@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/global.variables' as globalVariables;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/spacing.variables' as spacing;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/colours.variables' as colours;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/breakpoints.variables' as breakpoints;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/typography.variables' as typography;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-sizes.variables' as fontSizes;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-weights.variables' as fontWeights;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/2-tools/placeholder/focus.placeholders' as placeholders;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables//letter-spacing.variables' as letterSpacing;

.law-act-layout {
    display: flex;
}

.rs-drawer-wrapper {
    display: none;
}

.act-content {
    padding: spacing.$spacing-5 spacing.$spacing-5 spacing.$spacing-0 spacing.$spacing-0;

    h1 {
        margin-top: spacing.$spacing-0;
    }

    @media screen and (max-width: breakpoints.$small-breakpoint) {
      padding: spacing.$spacing-5 spacing.$spacing-0 spacing.$spacing-0 spacing.$spacing-0;
    }
}

.oic-regNumber {
  margin-top: 1rem !important;
  margin-bottom: 0rem  !important;
}

.laws-document__act-content__heading {
    text-align: center;
    margin-top: spacing.$spacing-5;
    margin-bottom: spacing.$spacing-5;

    .shorttitle {
        @extend %h2-styles;
        font-size: 1.625rem;
        line-height: 1.9375rem;
        max-width: 100%;
        margin-bottom: spacing.$spacing-2;
        padding-top: spacing.$spacing-4;
    }

    .chapter {
        font-size: fontSizes.$ontario-font-size-xlarge;
        font-family: typography.$ontario-font-raleway-modified;
        text-transform: uppercase;
        margin-bottom: spacing.$spacing-0;
        padding-bottom: spacing.$spacing-4;
    }
}

.desktop-accordion {
  &__content {
      @media screen and (max-width: breakpoints.$small-breakpoint) {
        overflow: auto !important;
      }
  }
}

.laws-document__act-content__print-download {
    display: flex;

    .ontario-button {
        padding: spacing.$spacing-3 spacing.$spacing-5 spacing.$spacing-3 spacing.$spacing-0;
        margin: spacing.$spacing-1;
    }

    svg {
        margin-right: spacing.$spacing-2;
    }
}

.doc-comments {
    margin: spacing.$spacing-5 spacing.$spacing-0;

    .history-item {
        padding-bottom: spacing.$spacing-2;
    }
}

.law-comments {
    .ontario-badge {
        margin-right: spacing.$spacing-4;
    }

    .comment .comment {
        margin: spacing.$spacing-4 spacing.$spacing-0;
    }
}

.law-comments__heading {
    display: flex;
    align-items: center;

    .comment {
        margin-bottom: spacing.$spacing-0;
        text-align: left;
    }
}

.law-comments__content {
    display: flex;
}

.consolidate-period {
    font-size: fontSizes.$ontario-font-size-standard-body-text;
    font-weight: fontWeights.$ontario-font-weights-normal;
    margin-left: spacing.$spacing-0;
}

.doc-comments__ammendment {
    margin-bottom: spacing.$spacing-4;
}

.content-accordion {
    .desktop-accordion__content {
        padding: spacing.$spacing-0 spacing.$spacing-0 spacing.$spacing-0 spacing.$spacing-6;
    }
    
    div#result-panel > div:nth-child(even),
    tbody tr:nth-child(even) {
      background-color: colours.$ontario-colour-white;
    }
}

.badge-notice-container {
  display: flex;
  align-items: center;
}


.MsoNormalTable {
  tbody {
    tr {
      border: none;
      border-width: 0.0625rem 0;
    }
    
    tr:hover {
      background-color: colours.$ontario-greyscale-5 !important;
    }
    
    tr[class*='marked'] {
      border-left: 4px solid colours.$ontario-colour-black;
    }

    td {
      padding: spacing.$spacing-2 spacing.$spacing-2 spacing.$spacing-2 spacing.$spacing-4;
    }
  }

  a,
  u {
    text-decoration: none;
    color: colours.$ontario-colour-black;
    font-family: typography.$ontario-font-open-sans;
    font-size: fontSizes.$ontario-font-size-standard-body-text;
    font-weight: fontWeights.$ontario-font-weights-normal;
    line-height: 1.6rem;
    display: inline-block;
  }

  a {
    display: inline-flex;
    align-items: flex-start;

    u span {
      padding-right: spacing.$spacing-4;
    }
  }

  .TOCpartCenter,
  .TOCpartCenter-e
  .TOCpartCenter-f {
    text-align: left !important;
    a,
    u {
      font-size: fontSizes.$ontario-font-size-standard-body-text;
      font-family: typography.$ontario-font-raleway-modified;
      font-weight: fontWeights.$ontario-font-weights-bold;
      line-height: 25.6px;
      font-variant: normal !important;
    }
  }

  .TOCheadCenter,
  .TOCheadCenter-e
  .TOCheadCenter-f {
      text-align: left !important;
    a,
    u {
      font-size: fontSizes.$ontario-font-size-standard-body-text;
      font-family: typography.$ontario-font-raleway-modified;
      font-weight: fontWeights.$ontario-font-weights-semi-bold;
      line-height: 25.6px;
      font-variant: normal !important;
    }
  }

  .table-e,
  .TOCpartLeft,
  .TOCpartLeft-e,
  .TOCpartLeft-f {
    text-align: left !important;
    a,
    u {
      font-size: fontSizes.$ontario-font-size-standard-body-text;
      font-family: typography.$ontario-font-raleway-modified;
      font-weight: fontWeights.$ontario-font-weights-semi-bold;
      line-height: 25.6px;
      text-transform: capitalize !important;
      padding-left: spacing.$spacing-1;
    }
  }

  .TOCid,
  .TOCid-e,
  .TOCheadLeft,
  .TOCheadLeft-e,
  .TOCheadLeft-f {
    text-align: left !important;
    margin-bottom: spacing.$spacing-0;
    a,
    u {
      font-size: fontSizes.$ontario-font-size-standard-body-text;
      font-family: typography.$ontario-font-raleway-modified;
      font-weight: fontWeights.$ontario-font-weights-normal;
      line-height: 25.6px;
      font-variant: normal !important;
      padding-left: spacing.$spacing-1;
    }
    a {
      u span {

      font-weight: fontWeights.$ontario-font-weights-normal !important;
        font-variant: normal !important;
      }
    }
  }
}

.WordSection1 {
    .headnote {
        font-size: fontSizes.$ontario-font-size-xlarge;
        font-weight: fontWeights.$ontario-font-weights-bold;
        margin-top: spacing.$spacing-2;
    }

    .definition {
        padding-left: spacing.$spacing-4;
    }
}

.content {
    .amendments-heading {
        width: globalVariables.$full-width;
        border-top: 2px solid colours.$ontario-greyscale-20;
        margin-bottom: spacing.$spacing-0;
        font-weight: fontWeights.$ontario-font-weights-semi-bold;
        padding: spacing.$spacing-3;

        b {
            font-weight: fontWeights.$ontario-font-weights-semi-bold;
            font-size: fontSizes.$ontario-font-size-standard-body-text;
        }
    }

    .amendments {
        margin-bottom: spacing.$spacing-0;
        .citation {
          padding: spacing.$spacing-3 spacing.$spacing-0 spacing.$spacing-2 spacing.$spacing-7;
        }

        .MsoHyperlink{
          padding: spacing.$spacing-3 spacing.$spacing-0 spacing.$spacing-2 spacing.$spacing-7;
        }
    }

    p.footnoteLeft.amendments {
      padding: spacing.$spacing-0 spacing.$spacing-0 spacing.$spacing-2 spacing.$spacing-8;
    }

    .amendments-heading.collapsed {
        border-bottom: 2px solid colours.$ontario-greyscale-20;
    }

    .amendments-heading::after {
        display: inline-block;
        vertical-align: text-bottom;
    }

    .amendments-heading.collapsed::before {
        content: '\2303';
        transform: rotate(180deg);
        color: colours.$ontario-colour-black;
        margin-right: spacing.$spacing-3;
        display: inline-block;
        cursor: pointer;
    }

    .amendments-heading:not(.collapsed)::before {
        content: '\2303';
        color: colours.$ontario-colour-black;
        margin-right: spacing.$spacing-3;
        display: inline-block;
        cursor: pointer;
    }
}
.WordSection1 p.leg-history.collapsed span.leg-history-inner,
.content p.footnoteLeft.amendments.collapsed,
.content table.hidden {
  display: none !important;
}

div.WordSection1 p.leg-history.collapsed .leg-history-inner {
  display: none;
}

div.WordSection1 div table.MsoNormalTable {
  display: block;
}

.act-content-panel {
    margin: 0 1rem;
  }
  
  .act-content-panel .sticky-nav-panel {
    height: auto;
    top: -0.5rem;
    overflow-y: scroll;
    display: flex;
  }
  
  .act-content {
    max-width: 82rem;
    overflow-x: hidden;
  }
  
  .sticky-nav {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    height: 100vh;
    overflow-y: auto;
    width: 18rem;
    min-width: 18rem;
  }
  
  .sticky-nav td {
    padding: 0.75rem 0.5rem;
  }
  
  .sticky-nav td p {
    margin-top: 0;
    margin-bottom: 0;
  }
  
  #act-verion > ul {
    margin-left: 0;
    border-bottom: 4px solid #d1d1d1;
  }
  
  #act-verion ul li {
    margin-bottom: -3px;
    padding: 0;
  }
  
  #act-verion > ul > li button {
    background-color: #f2f2f2;
    font-weight: bold;
    font-size: fontSizes.$ontario-font-size-large;
    margin-right: 0.25rem;
    color: #000000;
  }
  
  tbody tr > td > p.YTOCid {
    margin-bottom: 0 !important;
  }
  
  tbody tr > td > p.YTOCid > a.YTOCid {
    padding: 0 !important;
  }
  
  .node-law [class^='defPnote'],
  .node-law [class^='Pnote'],
  .node-law [class^='Y'],
  [class^='pnoteclause'],
  [class^='procclause'],
  [class^='procparagraph'] {
    background: #d9d9d9 !important;
    box-sizing: border-box;
    margin-bottom: 0.5em;
    padding: 0 0.5em;
  }
  
  #act-verion ul li button[class*='active'] {
    background-color: #e0f0ff;
    border-bottom: 4px solid colours.$ontario-colour-link;
    color: colours.$ontario-colour-link;
  }
  
  #act-verion .tab-content {
    border-bottom: 4px solid #d1d1d1;
  }
  
  .tab-content ul {
    margin-left: 0;
  }
  
  .doc-header-container div.doc-row {
    margin-top: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1.5fr 2.5fr;
  }
  
  .doc-header-container .ontario-label {
    max-width: 12rem;
    white-space: nowrap;
  }
  
  .doc-header-container .ontario-input {
    min-width: 7rem;
  }
  
  .doc-tab-container div.doc-row {
    display: grid;
    grid-template-columns: 1fr 4fr;
  }
  
  .doc-tab-container div.doc-row-version {
    display: grid;
    grid-template-columns: 1fr 8fr;
  }
  
  div.doc-row article {
    display: inline-flex;
    padding-right: 0.5rem;
  }
  
  div.doc-row a .float-right {
    text-align: right;
    padding: 0 1rem;
  }
  
  label.sort-label {
    text-align: right;
    padding-right: 0.5em;
  }
  
  span.citation {
    color: #666666;
  }
    
  .doc-version-e {
    font-weight: fontWeights.$ontario-font-weights-bold;
    .version-e {
      font-style:normal;
    }
    .version-f {
      font-style:normal;
    } 
  }

  aside.ontario-aside {
    margin: 2rem 0 0 0;
    padding: 1rem 1.5rem;
  }
  
  div.doc-row .ontario-input {
    margin: -0.5rem 0 0.5rem 0;
    padding: 0 1rem;
  }
  
  .current-button {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background-color: #e7e7e8;
    border: none;
    border-radius: 0.25rem;
    font-weight: bold;
    cursor: default;
  }
  
  .version-left {
    min-width: 100px;
  }
  
  section div.doc-row:nth-child(even) {
    background-color: #f5f5f5;
  }
  
  button[class^='hl-'] {
    padding: 0.5rem 0.5rem;
    background-color: #e8e8e8;
    border: none;
    margin: 0 0.1rem;
  }
  
  button.hl-up {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  
  button.hl-down {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  
  .in-doc-search {
    display: flex;
    flex-wrap: wrap;
  }
  
  .in-doc-error .ontario-error-messaging__content {
    color: #d81a20;
  }
  
  .law-act-layout {
    display: flex;
    flex-direction: row;
  }
  
  .in-doc-content em.active {
    padding: 0.15;
    background-color: #adff2d;
  }
  
  .in-doc-content em {
    padding: 0.15;
    background-color: #fff3cd;
  }
  
  
  div.rs-drawer-body {
    padding: 2rem 0rem;
  }
  
  .rs-drawer-body tbody tr {
    padding: 0.5rem 0.5rem;
  }
  
  .nav-content table.hidden {
    display: table;
  }
  
  .content-table tr:first-child {
    border-top: 3px solid #b2b2b2;
  }
  
  .content-table tr:last-child {
    border-bottom: 3px solid #b2b2b2;
  }
  
  .content-table tr,
  .nav-content tr {
    border: none;
  }
  
  .nav-content th,
  .nav-content td {
    padding: 0.5rem 0.5rem;
  }
  
  .content-table tr:hover,
  .nav-content tr:hover {
    background-color: #e0f0ff;
  }
  
  .content-table td a u,
  .nav-content td a u {
    text-decoration: none;
    font-weight: bold;
  }
  
  .content-table td a u {
    margin-right: 1rem;
  }
  
  .side-drewer-sub-title,
  .sub-title-desc {
    padding: 0 0.5rem;
  }
  
  .side-drewer-sub-title h2,
  .side-drewer-sub-title h4,
  .sub-title-desc h4,
  .sub-title-desc h5 {
    font-weight: bold;
  }
  
  div.rs-drawer-left.rs-drawer-xs,
  div.rs-drawer-right.rs-drawer-xs {
    width: 20rem;
  }
  
  h4.rs-drawer-title {
    font-size: 2rem;
    font-weight: bold;
  }
  
  div.rs-drawer-header {
    padding: 0.5rem 1rem 0.5rem 3rem;
  }
  
  .rs-drawer-header > button {
    display: none;
  }
  
  .download-print {
    display: flex;
    padding-right: 2rem;
  }
  
  .download-print a {
    margin-right: 2rem;
    text-decoration: underline;
    cursor: pointer;
    padding: 0 0.25rem;
  }
  
  .download-print a:active {
    color: #551abb;
  }
  
  .download-print a svg {
    margin-right: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .download-print button {
    margin-right: 2rem;
    text-decoration: underline;
    cursor: pointer;
    padding: 0 0.25rem;
  }
  
  .download-print button:active {
    color: #551abb;
  }
  
  .download-print button svg {
    margin-right: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .law-act-layout .ontario-accordions__container,
  .law-act-layout .ontario-accordion__content * {
    max-width: 100%;
  }
  
  .legislative-accordion p {
    margin: 0 1rem;
  }
  
  .doc-comments .ontario-blockquote {
    max-width: 100%;
    margin: 2rem 0;
    border-left: 4px solid #367a76;
    background-color: #f2f2f2;
    padding: 1.5rem 1.5rem;
    font-size: fontSizes.$ontario-font-size-standard-body-text;
  }
  
  .WordSection1 tbody {
    width: 100%;
  }
  
  .WordSection1 td p,
  .content-table td p {
    margin-bottom: 0;
  }
  
  .WordSection1 td,
  .content-table td {
    padding: 0.75rem 1rem;
  }
  
  .act-nav-btn {
    top: 75vh;
    left: -16px;
    height: 2.5rem;
    width: 4rem;
    position: absolute;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 6px solid #ffff;
  }
  
  .act-nav-btn:before {
    content: '';
    left: -10px;
    width: 10px;
    height: 2.5rem;
    background-color: #ffff;
    position: absolute;
  }
  
  button.side-drewer-collapse {
    background-color: #ffff;
    float: right;
    padding-top: 1rem;
  }
  
  .sticky-panel-collapsed {
    width: 10px;
    height: 100%;
    background-color: #ffff;
    position: relative;
    margin: 0 auto;
    box-shadow: 4px 0 2px rgba(0, 0, 0, 0.2);
  }
  
  .act-nav-btn:hover {
    opacity: 1;
  }
  
  .leg-history {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .leg-history .history-item {
    flex-basis: 33%;
    padding: 0 1rem;
  }
  
  div#toc-toggle-btn {
    display: none;
  }
  
  .tab-slider {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  
  .tab-item button {
    border: 1px solid colours.$ontario-greyscale-20;
    border-bottom: none;
    background-color: colours.$ontario-colour-white;
    font-family: 'Raleway';
    font-size: fontSizes.$ontario-font-size-standard-body-text;
    font-weight: 700;
    line-height: 19px;
    color: #4D4D4D;
    padding: 1rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin-right: 5px;
    text-decoration: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    cursor: pointer;

    @media screen and (max-width: breakpoints.$small-breakpoint) {
      white-space: wrap;
      height: 100%;
    }
  }
  
  .tab-item button.active {
    background-color: colours.$ontario-colour-black;
    color: colours.$ontario-colour-white;
    border: 1px solid colours.$ontario-colour-black;
    font-size: fontSizes.$ontario-font-size-standard-body-text;
    font-weight: 700;
    line-height: 19px;
    border-bottom: none;
    text-decoration: none;
    text-decoration-style: none;
  }
  
  .tab-content {
    display: none;
  }
  
  .tab-content.active {
    display: inherit;
  }
  
  .thumbs {
    max-width: 1024px;
    margin: 1rem auto;
    padding: 0 1.5rem;
    position: relative;
  }
  
  .thumbs .btn-prev,
  .thumbs .btn-next {
    position: absolute;
    top: 0px;
    font-size: fontSizes.$ontario-font-size-standard-body-text;
    color: #c7cdde;
    cursor: pointer;
  }
  
  .thumbs .btn-prev {
    left: 0px;
  }
  
  .thumbs .btn-next {
    right: 0px;
  }
  
  .thumbs .alice-carousel li {
    width: auto !important;
  }
  
  .alice-carousel {
    position: relative;
    width: 100%;
    margin: auto;
    direction: ltr;
    font-size: fontSizes.$ontario-font-size-large;
  }
  
  .law-comments .comment .Pnote {
    padding: 0;
  }
  
  .comment {
    margin-bottom: 1rem;
  }
  
  svg.term-icon {
    margin-left: -0.2rem;
  }
  
.content p.headnote {
  font-size: 1.2em;
  margin: 0;
  margin-top: 1em;
  font-weight: bold;
  line-height: 1.3em;
  color: #222;
  text-rendering: optimizeLegibility;
}

table.MsoNormalTable {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: none;

  a,
  u {      
    @media screen and (max-width: breakpoints.$medium-breakpoint) {
      text-decoration: underline !important;
    }
  }

       
    @media screen and (max-width: breakpoints.$small-breakpoint) {
      display: block;
      overflow-x: auto;
    }
  
}

@media screen and (max-width: breakpoints.$small-breakpoint) {
  .WordSection1 tbody {
    display: inline-table; /* Ensure the tbody adapts in small views */
  }
}

.Caution,
.SeeSource,
[class^='Notice'] {
  color: red;
}

.content .footnoteLeft.leg-history.collapsed::after {
  content: '\2303';
  transform: rotate(180deg);
  cursor: pointer;
}

.content .footnoteLeft.leg-history.collapsed::after {
  content: '\2303';
  transform: rotate(180deg);
  cursor: pointer;
}

.content-table .tocExpandable::after,
.content .leg-history::after,
.content .amendments-heading::after {
  display: inline-block;
  font-size: 0.9em;
  line-height: 1.6;
  margin-left: 1ex;
  vertical-align: text-bottom;
}

.content .footnoteLeft.amendments-heading {
  font-size: 1.1rem;
  color: #000;
  text-align: inherit;
  padding: spacing.$spacing-2;
}

.content .footnoteLeft.amendments-heading.collapsed::before {
  content: '\2303';
  /* 2304 is the down arrow but for whatever reason doesnt look similar in proportion */
  /* flipping \2303 180deg looks visually better */
  transform: rotate(180deg);
  color: colours.$ontario-colour-black ;
  display: inline-block;
  font-weight: bold;
  font-size: fontSizes.$ontario-font-size-large;
  cursor: pointer;
  padding-top: 2px;
}

.content .footnoteLeft.amendments-heading:not(.collapsed)::before {
  content: '\2303';
  color: colours.$ontario-colour-black;
  display: inline-block;
  font-weight: bold;
  font-size: fontSizes.$ontario-font-size-large;
  cursor: pointer;
  position: relative;
  top: 6px;
  padding-top: 2px;
}

.content p.toc-f::after {
  content: '\2303';
  cursor: pointer;
  margin-left: 6px;
  margin-right: 6px;
}

.content-table .tocExpandable:not(.tocCollapsed)::before {
  font-weight: bold;
  content: '\2303';
  cursor: pointer;
  margin-left: 6px;
  display: inline-block;
}

.content-table .tocExpandable.tocCollapsed::before {
  /* font-size: 2.25rem; */
  font-weight: bold;
  content: '\2303';
  /* 2304 is the down arrow but for whatever reason doesnt look similar in proportion */
  /* flipping \2303 180deg looks visually better */
  transform: rotate(180deg);
  cursor: pointer;
  margin-left: 6px;
  width: 1.5rem;
  display: inline-block;
}

.content .leg-history.collapsed::after {
  margin-left: 0;
  font-size: 0.9em;
  margin-left: 6px;
  margin-right: 6px;
}

.content .WordSection1 [class^='assent'] {
  text-align: right;
  font-style: italic;
}

.WordSection1 a {
  scroll-margin-top: 150px;
}

p[class*='longtitle'],
p[class*='shorttitle'] {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0;
}

p[class^='chapter'],
p[class*='longtitle'],
p[class*='shorttitle'] {
  margin-top: 1rem !important;
  text-align: center;
}

#law-content h1 {
  font-weight: 700;
}

.ontario-accordions__container .WordSection1 {
  margin-left: 2rem;
  padding-right: 2rem;
  [class^='xpara'],
  [class^='xnote'] {
    margin-bottom: 1rem;
  }
}

p[class^='xpartnum'] {
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  margin-bottom: 1rem;
  font-feature-settings: normal;
  font-style: normal;
}

p[class*='xtitle'] {
  visibility: hidden;
  height: 0;
}

p[class^='paragraph'],
p[class^='clause'] {
  margin-left: 2rem;
}

p[class^='subpara'],
p[class^='subclause'] {
  margin-left: 5rem;
}

p[class^='parawindt2'] {
  margin-left: 3rem;
}

.WordSection1 p.leg-history.collapsed span.leg-history-inner,
.content p.footnoteLeft.amendments.collapsed,
.content table.hidden {
    display: none;
}

div.WordSection1 p.leg-history.collapsed .leg-history-inner {
    display: none;
}

div.WordSection1 div table.MsoNormalTable {
    display: block;
}

#legislative-doc [class^="Pnote"] {
    font-size: 16px;
}

/*Laws-1292,Laws-1307*/
p.headnoteitalic, li.headnoteitalic, div.headnoteitalic
{
	margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:0cm;
  //line-height:9.0pt;
	page-break-after:avoid;
	font-size:10.0pt;
	font-weight:bold;
	font-style:italic;
  font-family: typography.$ontario-font-raleway-modified;
}

/*Laws-1321, Laws-1340*/

[class^="subsubpara"], [class^="subsubclause"],[class^="defsubsubclause"]
{
  margin-left:6em; 
  text-indent:-1.5em;
  padding-left:2em;
}

/*Laws-1340*/
[class^="defsubclause"]
{
	margin-left:6.5em; 
  text-indent:-1.5em;
}

[class*=defclause], [class*=defparagraph]
{
  text-indent: -1.5em;
  margin: 0 0 0.5em 3em;
  line-height: 1.6;
}

/*Laws-1325  Modified css*/
/*p[class*=shorttitle] br, */
/*p[class*=longtitle] br,
table br,
.MsoNormalTable.hidden {
  display: nblockone;
}*/
.MsoNormal {
  display: block;
}


.WordSection1 p.toc,
.content-table p.toc {
  text-align: left;
  color: colours.$ontario-colour-link;
  margin-top: 1.5rem;
  /*text-transform: capitalize;*/
  font-weight: 700;
  letter-spacing: letterSpacing.$ontario-letter-spacing-large;
  font-size: fontSizes.$ontario-font-size-xlarge;
}

p.Psection,
li.Psection,
div.Psection,
p.Psubsection,
li.Psubsection,
div.Psubsection {
  margin-bottom: 1.25rem !important;
}
