@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/global.variables' as globalVariables;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/spacing.variables' as spacing;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/colours.variables' as colours;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/breakpoints.variables' as breakpoints;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/2-tools/placeholder/focus.placeholders' as placeholders;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/typography.variables' as typography;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-sizes.variables' as fontSizes;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/z-index.variables' as zIndex;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/line-heights.variables' as lineHeights;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-weights.variables' as fontWeights;

.legislative-related-links {
  padding: 1rem;
}

.elaws-data-grid .MuiDataGrid-columnHeaders {
  border-top-left-radius: spacing.$spacing-0;
  border-top-right-radius: spacing.$spacing-0;
  background-color: colours.$ontario-greyscale-60;
  color: colours.$ontario-colour-white;
}

.elaws-data-grid .odd, table .odd {
  background-color: colours.$ontario-greyscale-5;
}

.related {
  background-color: colours.$ontario-greyscale-5;
  color: colours.$ontario-greyscale-70;
	font-weight: fontWeights.$ontario-font-weights-bold;
  padding: 0.3125rem 0.3125rem 0.3125rem 0.625rem;
}

#result-options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

#result-options .paging-option,
#result-options .highlight-option {
  flex: 1;
}

#result-options .paging-option,
#result-options .highlight-option,
#result-options .sort-option {
  margin: 0 0.5rem;
}

#result-options .ontario-form-group {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 1rem !important;
}

#result-options .ontario-form-group label {
  white-space: nowrap;
  margin-right: 0.5rem;
  margin-top: 0.25rem;
  width: auto;
}

#result-options .ontario-form-group select {
  padding: 0 2rem 0 0.75rem;
  width: auto;
  margin-bottom: 0.25rem;
}
.elaws-hr-thin {
  border-bottom-width: 2px;
}

p.Ysection-e, li.Ysection-e, div.Ysection-e
	{
	margin-top:5.0pt;
	margin-right:0pc;
	margin-left:0pc;
	text-align:justify;
	line-height:1.6;
	font-family:"Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  letter-spacing:normal;
	}

p.Ysubsection-e, li.Ysubsection-e, div.Ysubsection-e
	{
	margin-top:5.0pt;
	margin-right:0pc;
	margin-left:0pc;
	text-align:justify;
	line-height:1.6;
	font-family:"Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  letter-spacing:normal;
	}

p.Yclause-e, li.Yclause-e, div.Yclause-e
	{
	margin-top:5.55pt;
	margin-right:0pc;
	margin-left:26.9pt;
	text-align:justify;
	line-height:1.6;
	font-family:"Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  letter-spacing:normal;
  padding-left:32px;
  padding-right:8px;
  /*text-indent:-24px;*/
	}

  p.Yparagraph, p.Yparagraph-e, li.Yparagraph-e, div.Yparagraph-e
	{
	margin-top:5.55pt;
	margin-right:0pc;
	margin-left:26.9pt;
	text-align:justify;
	line-height:1.6;
	font-family:"Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  letter-spacing:normal;
  padding-left:32px;
  /*text-indent:-24px;*/
	}

p.Ysubpara, p.Ysubpara-e, li.Ysubpara-e, div.Ysubpara-e
	{
	margin-top:5.55pt;
	margin-right:0pc;
	margin-left:47.75pt;
	text-align:justify;
	line-height:1.6;
	font-family:"Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  letter-spacing:normal;
  padding-left:32px;
  padding-right:8px;
  /*text-indent:-24px;*/
	}

p.Yheadnote-e, li.Yheadnote-e, div.Yheadnote-e
	{
	margin-top:.5pc;
	margin-right:0pc;
	margin-left:0pc;
	line-height:1.6;
	font-weight:bold;
  letter-spacing:normal;
  overflow-wrap:normal;
  font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
}

.print-body .print-logo {
  display: block !important; /* Ensure logo is displayed during printing */
  text-align: left;
  margin-top: 10mm;
  margin-bottom: 10mm;
}

.print-logo {
  display: none; /* Hide logo by default */
}

.ontario-header__container {
  z-index: 1;
}

#elaws-header.ontario-application-subheader .ontario-header-button {
  background-color: #404040;
}

#elaws-header.ontario-application-subheader
  .ontario-application-subheader__menu
  a:hover {
  background-color: #4d4d4d;
}


.filter-header {
  padding: 1rem 1rem;
  text-transform: capitalize;
}

.fixed-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

.legislativeRelatedLinks {
  padding: 1rem;
}

.link-item a {
  text-decoration: none;
}

.filter-form-button {
  color: #06c;
  text-decoration: underline;
  cursor: pointer;
  margin: 0.2rem 1rem;
  padding: 0;
  font-size: 1.2rem;
  font-weight: semibold;
}

@media only screen and (max-width: 720px) {
  .filter-form-button {
    padding: 0 1.5rem;
  }
}

.right-aligned {
  text-align: right;
}

div.ontario-callout {
  margin: 0 0 !important;
}

div.ontario-critical-alert {
  padding: 1rem 1rem;
}

.banner {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.banner .ontario-callout * {
  max-width: none;
}

#last-modified {
  float: right;
  font-family: Cabin, sans-serif;
  margin: 1.5rem 0 1.5rem 1.5rem;
}

.tooltip {
  border-bottom: 2px dotted #000;
  text-decoration: none;
  opacity: 0.7;
  padding: 0.1rem 0;
  margin: 0 0.25rem;
  display: inline;
  font-size: inherit;
}

#elaws-header .ontario-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 1rem;
}

#elaws-header .ontario-application-subheader__container {
  flex-basis: 18%;
  min-width: 5rem;
}

#elaws-header .ontario-header__nav-right-container {
  flex-basis: 82%;
}

#elaws-header .ontario-header__nav-right-container a {
  padding-left: 0;
  padding-right: 1rem;
}

#elaws-header.ontario-application-subheader
  .ontario-application-subheader__menu
  button {
  display: none;
}

#elaws-header .ontario-application-subheader__heading a {
  white-space: nowrap;
}

@media only screen and (max-width: 720px) {
  #elaws-header.ontario-application-subheader
    .ontario-application-subheader__menu
    button {
    display: inherit;
  }

  #elaws-header .ontario-header__nav-right-container a {
    display: none;
  }

  #elaws-header .ontario-row {
    flex-wrap: nowrap;
  }

  .main-panel {
    display: block;
  }

  .main-panel #search-option-panel {
    width: 100%;
  }

  .main-panel #search-panel {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .search-box > button.search {
    max-width: 4rem;
  }

  #search-option-panel .sticky-inner-wrapper,
  #act-side-drew .sticky-inner-wrapper {
    position: static !important;
    transform: none !important;
    -webkit-transform: no;
    -moz-transform: no;
    -ms-transform: no;
    -o-transform: no;
  }

  #search-option-panel .scroll-y-panel,
  #act-side-drew .sticky-nav-panel {
    height: 100% !important;
    top: auto !important;
  }

  .law-act-layout {
    display: block;
  }

  .act-side-drewer.active {
    width: 100%;
    max-width: none;
    padding: 0 1rem;
  }

  div#toc-toggle-btn {
    display: inherit;
  }

  div#toc-toggler {
    display: none !important;
  }

  tbody {
    font-size: fontSizes.$ontario-font-size-small;
  }

  td {
    padding: 0.5em;
  }

  .act-nav-btn {
    left: 0;
    position: fixed;
  }

  .act-nav-btn:before {
    background: transparent;
  }
}

/* Data tables */
@media only screen and (max-width: 500px) {
  .MuiDataGrid-filterForm {
    display: table-column !important;
  }

  .MuiDataGrid-filterFormValueInput {
    padding-left: 2rem !important;
    padding-top: 1rem !important;
  }
}

@media print {
  .print-page-break {
    page-break-before:always;
  }

    /* Ensure all tables are visible */
    table.MsoNormalTable {
      display: table !important;
    }
  
    /* Override any 'hidden' class to show content */
    .hidden {
      display: block !important;
    }
  
    /* Make sure the contents section is fully expanded */
    .tocExpandable {
      display: block !important;
    }
}

.elaws-data-grid {
  height: 1200px !important;
  width: 100%;
}

/* At the time of release this particular table only had 6 entries */
/* Hence it needs less vertical space */
#privateStatutesRepealed .elaws-data-grid {
  height: 100% !important;
}

.elaws-data-grid .odd,
table .odd {
  background-color: #f5f5f5;
}

.elaws-data-grid .MuiDataGrid-columnHeaders {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: #666666;
  color: white;
}

.elaws-data-grid .MuiDataGrid-sortIcon {
  color: white;
  opacity: 0.6;
}

.elaws-data-grid .MuiDataGrid-columnHeader.MuiDataGrid-withBorder {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.elaws-data-grid .MuiDataGrid-menuIcon button {
  color: white;
  opacity: 0.5;
}

p.MuiTablePagination-selectLabel,
p.MuiTablePagination-displayedRows {
  margin: 0 0 0 0 !important;
}

.elaws-data-grid
  [class~='MuiDataGrid-columnHeader']:last-child
  .MuiDataGrid-columnSeparator {
  display: none;
}

.elaws-data-grid .MuiDataGrid-cell:focus {
  outline: none !important;
}

/*LAWS-1328: Fixing Legislative tables height issue */
.elaws-data-grid {
  height: auto !important;
}
