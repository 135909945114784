
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/colours.variables' as colours;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/spacing.variables' as spacing;

mark.hl.active {
  /*background: #adff2f;*/
  border: 1px solid #1A1A1A;
}

mark.result-hl {
  background-color: #bad5e2;
}

mark.no-hl {
  background-color: inherit !important;
}

.black-border {
  border-bottom: 4px solid colours.$ontario-colour-black;
  margin: spacing.$spacing-5 0 0 0 !important;
  padding: 0 !important;
}