@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/colours.variables' as colours;

.border-right {
	border-right: 2px solid colours.$ontario-greyscale-20;
}

.border-bottom {
	border-bottom: 2px solid colours.$ontario-greyscale-20;
    margin-bottom: 1rem;
}

hr.grey-border--thick {
	border-bottom: 4px solid colours.$ontario-greyscale-20;
}

hr.grey-border--default {
    border-bottom: 2px solid colours.$ontario-greyscale-20;
}

hr.grey-border--thin {
    border-bottom: 1px solid colours.$ontario-greyscale-20;
}

hr.black-border--thick {
    border-bottom: 4px solid colours.$ontario-colour-black;
}