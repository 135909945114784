@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/global.variables' as globalVariables;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/spacing.variables' as spacing;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/colours.variables' as colours;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/breakpoints.variables' as breakpoints;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/2-tools/placeholder/focus.placeholders' as placeholders;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/typography.variables' as typography;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-sizes.variables' as fontSizes;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-weights.variables' as fontWeights;

$spacing-13-px: 0.8125rem;
$nav-content-height: 1000px;

.side-drawer__title {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    background: colours.$ontario-greyscale-5;

    .elaws-h6 {
        margin-bottom: spacing.$spacing-0;
        padding-left: spacing.$spacing-2;
    }
}

.side-drawer--collapsed {
    align-items: flex-start;
    padding: $spacing-13-px spacing.$spacing-0 spacing.$spacing-3 spacing.$spacing-0;
      
    .side-drawer-border {
      border-right: 2px solid grey;
      height: 100%;
    }
    button {
      padding-right: spacing.$spacing-6;
      background: colours.$ontario-greyscale-5;
      @media screen and (max-width: breakpoints.$small-breakpoint) {
        padding-right: spacing.$spacing-5;
      }
    }
}

.side-drawer--expanded {
    margin-right: spacing.$spacing-4;
    padding: $spacing-13-px spacing.$spacing-4 spacing.$spacing-3 spacing.$spacing-0;
    
	@media screen and (max-width: breakpoints.$medium-breakpoint) {
        margin-right: spacing.$spacing-0;
    }
}

.side-drawer__toggle {
    border: none;
    padding-top: spacing.$spacing-1;
    cursor: pointer;

    &:hover {
        cursor: pointer;
    }
}

.legislativeRelatedLinks {
    .link-item a {
        text-decoration: none;
        color: colours.$ontario-colour-link;
    }
}

.nav-content {
    padding-right: spacing.$spacing-4;
    div#result-panel > div:nth-child(even),
    tbody tr:nth-child(even) {
      background-color: colours.$ontario-colour-white;
    }

    @media screen and (max-width: breakpoints.$small-breakpoint) {
      margin-top: spacing.$spacing-9;
    }

}

.nav-content-header-sticky {
  position:fixed;
  top:0;
  width:100%;
  z-index:100;
}

.nav-content-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background: colours.$ontario-greyscale-5;
  padding: spacing.$spacing-2;

  h6 {
    flex: 1;
    padding-top: 12px;
    padding-left: spacing.$spacing-1;
  }
}

.sticky-nav .grey-border--thin {
    margin-right: spacing.$spacing-5;
    padding-right: spacing.$spacing-5;
}

.act-content-panel__hidden {
    display: none;
}

.act-side-drewer {
    cursor: pointer;
  }
  
  .act-side-drewer [class^='TOChead'] {
    text-align: center;
  }
  
  .act-side-drewer.active {
    width: 20rem;
    max-width: 25rem;
    min-width: 20rem;
  }
  
  .sticky-nav-panel {
    height: 100vh;
    overflow-y: auto;
    top: 0;
  }
  
  .act-side-drewer td p {
    margin: 0;
  }
  
  .side-drewer-title {
    padding: 0 0.5rem;
    display: flex;
    justify-content: space-between;
  }
  
  .side-drewer-toggle-button {
    border: none;
    background-color: transparent;
  }
  
  #act-side-drew .side-drewer-toggler {
    float: right;
  }
  
  .rs-drawer-actions button {
    background-color: colours.$ontario-colour-white;
    opacity: 0.4;
  }
  
/*Laws-1337 Modified css*/
.side-drewer-title h1 {
    font-weight: bold;
    margin-top: 2rem;
}
