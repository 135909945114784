.hide {
  display: none !important;
}

.show-block {
  display: block;
}


.inline-items {
  display: flex;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: 50%;
}

.hl{
  background: #F8E5C3;
}

.highlighted {
  background-color: #e0e0e0;
}
