
.breadcrumbs {
  margin-left: 0;
  overflow: hidden;
  padding-left: 0;
  margin: 0.5rem 0 0 0;
}

.breadcrumbs li {
  font-size: 16px;
  text-transform: none;
}

.breadcrumbs > * {
  display: block;
  float: left;
  line-height: inherit;
  list-style: none;
  overflow: hidden;
}

.breadcrumbs li a {
  text-decoration: underline;
}

.breadcrumbs > :nth-child(n + 1):before {
  position: relative;
  top: 0.15rem;
  width: 20px;
}

.breadcrumbs > :first-child:before {
  width: 0;
}

.breadcrumbs > :nth-child(n + 1):before {
  background: url(https://www.ontario.ca/img/assets/chevron-right.svg) no-repeat;
  background-position-y: center;
  content: '';
  display: inline-block;
  height: 16px;
}

.ontario-row #block-breadcrumbs {
  padding-left: 12px;
}
