@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/global.variables' as globalVariables;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/spacing.variables' as spacing;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/colours.variables' as colours;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/breakpoints.variables' as breakpoints;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/typography.variables' as typography;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-sizes.variables' as fontSizes;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-weights.variables' as fontWeights;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/2-tools/placeholder/focus.placeholders' as placeholders;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables//letter-spacing.variables' as letterSpacing;
@use "@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/z-index.variables" as zIndex;

.tab-slider {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.tab-panel {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    border-bottom: 4px solid colours.$ontario-colour-black;
    overflow: hidden;

}

.tab-slid-arrow {
    padding: 0.5rem 0;
    background-color: colours.$ontario-colour-white;
    color: colours.$ontario-colour-black;
    border-bottom: 2px solid colours.$ontario-greyscale-5;
    cursor: pointer;
}

.tab-slid-arrow--left {
    box-shadow: 10px 0 8px -5px rgba(0, 0, 0, 0.3);
    z-index: zIndex.$ontario-z-index-above-high;
    align-content: center;
}

.tab-slid-arrow--right {
    box-shadow: -10px 0 8px -5px rgba(0, 0, 0, 0.3);
    align-content: center;
}

.tab-slider__regulations,
.tab-slider__act {
    margin: spacing.$spacing-4 spacing.$spacing-4 spacing.$spacing-2 spacing.$spacing-4;

    .doc-tab__container {
        flex-direction: column;
    }

    .doc-row {
        display: grid;
        grid-template-columns: 1fr 3fr;
        padding-top: spacing.$spacing-1;
        padding-bottom: spacing.$spacing-1;
    }

    .doc-row__title {
        text-decoration: none;
    }

    .elaws-h4 {
        margin-bottom: spacing.$spacing-0;
    }
}

.tab-slider__versions {
    .doc-tab__container {
        margin: spacing.$spacing-4 spacing.$spacing-4 spacing.$spacing-2 spacing.$spacing-4;
        display: flex;
        align-self: start;

        .ontario-badge {
            align-self: flex-start;
            /* Ensures the badge stays at the top */
        }
    }

    @media screen and (max-width: breakpoints.$small-breakpoint) {
        .ontario-badge {
            margin-top: spacing.$spacing-1;
        }

        .doc-tab__container {
            margin: spacing.$spacing-4 spacing.$spacing-0 spacing.$spacing-2 spacing.$spacing-0;
        }

    }
}

.doc-tab__content {
    margin-top: spacing.$spacing-1;
    margin-left: spacing.$spacing-2;
    width: 90%;

    hr {
        margin-top: spacing.$spacing-2;
        margin-bottom: spacing.$spacing-2;
    }
}

.doc-row-version,
.doc-row-version button,
.doc-row-version a {
    color: colours.$ontario-colour-black;
    font-family: typography.$ontario-font-open-sans;
    font-size: fontSizes.$ontario-font-size-standard-body-text;
    font-weight: fontWeights.$ontario-font-weights-semi-bold;
    line-height: 1.6rem;
}

.doc-row-version {
    margin-bottom: spacing.$spacing-4;

    .term-modal {
        margin-right: spacing.$spacing-1;
    }

    &__link {
        color: colours.$ontario-colour-link;
        margin-bottom: spacing.$spacing-4;
        cursor: pointer;
        
        a {
            text-decoration: none;

            &:link,
            &:visited {
                color: colours.$ontario-colour-link;
            }
        }
    }
}

a.doc-row-version__date-span {
    text-decoration: none;
    color: colours.$ontario-colour-link;
    font-weight: fontWeights.$ontario-font-weights-normal;
    margin-bottom: spacing.$spacing-2;
}

.doc-row-version__show-more {
    a {
        color: colours.$ontario-colour-link;
        text-decoration: none;
        font-weight: fontWeights.$ontario-font-weights-semi-bold;
    }

    svg {
        margin-right: spacing.$spacing-2;
    }
}

.doc-row__volume-label {
    font-weight: fontWeights.$ontario-font-weights-semi-bold;
}

#act-under-reg-panel .doc-tab__container .doc-row,
#act-rrs-panel .doc-tab__container .doc-row {
    display: grid;
}

.doc-header-container div.doc-row {
    margin-top: spacing.$spacing-2;
    display: grid;
}

.doc-header-container .ontario-input {
    min-width: 7rem;
}

.doc-tab__container div.doc-row {
    display: grid;
    grid-template-columns: 1fr 4fr;
}

.doc-row article {
    display: inline-flex;
    padding-right: spacing.$spacing-2;
}

.sort-label {
    text-align: right;
    padding-right: spacing.$spacing-2;
}

span.citation {
    color: colours.$ontario-greyscale-60;
}

div.doc-row .ontario-input {
    margin: -0.5rem spacing.$spacing-0 spacing.$spacing-2 spacing.$spacing-0;
    padding: spacing.$spacing-0 spacing.$spacing-4;
}

.current-button {
    padding-left: spacing.$spacing-2;
    padding-right: spacing.$spacing-2;
    border: none;
    border-radius: 0.25rem;
    font-weight: bold;
}

.version-left {
    min-width: 100px;
}

section div.doc-row:nth-child(even) {
    background-color: colours.$ontario-greyscale-5;
}

.act-search {
    width: 100%;
    background-color: colours.$ontario-greyscale-5;
    padding: spacing.$spacing-5 spacing.$spacing-3;
}

.oic-bottom-line {
    margin: 0 !important;
}

.oic-top-line {
    margin: 1.5rem 0 !important;
}