
.ontario-row.homepage .disclaimer {
  margin-left: 1rem;
  margin-right: 1rem;
}

.error-section {
  padding: 2rem 1rem;
  min-height: 30rem;
}

.disclaimer,
.fr-attention {
  border-width: 0 0 0 4px;
  border-style: solid;
  padding: 1.5rem;
  /* margin: 2rem 0 0.5rem 0; */
}

.disclaimer p,
.fr-attention p {
  margin: 0;
  padding-bottom: 0;
}

