div#main-container .ontario-row {
  max-width: 82rem !important;
}

div#main-container .ontario-footer {
  margin-top: 0 !important;
}

.ontario-footer .ontario-row .ontario-columns.ontario-medium-5.ontario-large-5 {
  text-align: right;
}

.ontario-footer__copyright a {
  margin-right: 0;
}

li.character {
  padding: 1rem 0.33rem 0 0;
  border: none;
}

td.operator,
th.operator {
  width: 20%;
}

.ontario-icon.blue {
  color: rgb(0, 102, 204);
}

ul.ontario-footer__links-container--inline {
  padding-left: 0 !important;
}
