@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/global.variables' as globalVariables;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/spacing.variables' as spacing;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/colours.variables' as colours;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/breakpoints.variables' as breakpoints;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/2-tools/placeholder/focus.placeholders' as placeholders;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/typography.variables' as typography;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-sizes.variables' as fontSizes;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/z-index.variables' as zIndex;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/line-heights.variables' as lineHeights;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-weights.variables' as fontWeights;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables//letter-spacing.variables' as letterSpacing;

$transparent-color: rgba(colours.$ontario-colour-white, 0);

.record-panel {
    padding: spacing.$spacing-4 spacing.$spacing-5;
    margin-bottom: spacing.$spacing-4;

    .elaws-decorator-parenthesis {
        color: colours.$ontario-greyscale-60;
        font-weight: fontWeights.$ontario-font-weights-semi-bold;
    }

    .indented-with-border {
        border-left: 2px solid colours.$ontario-greyscale-20;
        padding-left: spacing.$spacing-6;
    }
}

.record-title {
    font-family: typography.$ontario-font-raleway-modified;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: fontWeights.$ontario-font-weights-semi-bold;
    line-height: lineHeights.$ontario-line-height-5;
}

.not-under-act-regulation {
  font-family: typography.$ontario-font-raleway-modified;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: fontWeights.$ontario-font-weights-semi-bold;
  line-height: lineHeights.$ontario-line-height-5;
  margin-bottom: spacing.$spacing-2;
  text-align: left !important;
}

.record-title {
    color: colours.$ontario-colour-link;
    margin-bottom: spacing.$spacing-1;
    text-decoration: none;
}

.record-subtitle {
    margin-bottom: spacing.$spacing-1;

    .ontario-badge {
        margin-right: spacing.$spacing-2;
    }
}

.record-subtitle__currency-date,
.record-content {
    font-family: typography.$ontario-font-open-sans;
    font-size: fontSizes.$ontario-font-size-standard-body-text;
    font-style: normal;
    font-weight: fontWeights.$ontario-font-weights-normal;
    line-height: 1.6rem;
}

.record-subtitle__currency-date {
    color: colours.$ontario-greyscale-60;
    padding-left: spacing.$spacing-2;

    @media screen and (max-width: breakpoints.$small-breakpoint) {
        padding-left: spacing.$spacing-0;
    }
}

.record__hint-expander {
    margin-top: spacing.$spacing-0;
    margin-bottom: spacing.$spacing-0;

    .ontario-hint-expander__button {
        margin-top: spacing.$spacing-2;
        margin-bottom: spacing.$spacing-0;
    }

    .ontario-hint-expander__content {
        background-color: $transparent-color;
        padding: spacing.$spacing-0;
        margin-bottom: spacing.$spacing-5;
    }

    .result-record {
        padding-left: spacing.$spacing-5;
        margin-bottom: spacing.$spacing-5;
        border-left: none;

        &:first-of-type {
            margin-top: spacing.$spacing-5;
        }
    }
}


.record-panel {
  padding: 1rem 1rem;
  clear: both;
}

.record-panel .block-title {
  font-size: 1.2rem;
}

.record-panel .ontario-blockquote {
  margin: 0;
  border-left: 2px solid #cccccc;
  font-size: fontSizes.$ontario-font-size-standard-body-text;
  padding: 0.75rem 1.5rem 0.5rem 1.5rem;
  letter-spacing: 0;
  line-height: 1;
  font-family: inherit;
  font-weight: inherit;
  max-width: 60rem;
}

.result-record .ontario-label {
  width: auto;
  max-width: 100%;
  color: #0060b3;
  margin-bottom: 0;
}

.result-record .record-title {
  padding: 0.25rem 0;
}

.result-record .record-heading {
  font-size: 1.18rem;
  color: #666666;
  font-weight: 400;
}

.result-record .record-subtitle {
  padding: 0.25rem 0;
}

.result-record .record-current {
  padding: 0.25em 0.5em;
  background-color: #e7e7e8;
  border: none;
  border-radius: 0.25rem;
  /* margin: 0 0.25rem; */
  font-weight: bold;
}

.emdash-prefix::before {
    content: '\2014';
    /* equivalent to &mdash; */
    font-weight: 'bold';
  }
  
  .plus-prefix::before {
    content: '\FF0B';
    /* equivalent to &plus; */
    font-weight: 'bold';
  }
  
.ontario-accordion__content--under-act {
    padding-bottom: 0 !important;
  }
  
  .ontario-accordion__button--under-act {
    color: #1a1a1a;
    font-family: 'Open Sans';
    font-size: fontSizes.$ontario-font-size-standard-body-text;
    font-weight: normal;
    margin-bottom: 1rem;
    padding-top: 0.25em !important;
    padding-bottom: 0 !important;
  }
  
  .ontario-accordion__button--under-act:hover,
  .ontario-accordion__button--under-act:focus {
    color: #1a1a1a;
    background: inherit;
  }
  
  .ontario-accordion__button--small-font {
    font-size: fontSizes.$ontario-font-size-standard-body-text;
  }
  
  .ontario-accordion__button--font-weight-normal {
    font-weight: normal;
  }
  
  .ontario-expandable-search{
    margin-left: 1rem;
  }
  
  .ontario-alert--warning a {
    color: #00478F; /* Darker blue for accessibility */
  }
    
.indented-with-border {
    border-left: 2px solid #e7e7e8;
    padding-left: 1.5em;
}

.elaws-width-inherit * {
    width: inherit !important;
}
  
.elaws-link-no-text-decoration {
    text-decoration: none;
}
  
.elaws-accordion-text {
    border-left: 4px solid #ccc;
    padding: 1em 0 1em 1em;
    font-family: 'Raleway';
    letter-spacing: letterSpacing.$ontario-letter-spacing-small;
}
  