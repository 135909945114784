@use "sass:math";
@use "@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/global.variables"
  as globalVariables;
@use "@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/spacing.variables"
  as spacing;
@use "@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/colours.variables"
  as colours;
@use "@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/breakpoints.variables"
  as breakpoints;
@use "@ongov/ontario-design-system-global-styles/dist/styles/scss/2-tools/placeholder/focus.placeholders"
  as placeholders;
@use "@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/typography.variables"
  as typography;
@use "@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-sizes.variables"
  as fontSizes;
@use "@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/z-index.variables"
  as zIndex;
@use "@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/line-heights.variables"
  as lineHeights;
@use "@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-weights.variables"
  as fontWeights;
@use "@ongov/ontario-design-system-global-styles/dist/styles/scss/2-tools/functions/global.functions"
  as globalFunctions;

$ontario-search-autosuggest-box-shadow: #0000001a;

.ontario-search-autocomplete__suggestion-list {
  background-color: colours.$ontario-colour-white;
  border-radius: 0 0 globalVariables.$global-radius
    globalVariables.$global-radius;
  border-top: 2px solid $ontario-search-autosuggest-box-shadow;
  box-shadow: 0px 3px 8px 1px $ontario-search-autosuggest-box-shadow;
  clip-path: inset(0px -8px -8px -8px);
  display: block;
  height: auto;
  margin: 0 spacing.$spacing-7 spacing.$spacing-7 (0.5 * spacing.$spacing-1);
  overflow: hidden;
  position: absolute;
  width: calc(100% - spacing.$spacing-1);
  max-width: 34rem;
  z-index: zIndex.$ontario-z-index-above-high;

  li {
    list-style-type: none;
    padding: spacing.$spacing-1 spacing.$spacing-4;

    &:hover,
    &.highlighted {
      background-color: colours.$ontario-colour-button-tertiary--hover;
      cursor: pointer;
    }

    a {
      text-decoration: none;
      color: inherit;
      border: none;
      outline: none;
      
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    &:active {
      box-shadow: 0 0 0 4px colours.$ontario-colour-focus inset;
    }

    &:last-child {
      padding-bottom: spacing.$spacing-2;
    }
  }
}

.ontario-search-autocomplete__suggestion-list--selected {
  background-color: colours.$ontario-colour-button-tertiary--hover;
}

.ontario-search-autocomplete__suggestion-list__list-item:focus {
  outline: none;
}

.ontario-search__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(colours.$ontario-colour-white, 0.95);
  z-index: zIndex.$ontario-z-index-above-medium;
}

.ontario-search__overlay-open {
  .ontario-search__input-container,
  .ontario-search-autocomplete__suggestion-list {
    z-index: zIndex.$ontario-z-index-above-high;
    position: absolute;
    width: 100%;
  }

  .ontario-search__input-container {
    top: spacing.$spacing-4;
  }

  .ontario-search-autocomplete__suggestion-list {
    top: 4.06rem;
    /* Position below the search input box */
    left: 0;
    width: calc(100% - spacing.$spacing-6);
  }
}

.ontario-search__overlay-open.ontario-search__input-suggestion-container {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: zIndex.$ontario-z-index-above-high;
}

.ontario-search-autocomplete__suggestion-list-open {
  .ontario-search__submit {
    z-index: zIndex.$ontario-z-index-above-high;
    border-radius: 0 globalVariables.$global-radius 0 0;
    bottom: 0.0625rem;
  }
}

.ontario__visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
}

.search-box-label {
  margin-top: spacing.$spacing-4 * -1;
}

.search-bar-panel {
  display: flex;
  align-items: flex-end;
}

.search-bar-panel form {
  width: 100%;
}

.search-bar-panel .ontario-checkboxes {
  margin-bottom: 0 !important;
  margin-left: 2rem;
  max-width: 13rem;
}

.within-result-search .ontario-checkboxes__item {
  display: flex;
  margin-top: 0.75rem;
  padding: 0 0 0 0;
}

.within-result-search__error {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 0 0 0;
}

.within-result-search .ontario-checkboxes__input {
  position: inherit !important;
  @media screen and (min-width: breakpoints.$small-breakpoint) {
    margin-left: spacing.$spacing-3;
  }
}

.within-result-search .ontario-checkboxes__label {
  margin-left: spacing.$spacing-1;
}

.within-result-search label {
  margin-top: -0.75rem;
  
  font-size: 1rem !important;
  @media screen and (max-width: breakpoints.$small-breakpoint) {
    margin-top: spacing.$spacing-1;
  }
}

svg.clear-icon {
  color: #999999;
}

.act-search .search-box {
  max-width: 27rem;
  min-width: 16rem;
  margin-bottom: spacing.$spacing-4;
}

.ontario-search__reset {
  height: 2rem !important;
}

/* New code for search within spans */
.ontario-search__search-within-text {
  position: absolute;
  padding: 0.625rem 0 0.625rem 1.125rem;
  border: 2px #1a1a1a solid;
  border-right: none;
  border-radius: 4px 0 0 4px;
  background: #ebebeb;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.5;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 3rem;
  max-width: 14rem;
  z-index: 10;
}

.ontario-search__search-within-text__colon {
  position: absolute;
  padding: 0.625rem 0.25rem;
  border: 2px #1a1a1a solid;
  border-left: none;
  border-right: none;
  background: #ebebeb;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.5;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  z-index: 10;
}
