@use "@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/typography.variables"
  as typography;
@use "@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-weights.variables"
  as fontWeights;
@use "@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-sizes.variables"
  as fontSizes;
@use "@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/spacing.variables"
  as spacing;
@use "@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/colours.variables"
  as colours;
@use "@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/line-heights.variables"
  as lineHeights;
@use "@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables//letter-spacing.variables"
  as letterSpacing;

@mixin elaws-heading($size, $line-height) {
  font-size: $size;
  line-height: $line-height;
}

h1,
.elaws-h1 {
  @extend %h1-styles;
  @include elaws-heading(1.8125rem, 2.1875rem);
  font-weight: fontWeights.$ontario-font-weights-bold !important;
}

h2,
.elaws-h2 {
  @extend %h2-styles;
  @include elaws-heading(1.625rem, 1.9375rem);
}

h3,
.elaws-h3 {
  @extend %h3-styles;
  @include elaws-heading(1.4375rem, 1.6875rem);
}

h4,
.elaws-h4 {
  @extend %h4-styles;
  @include elaws-heading(1.25rem, 1.5rem);
  margin-bottom: spacing.$spacing-5;
  font-weight: fontWeights.$ontario-font-weights-semi-bold;
}

h5,
.elaws-h5 {
  @extend %h5-styles;
  @include elaws-heading(1.125rem, 1.375rem);
  margin-bottom: spacing.$spacing-5;
  font-weight: fontWeights.$ontario-font-weights-semi-bold;
}

h6,
.elaws-h6 {
  @extend %h6-styles;
  @include elaws-heading(1rem, 1.1875rem);
}

.leadText {
  font-family: typography.$ontario-font-open-sans;
  font-size: 1.375rem;
  font-weight: fontWeights.$ontario-font-weights-normal;
  line-height: 2.1875rem;

  button span {
    font-family: typography.$ontario-font-open-sans;
    font-size: 1.375rem;
    font-weight: fontWeights.$ontario-font-weights-normal;
    line-height: 2.1875rem;
  }
}

mark.hl {
  background: colours.$ontario-colour-light-yellow;
  mix-blend-mode: multiply;
}

mark.result-hl {
  background: colours.$ontario-colour-button-secondary--active;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  text-decoration: none;
}

/*
 * bootstrap .modal-title has it's own h4 class
 * instead, mimick the styles for .ontario-h4 from the ds
 */
.h4 {
  font-style: normal;
  font-weight: fontWeights.$ontario-font-weights-bold;
  text-rendering: optimizeLegibility;
  font-feature-settings: normal;
  font-family: typography.$ontario-font-raleway-modified;
  font-size: fontSizes.$ontario-font-size-large;
  letter-spacing: letterSpacing.$ontario-letter-spacing-medium;
  line-height: lineHeights.$ontario-line-height-7;
  margin: 0 0 0 0;
  max-width: typography.$ontario-max-width-standard;
}

@media screen and (min-width: 40em) {
  .h4 {
    font-size: fontSizes.$ontario-font-size-xlarge;
    letter-spacing: letterSpacing.$ontario-letter-spacing-large;
    line-height: lineHeights.$ontario-line-height-7;
  }
}
