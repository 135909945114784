@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/spacing.variables' as spacing;

.select-box {
    display: flex;
    align-items: center;

    .ontario-label {
        white-space: nowrap;
        margin-right: spacing.$spacing-2;
        margin-bottom: spacing.$spacing-0;
    }

    .ontario-input {
        margin-bottom: spacing.$spacing-0;
    }
}

.select-box__french {
    .ontario-label {
        white-space: wrap;
    }
}

.select-box__container {
    display: flex;
}

.sort-option {
    .ontario-label {
        text-align: end;
        width: 4em;
    }
}