@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/colours.variables' as colours;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/spacing.variables' as spacing;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/2-tools/functions/global.functions' as globalFunctions;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/breakpoints.variables' as breakpoints;

.ontario-checkboxes {
    margin-bottom: spacing.$spacing-0;
}

.ontario-checkboxes__label {
    margin-top: spacing.$spacing-0;
    padding: spacing.$spacing-0 spacing.$spacing-2;
}

.ontario-checkboxes__label:before {
    width: 1.25rem;
    height: 1.25rem;
}

.ontario-checkboxes__label:after {
    width: 1.25rem;
    height: 1.25rem;
    top: globalFunctions.px-to-rem(2);
    left: globalFunctions.px-to-rem(2);
}

.ontario-checkboxes__label {
    margin-top: spacing.$spacing-0;
    padding: spacing.$spacing-0 spacing.$spacing-1;
}

@media screen and (max-width: 40em) {
    .ontario-checkboxes__item {
        padding:0 0 0 2rem;
        min-height: 28px;
    }
}