@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/spacing.variables' as spacing;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-weights.variables' as fontWeights;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/colours.variables' as colours;

.filter-panel {
    .mobile-accordion__desktop-header {
        margin-bottom: spacing.$spacing-5;
    }

    .mobile-accordion__mobile {
        border-bottom: none;
    }
    
    .ontario-fieldset__legend {
        font-weight: fontWeights.$ontario-font-weights-semi-bold;
    }

    .ontario-hint-expander__button {
        padding-top: spacing.$spacing-4;
        padding-bottom: spacing.$spacing-4;
    }
}

.legislative-related-links {
    .link-item a {
        color: colours.$ontario-colour-link;
    }
}

.filter-buttons__container {
    margin: spacing.$spacing-5 spacing.$spacing-0;

    .ontario-button--tertiary {
        margin: 0.2rem spacing.$spacing-0;
        padding: 0.51875rem spacing.$spacing-5;
    }
}

.elaws-display-inline-block {
    display: inline-block !important;
}
  
.elaws-no-bg {
    background: none;
}
  