@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/spacing.variables' as spacing;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/colours.variables' as colours;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/typography.variables' as typography;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-sizes.variables' as fontSizes;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-weights.variables' as fontWeights;

.term-modal {
    background: none;
    padding: spacing.$spacing-0;
    font-size: fontSizes.$ontario-font-size-standard-body-text;
    font-weight: normal;
    font-family: 'Open Sans';
    border: none;
    color: colours.$ontario-colour-black;
    cursor: pointer;

    .ontario-icon {
        width: 1.25rem;
        height: 1.25rem;
    }
}

.term-icon {
    color: colours.$ontario-colour-link;
}

.elaws-decorator-parenthesis {
    &:before {
        content: '(';
        font-weight: normal;
    }

    &:after {
        content: ')';
        font-weight: normal;
    }
}

.elaws-underline {
    text-decoration: underline;

    &--dotted {
        text-decoration-style: dotted;
        text-underline-offset: .25em;
        text-decoration-thickness: 1px;
    }
}