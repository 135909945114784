/* Font Definitions */
@font-face {
  panose-1: 2 4 5 3 5 4 6 3 2 4;
}
@font-face {
}
@font-face {
  panose-1: 2 2 6 3 5 4 5 2 3 4;
}
/* Style Definitions */
p.MsoNormal,
li.MsoNormal,
div.MsoNormal {
  layout-grid-mode: line;
  margin: 0cm;
}
p.assent,
li.assent,
div.assent {
  font-style: italic;
  margin-bottom: 27.9pt;
  margin-top: 9.5pt;
  mso-style-name: assent;
  text-align: right;
}
p.assent,
li.assent,
div.assent,
p.chapter,
li.chapter,
div.chapter {
  margin-left: 0cm;
  margin-right: 0cm;
  page-break-after: avoid;
}
p.chapter,
li.chapter,
div.chapter {
  margin-bottom: 15.45pt;
  margin-top: 0cm;
  mso-style-name: chapter;
  text-align: center;
  text-transform: uppercase;
}
p.definition,
li.definition,
div.definition {
  margin-bottom: 0cm;
  margin-left: 9.45pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  mso-style-name: definition;
  text-align: justify;
}
p.ellipsis,
li.ellipsis,
div.ellipsis {
  mso-style-name: ellipsis;
}
p.ellipsis,
li.ellipsis,
div.ellipsis,
p.equation,
li.equation,
div.equation {
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: center;
}
p.equation,
li.equation,
div.equation {
  mso-style-name: equation;
}
p.heading1,
li.heading1,
div.heading1 {
  mso-style-name: heading1;
}
p.heading1,
li.heading1,
div.heading1,
p.heading2,
li.heading2,
div.heading2 {
  font-variant: small-caps;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
}
p.heading2,
li.heading2,
div.heading2 {
  mso-style-name: heading2;
}
p.heading3,
li.heading3,
div.heading3 {
  mso-style-name: heading3;
}
p.heading3,
li.heading3,
div.heading3,
p.headingx,
li.headingx,
div.headingx {
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
}
p.headingx,
li.headingx,
div.headingx {
  mso-style-name: headingx;
  text-transform: uppercase;
}
p.insert,
li.insert,
div.insert {
  font-style: italic;
  font-weight: bold;
  margin-bottom: 0cm;
  margin-top: 11.5pt;
  mso-style-name: insert;
  page-break-after: avoid;
  text-align: justify;
}
p.insert,
li.insert,
div.insert,
p.line,
li.line,
div.line {
  margin-left: 0cm;
  margin-right: 0cm;
}
p.line,
li.line,
div.line {
  margin-bottom: 3pt;
  margin-top: 3pt;
  mso-style-name: line;
  text-align: center;
}
p.longtitle,
li.longtitle,
div.longtitle {
  font-weight: bold;
  margin-bottom: 51.8pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 21pt;
  mso-style-name: longtitle;
  page-break-after: avoid;
  text-align: center;
}
p.minnote,
li.minnote,
div.minnote {
  font-style: italic;
  margin-bottom: 10.45pt;
  margin-top: 4.55pt;
  mso-style-name: minnote;
}
p.minnote,
li.minnote,
div.minnote,
p.number,
li.number,
div.number {
  margin-left: 0cm;
  margin-right: 0cm;
  text-align: justify;
}
p.number,
li.number,
div.number {
  font-weight: bold;
  margin-bottom: 0cm;
  margin-top: 20.1pt;
  mso-style-name: number;
}
p.paragraph,
li.paragraph,
div.paragraph {
  margin-left: 26.9pt;
  margin-top: 5.55pt;
  mso-style-name: paragraph;
}
p.paragraph,
li.paragraph,
div.paragraph,
p.paranoindt,
li.paranoindt,
div.paranoindt {
  margin-bottom: 0cm;
  margin-right: 0cm;
  text-align: justify;
}
p.paranoindt,
li.paranoindt,
div.paranoindt {
  margin-left: 0cm;
  margin-top: 4.8pt;
  mso-style-name: paranoindt;
}
p.parawindt,
li.parawindt,
div.parawindt {
  margin-left: 13.95pt;
  mso-style-name: parawindt;
}
p.parawindt,
li.parawindt,
div.parawindt,
p.parawtab,
li.parawtab,
div.parawtab {
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 4.8pt;
  text-align: justify;
}
p.parawtab,
li.parawtab,
div.parawtab {
  margin-left: 0cm;
  mso-style-name: parawtab;
}
p.partnum,
li.partnum,
div.partnum {
  mso-style-name: partnum;
  text-transform: uppercase;
}
p.partnum,
li.partnum,
div.partnum,
p.Pheading1,
li.Pheading1,
div.Pheading1 {
  font-weight: bold;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
}
p.Pheading1,
li.Pheading1,
div.Pheading1 {
  font-variant: small-caps;
  mso-style-name: Pheading1;
}
p.Pheading2,
li.Pheading2,
div.Pheading2 {
  font-variant: small-caps;
  mso-style-name: Pheading2;
}
p.Pheading2,
li.Pheading2,
div.Pheading2,
p.Pheading3,
li.Pheading3,
div.Pheading3 {
  font-weight: bold;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
}
p.Pheading3,
li.Pheading3,
div.Pheading3 {
  mso-style-name: Pheading3;
}
p.Pheadingx,
li.Pheadingx,
div.Pheadingx {
  margin-top: 7.5pt;
  mso-style-name: Pheadingx;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.Pheadingx,
li.Pheadingx,
div.Pheadingx,
p.Pnote,
li.Pnote,
div.Pnote {
  font-weight: bold;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
}
p.Pnote,
li.Pnote,
div.Pnote {
  background: #d9d9d9;
  margin-top: 5pt;
  mso-style-name: Pnote;
  text-align: justify;
}
p.Pparagraph,
li.Pparagraph,
div.Pparagraph {
  font-weight: bold;
  margin-left: 26.9pt;
  mso-style-name: Pparagraph;
}
p.Pparagraph,
li.Pparagraph,
div.Pparagraph,
p.preamble,
li.preamble,
div.preamble {
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.preamble,
li.preamble,
div.preamble {
  margin-left: 0cm;
  mso-style-name: preamble;
}
p.Psection,
li.Psection,
div.Psection {
  margin-left: 0cm;
  margin-top: 5pt;
  mso-style-name: Psection;
}
p.Psection,
li.Psection,
div.Psection,
p.Psubpara,
li.Psubpara,
div.Psubpara {
  font-weight: bold;
  margin-bottom: 0cm;
  margin-right: 0cm;
  text-align: justify;
}
p.Psubpara,
li.Psubpara,
div.Psubpara {
  margin-left: 47.75pt;
  margin-top: 5.55pt;
  mso-style-name: Psubpara;
}
p.Psubsection,
li.Psubsection,
div.Psubsection {
  font-weight: bold;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5pt;
  mso-style-name: Psubsection;
  text-align: justify;
}
p.Psubsubpara,
li.Psubsubpara,
div.Psubsubpara {
  margin-left: 71.75pt;
  mso-style-name: Psubsubpara;
}
p.Psubsubpara,
li.Psubsubpara,
div.Psubsubpara,
p.Psubsubsubpara,
li.Psubsubsubpara,
div.Psubsubsubpara {
  font-weight: bold;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Psubsubsubpara,
li.Psubsubsubpara,
div.Psubsubsubpara {
  margin-left: 89.65pt;
  mso-style-name: Psubsubsubpara;
}
p.scanned,
li.scanned,
div.scanned {
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.55pt;
  mso-style-name: scanned;
  text-align: justify;
}
p.schedule,
li.schedule,
div.schedule {
  margin-bottom: 3pt;
  margin-top: 7.5pt;
  mso-style-name: schedule;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.schedule,
li.schedule,
div.schedule,
p.section,
li.section,
div.section {
  margin-left: 0cm;
  margin-right: 0cm;
}
p.section,
li.section,
div.section {
  margin-bottom: 0cm;
  margin-top: 5pt;
  mso-style-name: section;
  text-align: justify;
}
p.shorttitle,
li.shorttitle,
div.shorttitle {
  font-weight: bold;
  margin-bottom: 28.9pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  mso-style-name: shorttitle;
  page-break-after: avoid;
  text-align: center;
}
p.StartTumble,
li.StartTumble,
div.StartTumble {
  margin-left: 0cm;
  mso-style-name: "Start Tumble";
}
p.StartTumble,
li.StartTumble,
div.StartTumble,
p.subclause,
li.subclause,
div.subclause {
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.subclause,
li.subclause,
div.subclause {
  layout-grid-mode: line;
  margin-left: 47.75pt;
  mso-style-name: subclause;
}
p.subpara,
li.subpara,
div.subpara {
  margin-left: 47.75pt;
  margin-top: 5.55pt;
  mso-style-name: subpara;
}
p.subpara,
li.subpara,
div.subpara,
p.subsection,
li.subsection,
div.subsection {
  margin-bottom: 0cm;
  margin-right: 0cm;
  text-align: justify;
}
p.subsection,
li.subsection,
div.subsection {
  margin-left: 0cm;
  margin-top: 5pt;
  mso-style-name: subsection;
}
p.subsubclause,
li.subsubclause,
div.subsubclause {
  layout-grid-mode: line;
  mso-style-name: subsubclause;
}
p.subsubclause,
li.subsubclause,
div.subsubclause,
p.subsubpara,
li.subsubpara,
div.subsubpara {
  margin-bottom: 0cm;
  margin-left: 71.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.subsubpara,
li.subsubpara,
div.subsubpara {
  mso-style-name: subsubpara;
}
p.subsubsubpara,
li.subsubsubpara,
div.subsubsubpara {
  margin-bottom: 0cm;
  margin-left: 89.65pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  mso-style-name: subsubsubpara;
  text-align: justify;
}
p.table,
li.table,
div.table {
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
  mso-style-name: table;
}
p.toc,
li.toc,
div.toc {
  font-weight: bold;
  margin-bottom: 6pt;
  margin-top: 15pt;
  mso-style-name: toc;
  text-transform: uppercase;
}
p.toc,
li.toc,
div.toc,
p.tochead1,
li.tochead1,
div.tochead1 {
  margin-left: 0cm;
  margin-right: 0cm;
  page-break-after: avoid;
  text-align: center;
}
p.tochead1,
li.tochead1,
div.tochead1 {
  font-variant: small-caps;
  margin-bottom: 2pt;
  margin-top: 4pt;
  mso-style-name: tochead1;
}
p.Yellipsis,
li.Yellipsis,
div.Yellipsis {
  margin-top: 5.55pt;
  mso-style-name: Yellipsis;
}
p.Yellipsis,
li.Yellipsis,
div.Yellipsis,
p.YPheadingx,
li.YPheadingx,
div.YPheadingx {
  background: #d9d9d9;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  text-align: center;
}
p.YPheadingx,
li.YPheadingx,
div.YPheadingx {
  font-weight: bold;
  layout-grid-mode: line;
  margin-top: 7.5pt;
  mso-style-name: YPheadingx;
  page-break-after: avoid;
  text-transform: uppercase;
}
p.Ytable,
li.Ytable,
div.Ytable {
  margin-bottom: 0cm;
  margin-top: 0.55pt;
  mso-style-name: Ytable;
}
p.Ytable,
li.Ytable,
div.Ytable,
p.Ytoc,
li.Ytoc,
div.Ytoc {
  background: #d9d9d9;
  margin-left: 0cm;
  margin-right: 0cm;
}
p.Ytoc,
li.Ytoc,
div.Ytoc {
  font-weight: bold;
  margin-bottom: 6pt;
  margin-top: 15pt;
  mso-style-name: Ytoc;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.footnote,
li.footnote,
div.footnote {
  margin-bottom: 0cm;
  margin-top: 5.55pt;
  mso-style-name: footnote;
}
p.footnote,
li.footnote,
div.footnote,
p.PrAssent,
li.PrAssent,
div.PrAssent {
  margin-left: 0cm;
  margin-right: 0cm;
  text-align: right;
}
p.PrAssent,
li.PrAssent,
div.PrAssent {
  font-style: italic;
  layout-grid-mode: line;
  margin-bottom: 20.9pt;
  margin-top: 0cm;
  mso-style-name: PrAssent;
}
p.comment,
li.comment,
div.comment {
  margin-bottom: 6pt;
  margin-top: 6pt;
  mso-style-name: comment;
}
p.comment,
li.comment,
div.comment,
p.tableheading,
li.tableheading,
div.tableheading {
  margin-left: 0cm;
  margin-right: 0cm;
}
p.tableheading,
li.tableheading,
div.tableheading {
  margin-bottom: 6.95pt;
  margin-top: 0cm;
  mso-style-name: tableheading;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.Ypreamble,
li.Ypreamble,
div.Ypreamble {
  background: #d9d9d9;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  mso-style-name: Ypreamble;
  text-align: justify;
}
p.Ypartnum,
li.Ypartnum,
div.Ypartnum {
  font-weight: bold;
  mso-style-name: Ypartnum;
  text-transform: uppercase;
}
p.Ypartnum,
li.Ypartnum,
div.Ypartnum,
p.Yheading1,
li.Yheading1,
div.Yheading1 {
  background: #d9d9d9;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
}
p.Yheading1,
li.Yheading1,
div.Yheading1 {
  font-variant: small-caps;
  mso-style-name: Yheading1;
}
p.Yheading2,
li.Yheading2,
div.Yheading2 {
  font-variant: small-caps;
  mso-style-name: Yheading2;
}
p.Yheading2,
li.Yheading2,
div.Yheading2,
p.Yheading3,
li.Yheading3,
div.Yheading3 {
  background: #d9d9d9;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
}
p.Yheading3,
li.Yheading3,
div.Yheading3 {
  mso-style-name: Yheading3;
}
p.Ytableheading,
li.Ytableheading,
div.Ytableheading {
  background: #d9d9d9;
  margin-bottom: 6.95pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  mso-style-name: Ytableheading;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.Ydefinition,
li.Ydefinition,
div.Ydefinition {
  margin-left: 9.45pt;
  margin-top: 5.55pt;
  mso-style-name: Ydefinition;
}
p.Ydefinition,
li.Ydefinition,
div.Ydefinition,
p.Ysection,
li.Ysection,
div.Ysection {
  background: #d9d9d9;
  margin-bottom: 0cm;
  margin-right: 0cm;
  text-align: justify;
}
p.Ysection,
li.Ysection,
div.Ysection {
  margin-left: 0cm;
  margin-top: 5pt;
  mso-style-name: Ysection;
}
p.Ysubsection,
li.Ysubsection,
div.Ysubsection {
  margin-left: 0cm;
  margin-top: 5pt;
  mso-style-name: Ysubsection;
}
p.Ysubsection,
li.Ysubsection,
div.Ysubsection,
p.Yparagraph,
li.Yparagraph,
div.Yparagraph {
  background: #d9d9d9;
  margin-bottom: 0cm;
  margin-right: 0cm;
  text-align: justify;
}
p.Yparagraph,
li.Yparagraph,
div.Yparagraph {
  margin-left: 26.9pt;
  margin-top: 5.55pt;
  mso-style-name: Yparagraph;
}
p.Yparanoindt,
li.Yparanoindt,
div.Yparanoindt {
  margin-left: 0cm;
  mso-style-name: Yparanoindt;
}
p.Yparanoindt,
li.Yparanoindt,
div.Yparanoindt,
p.Yparawindt,
li.Yparawindt,
div.Yparawindt {
  background: #d9d9d9;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 4.8pt;
  text-align: justify;
}
p.Yparawindt,
li.Yparawindt,
div.Yparawindt {
  margin-left: 13.9pt;
  mso-style-name: Yparawindt;
}
p.Yparawtab,
li.Yparawtab,
div.Yparawtab {
  background: #d9d9d9;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 4.8pt;
  mso-style-name: Yparawtab;
  text-align: justify;
}
p.Ysubpara,
li.Ysubpara,
div.Ysubpara {
  margin-left: 47.75pt;
  mso-style-name: Ysubpara;
}
p.Ysubpara,
li.Ysubpara,
div.Ysubpara,
p.Ysubsubpara,
li.Ysubsubpara,
div.Ysubsubpara {
  background: #d9d9d9;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Ysubsubpara,
li.Ysubsubpara,
div.Ysubsubpara {
  margin-left: 71.75pt;
  mso-style-name: Ysubsubpara;
}
p.Ysubsubsubpara,
li.Ysubsubsubpara,
div.Ysubsubsubpara {
  margin-left: 89.65pt;
  mso-style-name: Ysubsubsubpara;
  text-align: justify;
}
p.Ysubsubsubpara,
li.Ysubsubsubpara,
div.Ysubsubsubpara,
p.Yequation,
li.Yequation,
div.Yequation {
  background: #d9d9d9;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
}
p.Yequation,
li.Yequation,
div.Yequation {
  margin-left: 0cm;
  mso-style-name: Yequation;
  text-align: center;
}
p.YPsection,
li.YPsection,
div.YPsection {
  mso-style-name: YPsection;
}
p.YPsection,
li.YPsection,
div.YPsection,
p.YSPsection,
li.YSPsection,
div.YSPsection {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5pt;
  text-align: justify;
}
p.YSPsection,
li.YSPsection,
div.YSPsection {
  mso-style-name: YSPsection;
}
p.YPsubsection,
li.YPsubsection,
div.YPsubsection {
  mso-style-name: YPsubsection;
}
p.YPsubsection,
li.YPsubsection,
div.YPsubsection,
p.YSPsubsection,
li.YSPsubsection,
div.YSPsubsection {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5pt;
  text-align: justify;
}
p.YSPsubsection,
li.YSPsubsection,
div.YSPsubsection {
  mso-style-name: YSPsubsection;
}
p.YPclause,
li.YPclause,
div.YPclause {
  margin-left: 26.9pt;
  mso-style-name: YPclause;
}
p.YPclause,
li.YPclause,
div.YPclause,
p.YPsubclause,
li.YPsubclause,
div.YPsubclause {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YPsubclause,
li.YPsubclause,
div.YPsubclause {
  margin-left: 47.75pt;
  mso-style-name: YPsubclause;
}
p.YPsubsubclause,
li.YPsubsubclause,
div.YPsubsubclause {
  margin-left: 71.75pt;
  mso-style-name: YPsubsubclause;
}
p.YPsubsubclause,
li.YPsubsubclause,
div.YPsubsubclause,
p.YPsubsubsubclause,
li.YPsubsubsubclause,
div.YPsubsubsubclause {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YPsubsubsubclause,
li.YPsubsubsubclause,
div.YPsubsubsubclause {
  margin-left: 89.65pt;
  mso-style-name: YPsubsubsubclause;
}
p.YPparagraph,
li.YPparagraph,
div.YPparagraph {
  margin-left: 26.9pt;
  mso-style-name: YPparagraph;
}
p.YPparagraph,
li.YPparagraph,
div.YPparagraph,
p.YPsubpara,
li.YPsubpara,
div.YPsubpara {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YPsubpara,
li.YPsubpara,
div.YPsubpara {
  margin-left: 47.75pt;
  mso-style-name: YPsubpara;
}
p.YPsubsubpara,
li.YPsubsubpara,
div.YPsubsubpara {
  margin-left: 71.75pt;
  mso-style-name: YPsubsubpara;
}
p.YPsubsubpara,
li.YPsubsubpara,
div.YPsubsubpara,
p.YPsubsubsubpara,
li.YPsubsubsubpara,
div.YPsubsubsubpara {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YPsubsubsubpara,
li.YPsubsubsubpara,
div.YPsubsubsubpara {
  margin-left: 89.65pt;
  mso-style-name: YPsubsubsubpara;
}
p.Pheading,
li.Pheading,
div.Pheading {
  layout-grid-mode: line;
  margin-top: 7.5pt;
  mso-style-name: Pheading;
  text-align: center;
}
p.Pheading,
li.Pheading,
div.Pheading,
p.headnote,
li.headnote,
div.headnote {
  font-weight: bold;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  page-break-after: avoid;
}
p.headnote,
li.headnote,
div.headnote {
  margin-top: 6pt;
  mso-style-name: headnote;
}
p.defPnote,
li.defPnote,
div.defPnote {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5pt;
  mso-style-name: defPnote;
  text-align: justify;
}
p.Yprocsection,
li.Yprocsection,
div.Yprocsection {
  mso-style-name: Yprocsection;
}
p.Yprocsection,
li.Yprocsection,
div.Yprocsection,
p.Yprocsubsection,
li.Yprocsubsection,
div.Yprocsubsection {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 12pt;
  margin-right: 0cm;
  margin-top: 5pt;
  text-align: justify;
}
p.Yprocsubsection,
li.Yprocsubsection,
div.Yprocsubsection {
  mso-style-name: Yprocsubsection;
}
p.YprocSsection,
li.YprocSsection,
div.YprocSsection {
  mso-style-name: YprocSsection;
}
p.YprocSsection,
li.YprocSsection,
div.YprocSsection,
p.YprocSsubsection,
li.YprocSsubsection,
div.YprocSsubsection {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 12pt;
  margin-right: 0cm;
  margin-top: 5pt;
  text-align: justify;
}
p.YprocSsubsection,
li.YprocSsubsection,
div.YprocSsubsection {
  mso-style-name: YprocSsubsection;
}
p.Yprocclause,
li.Yprocclause,
div.Yprocclause {
  mso-style-name: Yprocclause;
}
p.Yprocclause,
li.Yprocclause,
div.Yprocclause,
p.Yprocparagraph,
li.Yprocparagraph,
div.Yprocparagraph {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 38.9pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yprocparagraph,
li.Yprocparagraph,
div.Yprocparagraph {
  mso-style-name: Yprocparagraph;
}
p.Yprocdefclause,
li.Yprocdefclause,
div.Yprocdefclause {
  margin-left: 38.9pt;
  mso-style-name: Yprocdefclause;
}
p.Yprocdefclause,
li.Yprocdefclause,
div.Yprocdefclause,
p.Yprocdefinition,
li.Yprocdefinition,
div.Yprocdefinition {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yprocdefinition,
li.Yprocdefinition,
div.Yprocdefinition {
  margin-left: 21.5pt;
  mso-style-name: Yprocdefinition;
}
p.Yprocdefparagraph,
li.Yprocdefparagraph,
div.Yprocdefparagraph {
  margin-left: 38.9pt;
  mso-style-name: Yprocdefparagraph;
}
p.Yprocdefparagraph,
li.Yprocdefparagraph,
div.Yprocdefparagraph,
p.Yprocfirstdef,
li.Yprocfirstdef,
div.Yprocfirstdef {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yprocfirstdef,
li.Yprocfirstdef,
div.Yprocfirstdef {
  margin-left: 21.5pt;
  mso-style-name: Yprocfirstdef;
}
p.YprocSclause,
li.YprocSclause,
div.YprocSclause {
  mso-style-name: YprocSclause;
}
p.YprocSclause,
li.YprocSclause,
div.YprocSclause,
p.YprocSdefclause,
li.YprocSdefclause,
div.YprocSdefclause {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 39.6pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YprocSdefclause,
li.YprocSdefclause,
div.YprocSdefclause {
  mso-style-name: YprocSdefclause;
}
p.YprocSdefinition,
li.YprocSdefinition,
div.YprocSdefinition {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 21.5pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  mso-style-name: YprocSdefinition;
  text-align: justify;
}
p.YprocSdefpara,
li.YprocSdefpara,
div.YprocSdefpara {
  mso-style-name: YprocSdefpara;
}
p.YprocSdefpara,
li.YprocSdefpara,
div.YprocSdefpara,
p.YprocSparagraph,
li.YprocSparagraph,
div.YprocSparagraph {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 39.6pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YprocSparagraph,
li.YprocSparagraph,
div.YprocSparagraph {
  mso-style-name: YprocSparagraph;
}
p.Yprocdefsubclause,
li.Yprocdefsubclause,
div.Yprocdefsubclause {
  margin-left: 63.6pt;
  mso-style-name: Yprocdefsubclause;
}
p.Yprocdefsubclause,
li.Yprocdefsubclause,
div.Yprocdefsubclause,
p.Yprocdefsubpara,
li.Yprocdefsubpara,
div.Yprocdefsubpara {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yprocdefsubpara,
li.Yprocdefsubpara,
div.Yprocdefsubpara {
  margin-left: 59.75pt;
  mso-style-name: Yprocdefsubpara;
}
p.Yprocdefsubsubclause,
li.Yprocdefsubsubclause,
div.Yprocdefsubsubclause {
  margin-left: 84pt;
  mso-style-name: Yprocdefsubsubclause;
}
p.Yprocdefsubsubclause,
li.Yprocdefsubsubclause,
div.Yprocdefsubsubclause,
p.Yprocdefsubsubpara,
li.Yprocdefsubsubpara,
div.Yprocdefsubsubpara {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yprocdefsubsubpara,
li.Yprocdefsubsubpara,
div.Yprocdefsubsubpara {
  margin-left: 83.75pt;
  mso-style-name: Yprocdefsubsubpara;
}
p.YprocSsubclause,
li.YprocSsubclause,
div.YprocSsubclause {
  mso-style-name: YprocSsubclause;
}
p.YprocSsubclause,
li.YprocSsubclause,
div.YprocSsubclause,
p.YprocSsubpara,
li.YprocSsubpara,
div.YprocSsubpara {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 59.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YprocSsubpara,
li.YprocSsubpara,
div.YprocSsubpara {
  mso-style-name: YprocSsubpara;
}
p.YprocSsubsubclause,
li.YprocSsubsubclause,
div.YprocSsubsubclause {
  mso-style-name: YprocSsubsubclause;
}
p.YprocSsubsubclause,
li.YprocSsubsubclause,
div.YprocSsubsubclause,
p.YprocSsubsubpara,
li.YprocSsubsubpara,
div.YprocSsubsubpara {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 83.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YprocSsubsubpara,
li.YprocSsubsubpara,
div.YprocSsubsubpara {
  mso-style-name: YprocSsubsubpara;
}
p.Yprocsubclause,
li.Yprocsubclause,
div.Yprocsubclause {
  mso-style-name: Yprocsubclause;
}
p.Yprocsubclause,
li.Yprocsubclause,
div.Yprocsubclause,
p.Yprocsubpara,
li.Yprocsubpara,
div.Yprocsubpara {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 59.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yprocsubpara,
li.Yprocsubpara,
div.Yprocsubpara {
  mso-style-name: Yprocsubpara;
}
p.Yprocsubsubclause,
li.Yprocsubsubclause,
div.Yprocsubsubclause {
  mso-style-name: Yprocsubsubclause;
}
p.Yprocsubsubclause,
li.Yprocsubsubclause,
div.Yprocsubsubclause,
p.Yprocsubsubpara,
li.Yprocsubsubpara,
div.Yprocsubsubpara {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 83.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yprocsubsubpara,
li.Yprocsubsubpara,
div.Yprocsubsubpara {
  mso-style-name: Yprocsubsubpara;
}
p.Yprocsubsubsubclause,
li.Yprocsubsubsubclause,
div.Yprocsubsubsubclause {
  mso-style-name: Yprocsubsubsubclause;
}
p.Yprocsubsubsubclause,
li.Yprocsubsubsubclause,
div.Yprocsubsubsubclause,
p.Yprocsubsubsubpara,
li.Yprocsubsubsubpara,
div.Yprocsubsubsubpara {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 101.65pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yprocsubsubsubpara,
li.Yprocsubsubsubpara,
div.Yprocsubsubsubpara {
  mso-style-name: Yprocsubsubsubpara;
}
p.YprocPnote,
li.YprocPnote,
div.YprocPnote {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 12pt;
  margin-right: 0cm;
  margin-top: 5pt;
  mso-style-name: YprocPnote;
  text-align: justify;
}
span.StatuteChap {
  mso-style-name: StatuteChap;
}
p.StatuteHeader,
li.StatuteHeader,
div.StatuteHeader {
  font-style: italic;
  margin: 0cm;
  mso-style-name: StatuteHeader;
}
p.procparagraph,
li.procparagraph,
div.procparagraph {
  mso-style-name: procparagraph;
}
p.procparagraph,
li.procparagraph,
div.procparagraph,
p.procclause,
li.procclause,
div.procclause {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 26.9pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.procclause,
li.procclause,
div.procclause {
  mso-style-name: procclause;
}
p.TOCid,
li.TOCid,
div.TOCid {
  mso-style-name: TOCid;
}
p.TOCid,
li.TOCid,
div.TOCid,
p.TOCheadCenter,
li.TOCheadCenter,
div.TOCheadCenter {
  color: blue;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
  text-decoration: underline;
}
p.TOCheadCenter,
li.TOCheadCenter,
div.TOCheadCenter {
  font-variant: small-caps;
  mso-style-name: TOCheadCenter;
  text-align: center;
}
p.TOCtable,
li.TOCtable,
div.TOCtable {
  color: blue;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
  mso-style-name: TOCtable;
  text-decoration: underline;
}
p.TOCschedCenter,
li.TOCschedCenter,
div.TOCschedCenter {
  mso-style-name: TOCschedCenter;
}
p.TOCschedCenter,
li.TOCschedCenter,
div.TOCschedCenter,
p.TOCpartCenter,
li.TOCpartCenter,
div.TOCpartCenter {
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
  text-align: center;
}
p.TOCpartCenter,
li.TOCpartCenter,
div.TOCpartCenter {
  font-weight: bold;
  mso-style-name: TOCpartCenter;
}
p.issue,
li.issue,
div.issue {
  margin-bottom: 35.85pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 3.55pt;
  mso-style-name: issue;
}
p.transsection,
li.transsection,
div.transsection {
  mso-style-name: transsection;
}
p.transsection,
li.transsection,
div.transsection,
p.transsubsection,
li.transsubsection,
div.transsubsection {
  font-weight: bold;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5pt;
  text-align: justify;
}
p.transsubsection,
li.transsubsection,
div.transsubsection {
  mso-style-name: transsubsection;
}
p.Yprocpartnum,
li.Yprocpartnum,
div.Yprocpartnum {
  background: #d9d9d9;
  font-weight: bold;
  margin-left: 0cm;
  margin-top: 7.5pt;
  mso-style-name: Yprocpartnum;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.Yprocpartnum,
li.Yprocpartnum,
div.Yprocpartnum,
p.NoticeAmend,
li.NoticeAmend,
div.NoticeAmend {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
}
p.NoticeAmend,
li.NoticeAmend,
div.NoticeAmend {
  color: red;
  margin-left: 88.8pt;
  margin-top: 4pt;
  mso-style-name: NoticeAmend;
  text-align: justify;
}
p.Standard,
li.Standard,
div.Standard {
  margin-top: 5pt;
  mso-style-name: Standard;
  text-align: justify;
}
p.Standard,
li.Standard,
div.Standard,
p.Yheadingx,
li.Yheadingx,
div.Yheadingx {
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
}
p.Yheadingx,
li.Yheadingx,
div.Yheadingx {
  background: #d9d9d9;
  margin-top: 7.5pt;
  mso-style-name: Yheadingx;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.Yschedule,
li.Yschedule,
div.Yschedule {
  background: #d9d9d9;
  margin-bottom: 3pt;
  margin-top: 7.5pt;
  mso-style-name: Yschedule;
  text-transform: uppercase;
}
p.Yschedule,
li.Yschedule,
div.Yschedule,
p.amendednote,
li.amendednote,
div.amendednote {
  margin-left: 0cm;
  margin-right: 0cm;
  page-break-after: avoid;
  text-align: center;
}
p.amendednote,
li.amendednote,
div.amendednote {
  font-style: italic;
  layout-grid-mode: line;
  margin-bottom: 6.95pt;
  margin-top: 0cm;
  mso-style-name: amendednote;
}
p.form,
li.form,
div.form {
  margin-bottom: 0cm;
  margin-top: 7pt;
  mso-style-name: form;
}
p.form,
li.form,
div.form,
p.regnumber,
li.regnumber,
div.regnumber {
  margin-left: 0cm;
  margin-right: 0cm;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.regnumber,
li.regnumber,
div.regnumber {
  font-weight: bold;
  margin-bottom: 7pt;
  margin-top: 0cm;
  mso-style-name: regnumber;
}
p.regtitle,
li.regtitle,
div.regtitle {
  font-weight: bold;
  margin-bottom: 6.95pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  mso-style-name: regtitle;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.ruleb,
li.ruleb,
div.ruleb {
  mso-style-name: ruleb;
}
p.ruleb,
li.ruleb,
div.ruleb,
p.rulec,
li.rulec,
div.rulec {
  font-weight: bold;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 6.95pt;
}
p.rulec,
li.rulec,
div.rulec {
  mso-style-name: rulec;
  text-align: center;
  text-transform: uppercase;
}
p.rulei,
li.rulei,
div.rulei {
  font-style: italic;
  mso-style-name: rulei;
}
p.rulei,
li.rulei,
div.rulei,
p.rulel,
li.rulel,
div.rulel {
  font-weight: bold;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 6.95pt;
}
p.rulel,
li.rulel,
div.rulel {
  mso-style-name: rulel;
  text-transform: uppercase;
}
p.subject,
li.subject,
div.subject {
  margin-top: 7pt;
  mso-style-name: subject;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.subject,
li.subject,
div.subject,
p.version,
li.version,
div.version {
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
}
p.version,
li.version,
div.version {
  font-style: italic;
  font-weight: bold;
  margin-top: 6.95pt;
  mso-style-name: version;
}
p.ActTitle,
li.ActTitle,
div.ActTitle {
  font-weight: bold;
  margin-bottom: 10pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  mso-style-name: ActTitle;
  text-transform: uppercase;
}
p.ActTitle,
li.ActTitle,
div.ActTitle,
p.regaction,
li.regaction,
div.regaction {
  page-break-after: avoid;
  text-align: center;
}
p.regaction,
li.regaction,
div.regaction {
  layout-grid-mode: line;
  margin: 0cm;
  mso-style-name: regaction;
}
p.dated,
li.dated,
div.dated {
  margin-bottom: 11.95pt;
  margin-top: 14.45pt;
  mso-style-name: dated;
}
p.dated,
li.dated,
div.dated,
p.madeappfiled,
li.madeappfiled,
div.madeappfiled {
  margin-left: 0cm;
  margin-right: 0cm;
  page-break-after: avoid;
}
p.madeappfiled,
li.madeappfiled,
div.madeappfiled {
  margin-bottom: 6pt;
  margin-top: 0cm;
  mso-style-name: "made\/app\/filed";
  text-align: center;
}
p.regtitleold,
li.regtitleold,
div.regtitleold {
  margin-bottom: 6.95pt;
  mso-style-name: regtitleold;
  text-align: center;
  text-transform: uppercase;
}
p.regtitleold,
li.regtitleold,
div.regtitleold,
p.commiss,
li.commiss,
div.commiss {
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  page-break-after: avoid;
}
p.commiss,
li.commiss,
div.commiss {
  font-variant: small-caps;
  margin-bottom: 23.9pt;
  mso-style-name: commiss;
  text-align: right;
}
p.Yact,
li.Yact,
div.Yact {
  font-style: italic;
  mso-style-name: Yact;
}
p.Yact,
li.Yact,
div.Yact,
p.Yform,
li.Yform,
div.Yform {
  background: #d9d9d9;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7pt;
  page-break-after: avoid;
  text-align: center;
}
p.Yform,
li.Yform,
div.Yform {
  mso-style-name: Yform;
  text-transform: uppercase;
}
p.note,
li.note,
div.note {
  margin-bottom: 7pt;
  margin-top: 0cm;
  mso-style-name: note;
}
p.note,
li.note,
div.note,
p.Yminnote,
li.Yminnote,
div.Yminnote {
  margin-left: 0cm;
  margin-right: 0cm;
  text-align: justify;
}
p.Yminnote,
li.Yminnote,
div.Yminnote {
  background: #d9d9d9;
  font-style: italic;
  margin-bottom: 10.45pt;
  margin-top: 4.55pt;
  mso-style-name: Yminnote;
}
p.Yruleb,
li.Yruleb,
div.Yruleb {
  mso-style-name: Yruleb;
}
p.Yruleb,
li.Yruleb,
div.Yruleb,
p.Yrulel,
li.Yrulel,
div.Yrulel {
  background: #d9d9d9;
  font-weight: bold;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 6.95pt;
}
p.Yrulel,
li.Yrulel,
div.Yrulel {
  mso-style-name: Yrulel;
  text-transform: uppercase;
}
p.Yrulec,
li.Yrulec,
div.Yrulec {
  mso-style-name: Yrulec;
  text-align: center;
  text-transform: uppercase;
}
p.Yrulec,
li.Yrulec,
div.Yrulec,
p.Yrulei,
li.Yrulei,
div.Yrulei {
  background: #d9d9d9;
  font-weight: bold;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 6.95pt;
}
p.Yrulei,
li.Yrulei,
div.Yrulei {
  font-style: italic;
  mso-style-name: Yrulei;
}
p.Ysubject,
li.Ysubject,
div.Ysubject {
  margin-top: 7pt;
  mso-style-name: Ysubject;
  text-align: center;
  text-transform: uppercase;
}
p.Ysubject,
li.Ysubject,
div.Ysubject,
p.Yheadnote,
li.Yheadnote,
div.Yheadnote {
  background: #d9d9d9;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  page-break-after: avoid;
}
p.Yheadnote,
li.Yheadnote,
div.Yheadnote {
  font-weight: bold;
  margin-top: 6pt;
  mso-style-name: Yheadnote;
}
p.TOChead,
li.TOChead,
div.TOChead {
  color: blue;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
  mso-style-name: TOChead;
  text-decoration: underline;
}
span.ovbold {
  font-weight: bold;
  mso-style-name: ovbold;
}
span.ovitalic {
  font-style: italic;
  mso-style-name: ovitalic;
}
span.ovsmallcap {
  font-variant: small-caps;
  mso-style-name: ovsmallcap;
}
span.ovregular {
  font-weight: bold;
  mso-style-name: ovregular;
}
span.ovitalicbold {
  font-style: italic;
  font-weight: bold;
  mso-style-name: ovitalicbold;
}
p.tablelevel1,
li.tablelevel1,
div.tablelevel1 {
  margin-left: 18pt;
  mso-style-name: tablelevel1;
}
p.tablelevel1,
li.tablelevel1,
div.tablelevel1,
p.tablelevel2,
li.tablelevel2,
div.tablelevel2 {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.tablelevel2,
li.tablelevel2,
div.tablelevel2 {
  margin-left: 30pt;
  mso-style-name: tablelevel2;
}
p.tablelevel3,
li.tablelevel3,
div.tablelevel3 {
  margin-left: 42pt;
  mso-style-name: tablelevel3;
}
p.tablelevel3,
li.tablelevel3,
div.tablelevel3,
p.tablelevel4,
li.tablelevel4,
div.tablelevel4 {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.tablelevel4,
li.tablelevel4,
div.tablelevel4 {
  margin-left: 54pt;
  mso-style-name: tablelevel4;
}
p.tablelevel1x,
li.tablelevel1x,
div.tablelevel1x {
  margin-left: 18pt;
  mso-style-name: tablelevel1x;
}
p.tablelevel1x,
li.tablelevel1x,
div.tablelevel1x,
p.tablelevel2x,
li.tablelevel2x,
div.tablelevel2x {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.tablelevel2x,
li.tablelevel2x,
div.tablelevel2x {
  margin-left: 30pt;
  mso-style-name: tablelevel2x;
}
p.tablelevel3x,
li.tablelevel3x,
div.tablelevel3x {
  margin-left: 42pt;
  mso-style-name: tablelevel3x;
}
p.tablelevel3x,
li.tablelevel3x,
div.tablelevel3x,
p.tablelevel4x,
li.tablelevel4x,
div.tablelevel4x {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.tablelevel4x,
li.tablelevel4x,
div.tablelevel4x {
  margin-left: 54pt;
  mso-style-name: tablelevel4x;
}
p.headnoteind,
li.headnoteind,
div.headnoteind {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 12.25pt;
  margin-right: 0cm;
  margin-top: 6pt;
  mso-style-name: headnoteind;
}
p.footnoteLeft,
li.footnoteLeft,
div.footnoteLeft {
  margin-top: 5.55pt;
  mso-style-name: footnoteLeft;
  text-align: justify;
}
p.footnoteLeft,
li.footnoteLeft,
div.footnoteLeft,
p.TOCpartLeft,
li.TOCpartLeft,
div.TOCpartLeft {
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
}
p.TOCpartLeft,
li.TOCpartLeft,
div.TOCpartLeft {
  font-weight: bold;
  margin-top: 0.55pt;
  mso-style-name: TOCpartLeft;
}
p.TOCschedLeft,
li.TOCschedLeft,
div.TOCschedLeft {
  mso-style-name: TOCschedLeft;
}
p.TOCschedLeft,
li.TOCschedLeft,
div.TOCschedLeft,
p.TOCheadLeft,
li.TOCheadLeft,
div.TOCheadLeft {
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.TOCheadLeft,
li.TOCheadLeft,
div.TOCheadLeft {
  color: blue;
  font-variant: small-caps;
  mso-style-name: TOCheadLeft;
  text-decoration: underline;
}
p.Yfootnote,
li.Yfootnote,
div.Yfootnote {
  mso-style-name: Yfootnote;
  text-align: right;
}
p.Yfootnote,
li.Yfootnote,
div.Yfootnote,
p.Yfootnoteleft,
li.Yfootnoteleft,
div.Yfootnoteleft {
  background: #d9d9d9;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
}
p.Yfootnoteleft,
li.Yfootnoteleft,
div.Yfootnoteleft {
  mso-style-name: Yfootnoteleft;
  text-align: justify;
}
p.TOCpart,
li.TOCpart,
div.TOCpart {
  font-weight: bold;
  mso-style-name: TOCpart;
}
p.TOCpart,
li.TOCpart,
div.TOCpart,
p.TOCsched,
li.TOCsched,
div.TOCsched {
  color: blue;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
  text-decoration: underline;
}
p.TOCsched,
li.TOCsched,
div.TOCsched {
  mso-style-name: TOCsched;
}
p.tocpartnum,
li.tocpartnum,
div.tocpartnum {
  font-weight: bold;
  margin-bottom: 2pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 6pt;
  mso-style-name: tocpartnum;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
span.ovallcaps {
  mso-style-name: ovallcaps;
  text-transform: uppercase;
}
span.ovboldallcaps {
  font-weight: bold;
  mso-style-name: ovboldallcaps;
  text-transform: uppercase;
}
p.OLCheader,
li.OLCheader,
div.OLCheader {
  layout-grid-mode: line;
  margin: 0cm;
  mso-style-name: OLCheader;
}
p.OLCfooter,
li.OLCfooter,
div.OLCfooter {
  margin-left: 0cm;
  margin-top: 3.5pt;
  mso-style-name: OLCfooter;
  text-align: center;
}
p.OLCfooter,
li.OLCfooter,
div.OLCfooter,
p.Ytablelevel1,
li.Ytablelevel1,
div.Ytablelevel1 {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
}
p.Ytablelevel1,
li.Ytablelevel1,
div.Ytablelevel1 {
  background: #d9d9d9;
  margin-left: 18pt;
  margin-top: 0.55pt;
  mso-style-name: Ytablelevel1;
}
p.Ytablelevel1x,
li.Ytablelevel1x,
div.Ytablelevel1x {
  margin-left: 18pt;
  mso-style-name: Ytablelevel1x;
}
p.Ytablelevel1x,
li.Ytablelevel1x,
div.Ytablelevel1x,
p.Ytablelevel2,
li.Ytablelevel2,
div.Ytablelevel2 {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.Ytablelevel2,
li.Ytablelevel2,
div.Ytablelevel2 {
  margin-left: 30pt;
  mso-style-name: Ytablelevel2;
}
p.Ytablelevel2x,
li.Ytablelevel2x,
div.Ytablelevel2x {
  margin-left: 30pt;
  mso-style-name: Ytablelevel2x;
}
p.Ytablelevel2x,
li.Ytablelevel2x,
div.Ytablelevel2x,
p.Ytablelevel3,
li.Ytablelevel3,
div.Ytablelevel3 {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.Ytablelevel3,
li.Ytablelevel3,
div.Ytablelevel3 {
  margin-left: 42pt;
  mso-style-name: Ytablelevel3;
}
p.Ytablelevel3x,
li.Ytablelevel3x,
div.Ytablelevel3x {
  margin-left: 42pt;
  mso-style-name: Ytablelevel3x;
}
p.Ytablelevel3x,
li.Ytablelevel3x,
div.Ytablelevel3x,
p.Ytablelevel4,
li.Ytablelevel4,
div.Ytablelevel4 {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.Ytablelevel4,
li.Ytablelevel4,
div.Ytablelevel4 {
  margin-left: 54pt;
  mso-style-name: Ytablelevel4;
}
p.Ytablelevel4x,
li.Ytablelevel4x,
div.Ytablelevel4x {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 54pt;
  margin-right: 0cm;
  margin-top: 0.55pt;
  mso-style-name: Ytablelevel4x;
}
p.parawindt2,
li.parawindt2,
div.parawindt2 {
  margin-left: 27.85pt;
  mso-style-name: parawindt2;
}
p.parawindt2,
li.parawindt2,
div.parawindt2,
p.parawindt3,
li.parawindt3,
div.parawindt3 {
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 4.8pt;
  text-align: justify;
}
p.parawindt3,
li.parawindt3,
div.parawindt3 {
  margin-left: 41.75pt;
  mso-style-name: parawindt3;
}
p.tableheadingrev,
li.tableheadingrev,
div.tableheadingrev {
  margin-bottom: 6.95pt;
  margin-top: 0cm;
  mso-style-name: tableheadingrev;
}
p.tableheadingrev,
li.tableheadingrev,
div.tableheadingrev,
p.Yheading1x,
li.Yheading1x,
div.Yheading1x {
  layout-grid-mode: line;
  margin-left: 0cm;
  margin-right: 0cm;
  page-break-after: avoid;
  text-align: center;
}
p.Yheading1x,
li.Yheading1x,
div.Yheading1x {
  background: #d9d9d9;
  font-variant: small-caps;
  margin-bottom: 0cm;
  margin-top: 7.5pt;
  mso-style-name: Yheading1x;
}
p.Yprocheadnote,
li.Yprocheadnote,
div.Yprocheadnote {
  background: #d9d9d9;
  font-weight: bold;
  margin-bottom: 0cm;
  margin-left: 12pt;
  margin-right: 0cm;
  margin-top: 6pt;
  mso-style-name: Yprocheadnote;
  page-break-after: avoid;
}
p.tableitalic,
li.tableitalic,
div.tableitalic {
  mso-style-name: tableitalic;
}
p.tableitalic,
li.tableitalic,
div.tableitalic,
p.Ytableitalic,
li.Ytableitalic,
div.Ytableitalic {
  font-style: italic;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.Ytableitalic,
li.Ytableitalic,
div.Ytableitalic {
  background: #d9d9d9;
  mso-style-name: Ytableitalic;
}
p.tablebold,
li.tablebold,
div.tablebold {
  mso-style-name: tablebold;
}
p.tablebold,
li.tablebold,
div.tablebold,
p.Ytablebold,
li.Ytablebold,
div.Ytablebold {
  font-weight: bold;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.Ytablebold,
li.Ytablebold,
div.Ytablebold {
  background: #d9d9d9;
  mso-style-name: Ytablebold;
}
p.bhnote,
li.bhnote,
div.bhnote {
  margin-bottom: 7pt;
  margin-left: 0cm;
  margin-top: 0cm;
  mso-style-name: bhnote;
}
p.bhnote,
li.bhnote,
div.bhnote,
p.Yprocdefsubsubsubclause,
li.Yprocdefsubsubsubclause,
div.Yprocdefsubsubsubclause {
  layout-grid-mode: line;
  margin-right: 0cm;
  text-align: justify;
}
p.Yprocdefsubsubsubclause,
li.Yprocdefsubsubsubclause,
div.Yprocdefsubsubsubclause {
  background: #d9d9d9;
  margin-bottom: 0cm;
  margin-left: 101.65pt;
  margin-top: 5.55pt;
  mso-style-name: Yprocdefsubsubsubclause;
}
p.Yprocheading1,
li.Yprocheading1,
div.Yprocheading1 {
  background: #d9d9d9;
  font-variant: small-caps;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 12pt;
  margin-right: 0cm;
  margin-top: 7.5pt;
  mso-style-name: Yprocheading1;
  page-break-after: avoid;
  text-align: center;
}
p.tableitaliclevel1x,
li.tableitaliclevel1x,
div.tableitaliclevel1x {
  layout-grid-mode: line;
  margin-left: 18pt;
  mso-style-name: tableitaliclevel1x;
}
p.tableitaliclevel1x,
li.tableitaliclevel1x,
div.tableitaliclevel1x,
p.tablebolditalic,
li.tablebolditalic,
div.tablebolditalic {
  font-style: italic;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.tablebolditalic,
li.tablebolditalic,
div.tablebolditalic {
  font-weight: bold;
  margin-left: 0cm;
  mso-style-name: tablebolditalic;
}
p.headnoteitalic,
li.headnoteitalic,
div.headnoteitalic {
  font-style: italic;
  margin-bottom: 0cm;
  margin-top: 6pt;
  mso-style-name: headnoteitalic;
}
p.headnoteitalic,
li.headnoteitalic,
div.headnoteitalic,
p.Pschedule,
li.Pschedule,
div.Pschedule {
  font-weight: bold;
  margin-left: 0cm;
  margin-right: 0cm;
  page-break-after: avoid;
}
p.Pschedule,
li.Pschedule,
div.Pschedule {
  margin-bottom: 3pt;
  margin-top: 7.5pt;
  mso-style-name: Pschedule;
  text-align: center;
  text-transform: uppercase;
}
p.rsignature,
li.rsignature,
div.rsignature {
  mso-style-name: rsignature;
  text-align: right;
}
p.rsignature,
li.rsignature,
div.rsignature,
p.lsignature,
li.lsignature,
div.lsignature {
  font-variant: small-caps;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 2.45pt;
  page-break-after: avoid;
}
p.lsignature,
li.lsignature,
div.lsignature {
  mso-style-name: lsignature;
}
p.rsigntit,
li.rsigntit,
div.rsigntit {
  mso-style-name: rsigntit;
  text-align: right;
}
p.rsigntit,
li.rsigntit,
div.rsigntit,
p.lsigntit,
li.lsigntit,
div.lsigntit {
  font-style: italic;
  margin-bottom: 11.95pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  page-break-after: avoid;
}
p.lsigntit,
li.lsigntit,
div.lsigntit {
  mso-style-name: lsigntit;
}
p.Signature2,
li.Signature2,
div.Signature2 {
  font-variant: small-caps;
  margin-bottom: 0cm;
  margin-top: 2.45pt;
  mso-style-name: Signature2;
}
p.Signature2,
li.Signature2,
div.Signature2,
p.signtit,
li.signtit,
div.signtit {
  margin-left: 0cm;
  margin-right: 0cm;
  page-break-after: avoid;
  text-align: right;
}
p.signtit,
li.signtit,
div.signtit {
  font-style: italic;
  margin-bottom: 11.95pt;
  margin-top: 0cm;
  mso-style-name: signtit;
}
p.certify,
li.certify,
div.certify {
  margin-bottom: 11.95pt;
  margin-top: 14.45pt;
  mso-style-name: certify;
}
p.certify,
li.certify,
div.certify,
p.YPheading3,
li.YPheading3,
div.YPheading3 {
  margin-left: 0cm;
  margin-right: 0cm;
  page-break-after: avoid;
}
p.YPheading3,
li.YPheading3,
div.YPheading3 {
  background: #d9d9d9;
  font-weight: bold;
  margin-bottom: 0cm;
  margin-top: 7.5pt;
  mso-style-name: YPheading3;
  text-align: center;
}
p.Yproctablelevel1x,
li.Yproctablelevel1x,
div.Yproctablelevel1x {
  mso-style-name: Yproctablelevel1x;
}
p.Yproctablelevel1x,
li.Yproctablelevel1x,
div.Yproctablelevel1x,
p.Yproctableboldlevel1x,
li.Yproctableboldlevel1x,
div.Yproctableboldlevel1x {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 12pt;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.Yproctableboldlevel1x,
li.Yproctableboldlevel1x,
div.Yproctableboldlevel1x {
  font-weight: bold;
  mso-style-name: Yproctableboldlevel1x;
}
p.ConsolidationPeriod,
li.ConsolidationPeriod,
div.ConsolidationPeriod {
  color: red;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 4.5pt;
  mso-style-name: ConsolidationPeriod;
}
p.Notice,
li.Notice,
div.Notice {
  margin-left: 0cm;
  mso-style-name: Notice;
  text-align: justify;
}
p.Notice,
li.Notice,
div.Notice,
p.NoticeAmend1,
li.NoticeAmend1,
div.NoticeAmend1 {
  color: red;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 4pt;
}
p.NoticeAmend1,
li.NoticeAmend1,
div.NoticeAmend1 {
  margin-left: 36pt;
  mso-style-name: NoticeAmend1;
}
p.NoticeAmend2,
li.NoticeAmend2,
div.NoticeAmend2 {
  margin-left: 72pt;
  mso-style-name: NoticeAmend2;
}
p.NoticeAmend2,
li.NoticeAmend2,
div.NoticeAmend2,
p.NoticeAmend3,
li.NoticeAmend3,
div.NoticeAmend3 {
  color: red;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 4pt;
}
p.NoticeAmend3,
li.NoticeAmend3,
div.NoticeAmend3 {
  margin-left: 36pt;
  mso-style-name: NoticeAmend3;
}
p.NoticeProc1,
li.NoticeProc1,
div.NoticeProc1 {
  color: red;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 36pt;
  margin-right: 0cm;
  margin-top: 6pt;
  mso-style-name: NoticeProc1;
}
p.Yparawindt2,
li.Yparawindt2,
div.Yparawindt2 {
  margin-left: 27.85pt;
  mso-style-name: Yparawindt2;
}
p.Yparawindt2,
li.Yparawindt2,
div.Yparawindt2,
p.Yparawindt3,
li.Yparawindt3,
div.Yparawindt3 {
  background: #d9d9d9;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 4.8pt;
  text-align: justify;
}
p.Yparawindt3,
li.Yparawindt3,
div.Yparawindt3 {
  margin-left: 41.75pt;
  mso-style-name: Yparawindt3;
}
p.Yprocparanoindt,
li.Yprocparanoindt,
div.Yprocparanoindt {
  margin-left: 12.25pt;
  margin-top: 4.8pt;
  mso-style-name: Yprocparanoindt;
}
p.Yprocparanoindt,
li.Yprocparanoindt,
div.Yprocparanoindt,
p.pnoteclause,
li.pnoteclause,
div.pnoteclause {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  text-align: justify;
}
p.pnoteclause,
li.pnoteclause,
div.pnoteclause {
  margin-left: 38.9pt;
  margin-top: 5.55pt;
  mso-style-name: pnoteclause;
}
p.Yregtitle,
li.Yregtitle,
div.Yregtitle {
  background: #d9d9d9;
  font-weight: bold;
  margin-bottom: 6.95pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  mso-style-name: Yregtitle;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.YTOCpartLeft,
li.YTOCpartLeft,
div.YTOCpartLeft {
  font-weight: bold;
  mso-style-name: YTOCpartLeft;
}
p.YTOCpartLeft,
li.YTOCpartLeft,
div.YTOCpartLeft,
p.YTOCid,
li.YTOCid,
div.YTOCid {
  background: #d9d9d9;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.YTOCid,
li.YTOCid,
div.YTOCid {
  color: blue;
  mso-style-name: YTOCid;
  text-decoration: underline;
}
p.YTOCSched,
li.YTOCSched,
div.YTOCSched {
  mso-style-name: YTOCSched;
}
p.YTOCSched,
li.YTOCSched,
div.YTOCSched,
p.YTOCTable,
li.YTOCTable,
div.YTOCTable {
  background: #d9d9d9;
  color: blue;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
  text-decoration: underline;
}
p.YTOCTable,
li.YTOCTable,
div.YTOCTable {
  mso-style-name: YTOCTable;
}
p.YTOCheadLeft,
li.YTOCheadLeft,
div.YTOCheadLeft {
  background: #d9d9d9;
  color: blue;
  font-variant: small-caps;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
  mso-style-name: YTOCheadLeft;
  text-decoration: underline;
}
p.YTOCPartCenter,
li.YTOCPartCenter,
div.YTOCPartCenter {
  font-weight: bold;
  mso-style-name: YTOCPartCenter;
}
p.YTOCPartCenter,
li.YTOCPartCenter,
div.YTOCPartCenter,
p.YTOCHeadCenter,
li.YTOCHeadCenter,
div.YTOCHeadCenter {
  background: #d9d9d9;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
  text-align: center;
}
p.YTOCHeadCenter,
li.YTOCHeadCenter,
div.YTOCHeadCenter {
  color: blue;
  font-variant: small-caps;
  mso-style-name: YTOCHeadCenter;
  text-decoration: underline;
}
p.YTOCHead,
li.YTOCHead,
div.YTOCHead {
  background: #d9d9d9;
  mso-style-name: YTOCHead;
}
p.YTOCHead,
li.YTOCHead,
div.YTOCHead,
p.TOCForm,
li.TOCForm,
div.TOCForm {
  color: blue;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
  text-decoration: underline;
}
p.TOCForm,
li.TOCForm,
div.TOCForm {
  mso-style-name: TOCForm;
}
p.YTOCForm,
li.YTOCForm,
div.YTOCForm {
  background: #d9d9d9;
  color: blue;
  margin-top: 0.55pt;
  mso-style-name: YTOCForm;
  text-decoration: underline;
}
p.YTOCForm,
li.YTOCForm,
div.YTOCForm,
p.act,
li.act,
div.act {
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
}
p.act,
li.act,
div.act {
  font-style: italic;
  margin-top: 7pt;
  mso-style-name: act;
  page-break-after: avoid;
  text-align: center;
}
.MsoChpDefault {
}
/* Page Definitions */
@page WordSection1 {
  margin: 72pt 54pt 57.6pt 72pt;
  size: 612pt 792pt;
}
div.WordSection1 {
  page: WordSection1;
}
/* List Definitions */
ol,
ul {
  margin-bottom: 0cm;
} /* Font Definitions */
@font-face {
  panose-1: 2 4 5 3 5 4 6 3 2 4;
}
@font-face {
  panose-1: 2 4 5 3 5 4 6 3 2 4;
}
@font-face {
}
@font-face {
  panose-1: 2 2 6 3 5 4 5 2 3 4;
}
/* Style Definitions */
h1 {
  font-weight: normal;
  margin-bottom: 0cm;
  margin-top: 12pt;
  mso-style-link: "Heading 1 Char";
}
h1,
p.assent-e,
li.assent-e,
div.assent-e {
  margin-left: 0cm;
  margin-right: 0cm;
  page-break-after: avoid;
}
p.assent-e,
li.assent-e,
div.assent-e {
  mso-style-name: assent-e;
}
p.assent-e,
li.assent-e,
div.assent-e,
p.assent-f,
li.assent-f,
div.assent-f {
  font-style: italic;
  layout-grid-mode: line;
  margin-bottom: 27.9pt;
  margin-top: 9.5pt;
  text-align: right;
}
p.assent-f,
li.assent-f,
div.assent-f {
  margin-left: 0cm;
  margin-right: 0cm;
  mso-style-name: assent-f;
  page-break-after: avoid;
}
p.chapter-e,
li.chapter-e,
div.chapter-e {
  mso-style-name: chapter-e;
}
p.chapter-e,
li.chapter-e,
div.chapter-e,
p.chapter-f,
li.chapter-f,
div.chapter-f {
  layout-grid-mode: line;
  margin-bottom: 15.45pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.chapter-f,
li.chapter-f,
div.chapter-f {
  mso-style-name: chapter-f;
}
p.clause-e,
li.clause-e,
div.clause-e {
  mso-style-name: clause-e;
}
p.clause-e,
li.clause-e,
div.clause-e,
p.clause-f,
li.clause-f,
div.clause-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 26.9pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.clause-f,
li.clause-f,
div.clause-f {
  mso-style-name: clause-f;
}
p.defclause-e,
li.defclause-e,
div.defclause-e {
  mso-style-name: defclause-e;
}
p.defclause-e,
li.defclause-e,
div.defclause-e,
p.defclause-f,
li.defclause-f,
div.defclause-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 26.9pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.defclause-f,
li.defclause-f,
div.defclause-f {
  mso-style-name: defclause-f;
}
p.definition-e,
li.definition-e,
div.definition-e {
  mso-style-name: definition-e;
}
p.definition-e,
li.definition-e,
div.definition-e,
p.definition-f,
li.definition-f,
div.definition-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 9.45pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.definition-f,
li.definition-f,
div.definition-f {
  mso-style-name: definition-f;
}
p.defparagraph-e,
li.defparagraph-e,
div.defparagraph-e {
  mso-style-name: defparagraph-e;
}
p.defparagraph-e,
li.defparagraph-e,
div.defparagraph-e,
p.paragraph-e,
li.paragraph-e,
div.paragraph-e {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 26.9pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.paragraph-e,
li.paragraph-e,
div.paragraph-e {
  mso-style-name: paragraph-e;
}
p.defparagraph-f,
li.defparagraph-f,
div.defparagraph-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 26.9pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  mso-style-name: defparagraph-f;
  text-align: justify;
}
p.defsubclause-e,
li.defsubclause-e,
div.defsubclause-e {
  mso-style-name: defsubclause-e;
}
p.defsubclause-e,
li.defsubclause-e,
div.defsubclause-e,
p.subclause-e,
li.subclause-e,
div.subclause-e {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 47.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.subclause-e,
li.subclause-e,
div.subclause-e {
  mso-style-name: subclause-e;
}
p.defsubclause-f,
li.defsubclause-f,
div.defsubclause-f {
  mso-style-name: defsubclause-f;
}
p.defsubclause-f,
li.defsubclause-f,
div.defsubclause-f,
p.defsubpara-e,
li.defsubpara-e,
div.defsubpara-e {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 47.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.defsubpara-e,
li.defsubpara-e,
div.defsubpara-e {
  mso-style-name: defsubpara-e;
}
p.subpara-e,
li.subpara-e,
div.subpara-e {
  mso-style-name: subpara-e;
}
p.subpara-e,
li.subpara-e,
div.subpara-e,
p.defsubpara-f,
li.defsubpara-f,
div.defsubpara-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 47.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.defsubpara-f,
li.defsubpara-f,
div.defsubpara-f {
  mso-style-name: defsubpara-f;
}
p.defsubsubclause-e,
li.defsubsubclause-e,
div.defsubsubclause-e {
  mso-style-name: defsubsubclause-e;
}
p.defsubsubclause-e,
li.defsubsubclause-e,
div.defsubsubclause-e,
p.subsubclause-e,
li.subsubclause-e,
div.subsubclause-e {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 71.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.subsubclause-e,
li.subsubclause-e,
div.subsubclause-e {
  mso-style-name: subsubclause-e;
}
p.defsubsubclause-f,
li.defsubsubclause-f,
div.defsubsubclause-f {
  mso-style-name: defsubsubclause-f;
}
p.defsubsubclause-f,
li.defsubsubclause-f,
div.defsubsubclause-f,
p.defsubsubpara-e,
li.defsubsubpara-e,
div.defsubsubpara-e {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 71.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.defsubsubpara-e,
li.defsubsubpara-e,
div.defsubsubpara-e {
  mso-style-name: defsubsubpara-e;
}
p.subsubpara-e,
li.subsubpara-e,
div.subsubpara-e {
  mso-style-name: subsubpara-e;
}
p.subsubpara-e,
li.subsubpara-e,
div.subsubpara-e,
p.defsubsubpara-f,
li.defsubsubpara-f,
div.defsubsubpara-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 71.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.defsubsubpara-f,
li.defsubsubpara-f,
div.defsubsubpara-f {
  mso-style-name: defsubsubpara-f;
}
p.ellipsis-e,
li.ellipsis-e,
div.ellipsis-e {
  mso-style-name: ellipsis-e;
}
p.ellipsis-e,
li.ellipsis-e,
div.ellipsis-e,
p.ellipsis-f,
li.ellipsis-f,
div.ellipsis-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: center;
}
p.ellipsis-f,
li.ellipsis-f,
div.ellipsis-f {
  mso-style-name: ellipsis-f;
}
p.equation-e,
li.equation-e,
div.equation-e {
  mso-style-name: equation-e;
}
p.equation-e,
li.equation-e,
div.equation-e,
p.equation-f,
li.equation-f,
div.equation-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: center;
}
p.equation-f,
li.equation-f,
div.equation-f {
  mso-style-name: equation-f;
}
p.firstdef-e,
li.firstdef-e,
div.firstdef-e {
  mso-style-name: firstdef-e;
}
p.firstdef-e,
li.firstdef-e,
div.firstdef-e,
p.firstdef-f,
li.firstdef-f,
div.firstdef-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 9.45pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.firstdef-f,
li.firstdef-f,
div.firstdef-f {
  mso-style-name: firstdef-f;
}
p.footnote-e,
li.footnote-e,
div.footnote-e {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  mso-style-name: footnote-e;
  text-align: right;
}
p.heading1-e,
li.heading1-e,
div.heading1-e {
  mso-style-name: heading1-e;
}
p.heading1-e,
li.heading1-e,
div.heading1-e,
p.heading1-f,
li.heading1-f,
div.heading1-f {
  font-variant: small-caps;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
}
p.heading1-f,
li.heading1-f,
div.heading1-f {
  mso-style-name: heading1-f;
}
p.heading2-e,
li.heading2-e,
div.heading2-e {
  mso-style-name: heading2-e;
}
p.heading2-e,
li.heading2-e,
div.heading2-e,
p.heading2-f,
li.heading2-f,
div.heading2-f {
  font-variant: small-caps;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
}
p.heading2-f,
li.heading2-f,
div.heading2-f {
  mso-style-name: heading2-f;
}
p.heading3-e,
li.heading3-e,
div.heading3-e {
  mso-style-name: heading3-e;
}
p.heading3-e,
li.heading3-e,
div.heading3-e,
p.heading3-f,
li.heading3-f,
div.heading3-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
}
p.heading3-f,
li.heading3-f,
div.heading3-f {
  mso-style-name: heading3-f;
}
p.headingx-e,
li.headingx-e,
div.headingx-e {
  mso-style-name: headingx-e;
}
p.headingx-e,
li.headingx-e,
div.headingx-e,
p.headingx-f,
li.headingx-f,
div.headingx-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.headingx-f,
li.headingx-f,
div.headingx-f {
  mso-style-name: headingx-f;
}
p.insert-e,
li.insert-e,
div.insert-e {
  mso-style-name: insert-e;
}
p.insert-e,
li.insert-e,
div.insert-e,
p.insert-f,
li.insert-f,
div.insert-f {
  font-style: italic;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 11.5pt;
  page-break-after: avoid;
  text-align: justify;
}
p.insert-f,
li.insert-f,
div.insert-f {
  mso-style-name: insert-f;
}
p.line-e,
li.line-e,
div.line-e {
  mso-style-name: line-e;
}
p.line-e,
li.line-e,
div.line-e,
p.line-f,
li.line-f,
div.line-f {
  layout-grid-mode: line;
  margin-bottom: 3pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 3pt;
  text-align: center;
}
p.line-f,
li.line-f,
div.line-f {
  mso-style-name: line-f;
}
p.longtitle-e,
li.longtitle-e,
div.longtitle-e {
  mso-style-name: longtitle-e;
}
p.longtitle-e,
li.longtitle-e,
div.longtitle-e,
p.longtitle-f,
li.longtitle-f,
div.longtitle-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 51.8pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 21pt;
  page-break-after: avoid;
  text-align: center;
}
p.longtitle-f,
li.longtitle-f,
div.longtitle-f {
  mso-style-name: longtitle-f;
}
p.minnote-e,
li.minnote-e,
div.minnote-e {
  mso-style-name: minnote-e;
}
p.minnote-e,
li.minnote-e,
div.minnote-e,
p.minnote-f,
li.minnote-f,
div.minnote-f {
  font-style: italic;
  layout-grid-mode: line;
  margin-bottom: 10.45pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 4.55pt;
  text-align: justify;
}
p.minnote-f,
li.minnote-f,
div.minnote-f {
  mso-style-name: minnote-f;
}
p.number-e,
li.number-e,
div.number-e {
  mso-style-name: number-e;
}
p.number-e,
li.number-e,
div.number-e,
p.number-f,
li.number-f,
div.number-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 20.1pt;
  text-align: justify;
}
p.number-f,
li.number-f,
div.number-f {
  mso-style-name: number-f;
}
p.paragraph-f,
li.paragraph-f,
div.paragraph-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 26.9pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  mso-style-name: paragraph-f;
  text-align: justify;
}
p.paranoindt-e,
li.paranoindt-e,
div.paranoindt-e {
  mso-style-name: paranoindt-e;
}
p.paranoindt-e,
li.paranoindt-e,
div.paranoindt-e,
p.paranoindt-f,
li.paranoindt-f,
div.paranoindt-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 4.8pt;
  text-align: justify;
}
p.paranoindt-f,
li.paranoindt-f,
div.paranoindt-f {
  mso-style-name: paranoindt-f;
}
p.parawindt-e,
li.parawindt-e,
div.parawindt-e {
  mso-style-name: parawindt-e;
}
p.parawindt-e,
li.parawindt-e,
div.parawindt-e,
p.parawindt-f,
li.parawindt-f,
div.parawindt-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 13.95pt;
  margin-right: 0cm;
  margin-top: 4.8pt;
  text-align: justify;
}
p.parawindt-f,
li.parawindt-f,
div.parawindt-f {
  mso-style-name: parawindt-f;
}
p.parawtab-e,
li.parawtab-e,
div.parawtab-e {
  mso-style-name: parawtab-e;
}
p.parawtab-e,
li.parawtab-e,
div.parawtab-e,
p.parawtab-f,
li.parawtab-f,
div.parawtab-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 4.8pt;
  text-align: justify;
}
p.parawtab-f,
li.parawtab-f,
div.parawtab-f {
  mso-style-name: parawtab-f;
}
p.partnum-e,
li.partnum-e,
div.partnum-e {
  mso-style-name: partnum-e;
}
p.partnum-e,
li.partnum-e,
div.partnum-e,
p.partnum-f,
li.partnum-f,
div.partnum-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.partnum-f,
li.partnum-f,
div.partnum-f {
  mso-style-name: partnum-f;
}
p.Pclause-e,
li.Pclause-e,
div.Pclause-e {
  mso-style-name: Pclause-e;
}
p.Pclause-e,
li.Pclause-e,
div.Pclause-e,
p.Pclause-f,
li.Pclause-f,
div.Pclause-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 26.9pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Pclause-f,
li.Pclause-f,
div.Pclause-f {
  mso-style-name: Pclause-f;
}
p.Pheading1-e,
li.Pheading1-e,
div.Pheading1-e {
  mso-style-name: Pheading1-e;
}
p.Pheading1-e,
li.Pheading1-e,
div.Pheading1-e,
p.Pheading1-f,
li.Pheading1-f,
div.Pheading1-f {
  font-variant: small-caps;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
}
p.Pheading1-f,
li.Pheading1-f,
div.Pheading1-f {
  mso-style-name: Pheading1-f;
}
p.Pheading2-e,
li.Pheading2-e,
div.Pheading2-e {
  mso-style-name: Pheading2-e;
}
p.Pheading2-e,
li.Pheading2-e,
div.Pheading2-e,
p.Pheading2-f,
li.Pheading2-f,
div.Pheading2-f {
  font-variant: small-caps;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
}
p.Pheading2-f,
li.Pheading2-f,
div.Pheading2-f {
  mso-style-name: Pheading2-f;
}
p.Pheading3-e,
li.Pheading3-e,
div.Pheading3-e {
  mso-style-name: Pheading3-e;
}
p.Pheading3-e,
li.Pheading3-e,
div.Pheading3-e,
p.Pheading3-f,
li.Pheading3-f,
div.Pheading3-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
}
p.Pheading3-f,
li.Pheading3-f,
div.Pheading3-f {
  mso-style-name: Pheading3-f;
}
p.Pheadingx-e,
li.Pheadingx-e,
div.Pheadingx-e {
  mso-style-name: Pheadingx-e;
}
p.Pheadingx-e,
li.Pheadingx-e,
div.Pheadingx-e,
p.Pheadingx-f,
li.Pheadingx-f,
div.Pheadingx-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.Pheadingx-f,
li.Pheadingx-f,
div.Pheadingx-f {
  mso-style-name: Pheadingx-f;
}
p.Pnote-e,
li.Pnote-e,
div.Pnote-e {
  mso-style-name: Pnote-e;
}
p.Pnote-e,
li.Pnote-e,
div.Pnote-e,
p.Pnote-f,
li.Pnote-f,
div.Pnote-f {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5pt;
  text-align: justify;
}
p.Pnote-f,
li.Pnote-f,
div.Pnote-f {
  mso-style-name: Pnote-f;
}
p.Pparagraph-e,
li.Pparagraph-e,
div.Pparagraph-e {
  mso-style-name: Pparagraph-e;
}
p.Pparagraph-e,
li.Pparagraph-e,
div.Pparagraph-e,
p.Pparagraph-f,
li.Pparagraph-f,
div.Pparagraph-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 26.9pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Pparagraph-f,
li.Pparagraph-f,
div.Pparagraph-f {
  mso-style-name: Pparagraph-f;
}
p.preamble-e,
li.preamble-e,
div.preamble-e {
  mso-style-name: preamble-e;
}
p.preamble-e,
li.preamble-e,
div.preamble-e,
p.preamble-f,
li.preamble-f,
div.preamble-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.preamble-f,
li.preamble-f,
div.preamble-f {
  mso-style-name: preamble-f;
}
p.Psection-e,
li.Psection-e,
div.Psection-e {
  font-weight: bold;
  mso-style-name: Psection-e;
}
p.Psection-e,
li.Psection-e,
div.Psection-e,
p.section-e,
li.section-e,
div.section-e {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5pt;
  text-align: justify;
}
p.section-e,
li.section-e,
div.section-e {
  mso-style-name: section-e;
}
p.Psection-f,
li.Psection-f,
div.Psection-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5pt;
  mso-style-name: Psection-f;
  text-align: justify;
}
p.tableheadingrev-e,
li.tableheadingrev-e,
div.tableheadingrev-e {
  mso-style-name: tableheadingrev-e;
}
p.tableheadingrev-e,
li.tableheadingrev-e,
div.tableheadingrev-e,
p.tableheading-e,
li.tableheading-e,
div.tableheading-e {
  layout-grid-mode: line;
  margin-bottom: 6.95pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  page-break-after: avoid;
  text-align: center;
}
p.tableheading-e,
li.tableheading-e,
div.tableheading-e {
  mso-style-name: tableheading-e;
  text-transform: uppercase;
}
p.Psubclause-e,
li.Psubclause-e,
div.Psubclause-e {
  mso-style-name: Psubclause-e;
}
p.Psubclause-e,
li.Psubclause-e,
div.Psubclause-e,
p.Psubclause-f,
li.Psubclause-f,
div.Psubclause-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 47.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Psubclause-f,
li.Psubclause-f,
div.Psubclause-f {
  mso-style-name: Psubclause-f;
}
p.Psubpara-e,
li.Psubpara-e,
div.Psubpara-e {
  mso-style-name: Psubpara-e;
}
p.Psubpara-e,
li.Psubpara-e,
div.Psubpara-e,
p.Psubpara-f,
li.Psubpara-f,
div.Psubpara-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 47.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Psubpara-f,
li.Psubpara-f,
div.Psubpara-f {
  mso-style-name: Psubpara-f;
}
p.Psubsection-e,
li.Psubsection-e,
div.Psubsection-e {
  font-weight: bold;
  mso-style-name: Psubsection-e;
}
p.Psubsection-e,
li.Psubsection-e,
div.Psubsection-e,
p.subsection-e,
li.subsection-e,
div.subsection-e {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5pt;
  text-align: justify;
}
p.subsection-e,
li.subsection-e,
div.subsection-e {
  mso-style-name: subsection-e;
}
p.Psubsection-f,
li.Psubsection-f,
div.Psubsection-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5pt;
  mso-style-name: Psubsection-f;
  text-align: justify;
}
p.Psubsubclause-e,
li.Psubsubclause-e,
div.Psubsubclause-e {
  mso-style-name: Psubsubclause-e;
}
p.Psubsubclause-e,
li.Psubsubclause-e,
div.Psubsubclause-e,
p.Psubsubclause-f,
li.Psubsubclause-f,
div.Psubsubclause-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 71.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Psubsubclause-f,
li.Psubsubclause-f,
div.Psubsubclause-f {
  mso-style-name: Psubsubclause-f;
}
p.Psubsubpara-e,
li.Psubsubpara-e,
div.Psubsubpara-e {
  mso-style-name: Psubsubpara-e;
}
p.Psubsubpara-e,
li.Psubsubpara-e,
div.Psubsubpara-e,
p.Psubsubpara-f,
li.Psubsubpara-f,
div.Psubsubpara-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 71.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Psubsubpara-f,
li.Psubsubpara-f,
div.Psubsubpara-f {
  mso-style-name: Psubsubpara-f;
}
p.Psubsubsubclause-e,
li.Psubsubsubclause-e,
div.Psubsubsubclause-e {
  font-weight: bold;
  mso-style-name: Psubsubsubclause-e;
}
p.Psubsubsubclause-e,
li.Psubsubsubclause-e,
div.Psubsubsubclause-e,
p.subsubsubclause-e,
li.subsubsubclause-e,
div.subsubsubclause-e {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 89.65pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.subsubsubclause-e,
li.subsubsubclause-e,
div.subsubsubclause-e {
  mso-style-name: subsubsubclause-e;
}
p.Psubsubsubclause-f,
li.Psubsubsubclause-f,
div.Psubsubsubclause-f {
  mso-style-name: Psubsubsubclause-f;
}
p.Psubsubsubclause-f,
li.Psubsubsubclause-f,
div.Psubsubsubclause-f,
p.Psubsubsubpara-e,
li.Psubsubsubpara-e,
div.Psubsubsubpara-e {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 89.65pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Psubsubsubpara-e,
li.Psubsubsubpara-e,
div.Psubsubsubpara-e {
  mso-style-name: Psubsubsubpara-e;
}
p.subsubsubpara-e,
li.subsubsubpara-e,
div.subsubsubpara-e {
  mso-style-name: subsubsubpara-e;
}
p.subsubsubpara-e,
li.subsubsubpara-e,
div.subsubsubpara-e,
p.Psubsubsubpara-f,
li.Psubsubsubpara-f,
div.Psubsubsubpara-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 89.65pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Psubsubsubpara-f,
li.Psubsubsubpara-f,
div.Psubsubsubpara-f {
  font-weight: bold;
  mso-style-name: Psubsubsubpara-f;
}
p.scanned-e,
li.scanned-e,
div.scanned-e {
  mso-style-name: scanned-e;
}
p.scanned-e,
li.scanned-e,
div.scanned-e,
p.scanned-f,
li.scanned-f,
div.scanned-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.55pt;
  text-align: justify;
}
p.scanned-f,
li.scanned-f,
div.scanned-f {
  mso-style-name: scanned-f;
}
p.schedule-e,
li.schedule-e,
div.schedule-e {
  mso-style-name: schedule-e;
}
p.schedule-e,
li.schedule-e,
div.schedule-e,
p.schedule-f,
li.schedule-f,
div.schedule-f {
  layout-grid-mode: line;
  margin-bottom: 3pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.schedule-f,
li.schedule-f,
div.schedule-f {
  mso-style-name: schedule-f;
}
p.Sclause-e,
li.Sclause-e,
div.Sclause-e {
  mso-style-name: Sclause-e;
}
p.Sclause-e,
li.Sclause-e,
div.Sclause-e,
p.Sclause-f,
li.Sclause-f,
div.Sclause-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 26.9pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Sclause-f,
li.Sclause-f,
div.Sclause-f {
  mso-style-name: Sclause-f;
}
p.Sdefclause-e,
li.Sdefclause-e,
div.Sdefclause-e {
  mso-style-name: Sdefclause-e;
}
p.Sdefclause-e,
li.Sdefclause-e,
div.Sdefclause-e,
p.Sdefclause-f,
li.Sdefclause-f,
div.Sdefclause-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 26.9pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Sdefclause-f,
li.Sdefclause-f,
div.Sdefclause-f {
  mso-style-name: Sdefclause-f;
}
p.Sdefinition-e,
li.Sdefinition-e,
div.Sdefinition-e {
  mso-style-name: Sdefinition-e;
}
p.Sdefinition-e,
li.Sdefinition-e,
div.Sdefinition-e,
p.Sdefinition-f,
li.Sdefinition-f,
div.Sdefinition-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 9.5pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Sdefinition-f,
li.Sdefinition-f,
div.Sdefinition-f {
  mso-style-name: Sdefinition-f;
}
p.Sdefpara-e,
li.Sdefpara-e,
div.Sdefpara-e {
  mso-style-name: Sdefpara-e;
}
p.Sdefpara-e,
li.Sdefpara-e,
div.Sdefpara-e,
p.Sdefpara-f,
li.Sdefpara-f,
div.Sdefpara-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 26.9pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Sdefpara-f,
li.Sdefpara-f,
div.Sdefpara-f {
  mso-style-name: Sdefpara-f;
}
p.section-f,
li.section-f,
div.section-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5pt;
  mso-style-name: section-f;
  text-align: justify;
}
p.shorttitle-f,
li.shorttitle-f,
div.shorttitle-f {
  mso-style-name: shorttitle-f;
}
p.shorttitle-f,
li.shorttitle-f,
div.shorttitle-f,
p.shorttitle-e,
li.shorttitle-e,
div.shorttitle-e {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 28.9pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  page-break-after: avoid;
  text-align: center;
}
p.shorttitle-e,
li.shorttitle-e,
div.shorttitle-e {
  mso-style-name: shorttitle-e;
}
p.note-e,
li.note-e,
div.note-e {
  layout-grid-mode: line;
  margin-bottom: 7pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  mso-style-name: note-e;
  text-align: justify;
}
p.parawindt2-e,
li.parawindt2-e,
div.parawindt2-e {
  margin-left: 27.85pt;
  margin-top: 4.8pt;
  mso-style-name: parawindt2-e;
}
p.parawindt2-e,
li.parawindt2-e,
div.parawindt2-e,
p.Sparagraph-e,
li.Sparagraph-e,
div.Sparagraph-e {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  text-align: justify;
}
p.Sparagraph-e,
li.Sparagraph-e,
div.Sparagraph-e {
  margin-left: 26.9pt;
  margin-top: 5.55pt;
  mso-style-name: Sparagraph-e;
}
p.Sparagraph-f,
li.Sparagraph-f,
div.Sparagraph-f {
  margin-left: 26.9pt;
  margin-top: 5.55pt;
  mso-style-name: Sparagraph-f;
}
p.Sparagraph-f,
li.Sparagraph-f,
div.Sparagraph-f,
p.SPsection-e,
li.SPsection-e,
div.SPsection-e {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  text-align: justify;
}
p.SPsection-e,
li.SPsection-e,
div.SPsection-e {
  font-weight: bold;
  margin-left: 0cm;
  margin-top: 5pt;
  mso-style-name: SPsection-e;
}
p.SPsection-f,
li.SPsection-f,
div.SPsection-f {
  mso-style-name: SPsection-f;
}
p.SPsection-f,
li.SPsection-f,
div.SPsection-f,
p.SPsubsection-e,
li.SPsubsection-e,
div.SPsubsection-e {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5pt;
  text-align: justify;
}
p.SPsubsection-e,
li.SPsubsection-e,
div.SPsubsection-e {
  mso-style-name: SPsubsection-e;
}
p.SPsubsection-f,
li.SPsubsection-f,
div.SPsubsection-f {
  font-weight: bold;
  mso-style-name: SPsubsection-f;
}
p.SPsubsection-f,
li.SPsubsection-f,
div.SPsubsection-f,
p.Ssection-e,
li.Ssection-e,
div.Ssection-e {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5pt;
  text-align: justify;
}
p.Ssection-e,
li.Ssection-e,
div.Ssection-e {
  mso-style-name: Ssection-e;
}
p.Ssection-f,
li.Ssection-f,
div.Ssection-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5pt;
  mso-style-name: Ssection-f;
  text-align: justify;
}
p.Ssubclause-e,
li.Ssubclause-e,
div.Ssubclause-e {
  mso-style-name: Ssubclause-e;
}
p.Ssubclause-e,
li.Ssubclause-e,
div.Ssubclause-e,
p.Ssubclause-f,
li.Ssubclause-f,
div.Ssubclause-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 47.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Ssubclause-f,
li.Ssubclause-f,
div.Ssubclause-f {
  mso-style-name: Ssubclause-f;
}
p.Ssubpara-e,
li.Ssubpara-e,
div.Ssubpara-e {
  mso-style-name: Ssubpara-e;
}
p.Ssubpara-e,
li.Ssubpara-e,
div.Ssubpara-e,
p.Ssubpara-f,
li.Ssubpara-f,
div.Ssubpara-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 47.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Ssubpara-f,
li.Ssubpara-f,
div.Ssubpara-f {
  mso-style-name: Ssubpara-f;
}
p.Ssubsection-e,
li.Ssubsection-e,
div.Ssubsection-e {
  mso-style-name: Ssubsection-e;
}
p.Ssubsection-e,
li.Ssubsection-e,
div.Ssubsection-e,
p.Ssubsection-f,
li.Ssubsection-f,
div.Ssubsection-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5pt;
  text-align: justify;
}
p.Ssubsection-f,
li.Ssubsection-f,
div.Ssubsection-f {
  mso-style-name: Ssubsection-f;
}
p.Ssubsubclause-e,
li.Ssubsubclause-e,
div.Ssubsubclause-e {
  mso-style-name: Ssubsubclause-e;
}
p.Ssubsubclause-e,
li.Ssubsubclause-e,
div.Ssubsubclause-e,
p.Ssubsubclause-f,
li.Ssubsubclause-f,
div.Ssubsubclause-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 71.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Ssubsubclause-f,
li.Ssubsubclause-f,
div.Ssubsubclause-f {
  mso-style-name: Ssubsubclause-f;
}
p.Ssubsubpara-e,
li.Ssubsubpara-e,
div.Ssubsubpara-e {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 71.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  mso-style-name: Ssubsubpara-e;
  text-align: justify;
}
p.StartTumble-e,
li.StartTumble-e,
div.StartTumble-e {
  mso-style-name: "Start Tumble-e";
}
p.StartTumble-e,
li.StartTumble-e,
div.StartTumble-e,
p.StartTumble-f,
li.StartTumble-f,
div.StartTumble-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.StartTumble-f,
li.StartTumble-f,
div.StartTumble-f {
  mso-style-name: "Start Tumble-f";
}
p.subclause-f,
li.subclause-f,
div.subclause-f {
  mso-style-name: subclause-f;
}
p.subclause-f,
li.subclause-f,
div.subclause-f,
p.subpara-f,
li.subpara-f,
div.subpara-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 47.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.subpara-f,
li.subpara-f,
div.subpara-f {
  mso-style-name: subpara-f;
}
p.subsection-f,
li.subsection-f,
div.subsection-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5pt;
  mso-style-name: subsection-f;
  text-align: justify;
}
p.subsubclause-f,
li.subsubclause-f,
div.subsubclause-f {
  mso-style-name: subsubclause-f;
}
p.subsubclause-f,
li.subsubclause-f,
div.subsubclause-f,
p.subsubpara-f,
li.subsubpara-f,
div.subsubpara-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 71.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.subsubpara-f,
li.subsubpara-f,
div.subsubpara-f {
  mso-style-name: subsubpara-f;
}
p.subsubsubclause-f,
li.subsubsubclause-f,
div.subsubsubclause-f {
  mso-style-name: subsubsubclause-f;
}
p.subsubsubclause-f,
li.subsubsubclause-f,
div.subsubsubclause-f,
p.subsubsubpara-f,
li.subsubsubpara-f,
div.subsubsubpara-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 89.65pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.subsubsubpara-f,
li.subsubsubpara-f,
div.subsubsubpara-f {
  mso-style-name: subsubsubpara-f;
}
p.table-e,
li.table-e,
div.table-e {
  mso-style-name: table-e;
}
p.table-e,
li.table-e,
div.table-e,
p.table-f,
li.table-f,
div.table-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.table-f,
li.table-f,
div.table-f {
  mso-style-name: table-f;
}
p.toc-e,
li.toc-e,
div.toc-e {
  mso-style-name: toc-e;
}
p.toc-e,
li.toc-e,
div.toc-e,
p.toc-f,
li.toc-f,
div.toc-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 6pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 15pt;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.toc-f,
li.toc-f,
div.toc-f {
  mso-style-name: toc-f;
}
p.tochead1-e,
li.tochead1-e,
div.tochead1-e {
  mso-style-name: tochead1-e;
}
p.tochead1-e,
li.tochead1-e,
div.tochead1-e,
p.tochead1-f,
li.tochead1-f,
div.tochead1-f {
  font-variant: small-caps;
  layout-grid-mode: line;
  margin-bottom: 2pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 4pt;
  page-break-after: avoid;
  text-align: center;
}
p.tochead1-f,
li.tochead1-f,
div.tochead1-f {
  mso-style-name: tochead1-f;
}
p.Yellipsis-e,
li.Yellipsis-e,
div.Yellipsis-e {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  mso-style-name: Yellipsis-e;
  text-align: center;
}
p.xleftpara-e,
li.xleftpara-e,
div.xleftpara-e {
  mso-style-name: xleftpara-e;
}
p.xleftpara-e,
li.xleftpara-e,
div.xleftpara-e,
p.xleftpara-f,
li.xleftpara-f,
div.xleftpara-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.xleftpara-f,
li.xleftpara-f,
div.xleftpara-f {
  mso-style-name: xleftpara-f;
}
p.xnum-e,
li.xnum-e,
div.xnum-e {
  mso-style-name: xnum-e;
}
p.xnum-e,
li.xnum-e,
div.xnum-e,
p.xnum-f,
li.xnum-f,
div.xnum-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 27.95pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.xnum-f,
li.xnum-f,
div.xnum-f {
  mso-style-name: xnum-f;
}
p.xpara-e,
li.xpara-e,
div.xpara-e {
  mso-style-name: xpara-e;
}
p.xpara-e,
li.xpara-e,
div.xpara-e,
p.xpara-f,
li.xpara-f,
div.xpara-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 4.5pt;
  text-align: justify;
}
p.xpara-f,
li.xpara-f,
div.xpara-f {
  mso-style-name: xpara-f;
}
p.xpartnum-e,
li.xpartnum-e,
div.xpartnum-e {
  mso-style-name: xpartnum-e;
}
p.xpartnum-e,
li.xpartnum-e,
div.xpartnum-e,
p.xpartnum-f,
li.xpartnum-f,
div.xpartnum-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 4.55pt;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.xpartnum-f,
li.xpartnum-f,
div.xpartnum-f {
  mso-style-name: xpartnum-f;
}
p.xtitle-e,
li.xtitle-e,
div.xtitle-e {
  mso-style-name: xtitle-e;
}
p.xtitle-e,
li.xtitle-e,
div.xtitle-e,
p.xtitle-f,
li.xtitle-f,
div.xtitle-f {
  layout-grid-mode: line;
  margin-bottom: 9.95pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.xtitle-f,
li.xtitle-f,
div.xtitle-f {
  mso-style-name: xtitle-f;
}
p.Yellipsis-f,
li.Yellipsis-f,
div.Yellipsis-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  mso-style-name: Yellipsis-f;
  text-align: center;
}
p.Ypartheading-e,
li.Ypartheading-e,
div.Ypartheading-e {
  background: #d9d9d9;
  mso-style-name: Ypartheading-e;
}
p.Ypartheading-e,
li.Ypartheading-e,
div.Ypartheading-e,
p.partheading-e,
li.partheading-e,
div.partheading-e {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.partheading-e,
li.partheading-e,
div.partheading-e {
  mso-style-name: partheading-e;
}
p.Ypartheading-f,
li.Ypartheading-f,
div.Ypartheading-f {
  background: #d9d9d9;
  mso-style-name: Ypartheading-f;
}
p.Ypartheading-f,
li.Ypartheading-f,
div.Ypartheading-f,
p.partheading-f,
li.partheading-f,
div.partheading-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.partheading-f,
li.partheading-f,
div.partheading-f {
  mso-style-name: partheading-f;
}
p.YPheadingx-e,
li.YPheadingx-e,
div.YPheadingx-e {
  mso-style-name: YPheadingx-e;
}
p.YPheadingx-e,
li.YPheadingx-e,
div.YPheadingx-e,
p.YPheadingx-f,
li.YPheadingx-f,
div.YPheadingx-f {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.YPheadingx-f,
li.YPheadingx-f,
div.YPheadingx-f {
  mso-style-name: YPheadingx-f;
}
p.Ytable-e,
li.Ytable-e,
div.Ytable-e {
  mso-style-name: Ytable-e;
}
p.Ytable-e,
li.Ytable-e,
div.Ytable-e,
p.Ytable-f,
li.Ytable-f,
div.Ytable-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.Ytable-f,
li.Ytable-f,
div.Ytable-f {
  mso-style-name: Ytable-f;
}
p.Ytoc-e,
li.Ytoc-e,
div.Ytoc-e {
  mso-style-name: Ytoc-e;
}
p.Ytoc-e,
li.Ytoc-e,
div.Ytoc-e,
p.Ytoc-f,
li.Ytoc-f,
div.Ytoc-f {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 6pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 15pt;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.Ytoc-f,
li.Ytoc-f,
div.Ytoc-f {
  mso-style-name: Ytoc-f;
}
p.footnote-f,
li.footnote-f,
div.footnote-f {
  margin-bottom: 0cm;
  margin-top: 5.55pt;
  mso-style-name: footnote-f;
  text-align: right;
}
p.footnote-f,
li.footnote-f,
div.footnote-f,
p.comment-e,
li.comment-e,
div.comment-e {
  layout-grid-mode: line;
  margin-left: 0cm;
  margin-right: 0cm;
}
p.comment-e,
li.comment-e,
div.comment-e {
  margin-bottom: 6pt;
  margin-top: 6pt;
  mso-style-name: comment-e;
}
p.Pheading-e,
li.Pheading-e,
div.Pheading-e {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  mso-style-name: Pheading-e;
  page-break-after: avoid;
  text-align: center;
}
p.comment-f,
li.comment-f,
div.comment-f {
  margin-bottom: 6pt;
  margin-top: 6pt;
  mso-style-name: comment-f;
}
p.comment-f,
li.comment-f,
div.comment-f,
p.tableheading-f,
li.tableheading-f,
div.tableheading-f {
  layout-grid-mode: line;
  margin-left: 0cm;
  margin-right: 0cm;
}
p.tableheading-f,
li.tableheading-f,
div.tableheading-f {
  margin-bottom: 6.95pt;
  margin-top: 0cm;
  mso-style-name: tableheading-f;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.Ypreamble-e,
li.Ypreamble-e,
div.Ypreamble-e {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  mso-style-name: Ypreamble-e;
  text-align: justify;
}
p.Ypartnum-e,
li.Ypartnum-e,
div.Ypartnum-e {
  font-weight: bold;
  mso-style-name: Ypartnum-e;
  text-transform: uppercase;
}
p.Ypartnum-e,
li.Ypartnum-e,
div.Ypartnum-e,
p.Yheading1-e,
li.Yheading1-e,
div.Yheading1-e {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
}
p.Yheading1-e,
li.Yheading1-e,
div.Yheading1-e {
  font-variant: small-caps;
  mso-style-name: Yheading1-e;
}
p.Yheading2-e,
li.Yheading2-e,
div.Yheading2-e {
  font-variant: small-caps;
  mso-style-name: Yheading2-e;
}
p.Yheading2-e,
li.Yheading2-e,
div.Yheading2-e,
p.Yheading3-e,
li.Yheading3-e,
div.Yheading3-e {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
}
p.Yheading3-e,
li.Yheading3-e,
div.Yheading3-e {
  mso-style-name: Yheading3-e;
}
p.Ytableheading-e,
li.Ytableheading-e,
div.Ytableheading-e {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 6.95pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  mso-style-name: Ytableheading-e;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.Yfirstdef-e,
li.Yfirstdef-e,
div.Yfirstdef-e {
  mso-style-name: Yfirstdef-e;
}
p.Yfirstdef-e,
li.Yfirstdef-e,
div.Yfirstdef-e,
p.Ydefinition-e,
li.Ydefinition-e,
div.Ydefinition-e {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 9.45pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Ydefinition-e,
li.Ydefinition-e,
div.Ydefinition-e {
  mso-style-name: Ydefinition-e;
}
p.Ydefclause-e,
li.Ydefclause-e,
div.Ydefclause-e {
  mso-style-name: Ydefclause-e;
}
p.Ydefclause-e,
li.Ydefclause-e,
div.Ydefclause-e,
p.YSdefclause-e,
li.YSdefclause-e,
div.YSdefclause-e {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 26.9pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YSdefclause-e,
li.YSdefclause-e,
div.YSdefclause-e {
  mso-style-name: YSdefclause-e;
}
p.Ydefsubclause-e,
li.Ydefsubclause-e,
div.Ydefsubclause-e {
  margin-left: 47.75pt;
  mso-style-name: Ydefsubclause-e;
}
p.Ydefsubclause-e,
li.Ydefsubclause-e,
div.Ydefsubclause-e,
p.Ydefsubsubclause-e,
li.Ydefsubsubclause-e,
div.Ydefsubsubclause-e {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Ydefsubsubclause-e,
li.Ydefsubsubclause-e,
div.Ydefsubsubclause-e {
  margin-left: 71.75pt;
  mso-style-name: Ydefsubsubclause-e;
}
p.Ydefparagraph-e,
li.Ydefparagraph-e,
div.Ydefparagraph-e {
  mso-style-name: Ydefparagraph-e;
}
p.Ydefparagraph-e,
li.Ydefparagraph-e,
div.Ydefparagraph-e,
p.YSdefpara-e,
li.YSdefpara-e,
div.YSdefpara-e {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 26.9pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YSdefpara-e,
li.YSdefpara-e,
div.YSdefpara-e {
  mso-style-name: YSdefpara-e;
}
p.Ydefsubpara-e,
li.Ydefsubpara-e,
div.Ydefsubpara-e {
  margin-left: 47.75pt;
  mso-style-name: Ydefsubpara-e;
}
p.Ydefsubpara-e,
li.Ydefsubpara-e,
div.Ydefsubpara-e,
p.Ydefsubsubpara-e,
li.Ydefsubsubpara-e,
div.Ydefsubsubpara-e {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Ydefsubsubpara-e,
li.Ydefsubsubpara-e,
div.Ydefsubsubpara-e {
  margin-left: 71.75pt;
  mso-style-name: Ydefsubsubpara-e;
}
p.Ysection-e,
li.Ysection-e,
div.Ysection-e {
  mso-style-name: Ysection-e;
}
p.Ysection-e,
li.Ysection-e,
div.Ysection-e,
p.YSsection-e,
li.YSsection-e,
div.YSsection-e {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5pt;
  text-align: justify;
}
p.YSsection-e,
li.YSsection-e,
div.YSsection-e {
  mso-style-name: YSsection-e;
}
p.Ysubsection-e,
li.Ysubsection-e,
div.Ysubsection-e {
  mso-style-name: Ysubsection-e;
}
p.Ysubsection-e,
li.Ysubsection-e,
div.Ysubsection-e,
p.YSsubsection-e,
li.YSsubsection-e,
div.YSsubsection-e {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5pt;
  text-align: justify;
}
p.YSsubsection-e,
li.YSsubsection-e,
div.YSsubsection-e {
  mso-style-name: YSsubsection-e;
}
p.Yclause-e,
li.Yclause-e,
div.Yclause-e {
  mso-style-name: Yclause-e;
}
p.Yclause-e,
li.Yclause-e,
div.Yclause-e,
p.YSclause-e,
li.YSclause-e,
div.YSclause-e {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 26.9pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YSclause-e,
li.YSclause-e,
div.YSclause-e {
  mso-style-name: YSclause-e;
}
p.Ysubclause-e,
li.Ysubclause-e,
div.Ysubclause-e {
  mso-style-name: Ysubclause-e;
}
p.Ysubclause-e,
li.Ysubclause-e,
div.Ysubclause-e,
p.YSsubclause-e,
li.YSsubclause-e,
div.YSsubclause-e {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 47.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YSsubclause-e,
li.YSsubclause-e,
div.YSsubclause-e {
  mso-style-name: YSsubclause-e;
}
p.Ysubsubclause-e,
li.Ysubsubclause-e,
div.Ysubsubclause-e {
  mso-style-name: Ysubsubclause-e;
}
p.Ysubsubclause-e,
li.Ysubsubclause-e,
div.Ysubsubclause-e,
p.YSsubsubclause-e,
li.YSsubsubclause-e,
div.YSsubsubclause-e {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 71.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YSsubsubclause-e,
li.YSsubsubclause-e,
div.YSsubsubclause-e {
  mso-style-name: YSsubsubclause-e;
}
p.Ysubsubsubclause-e,
li.Ysubsubsubclause-e,
div.Ysubsubsubclause-e {
  margin-left: 89.65pt;
  mso-style-name: Ysubsubsubclause-e;
}
p.Ysubsubsubclause-e,
li.Ysubsubsubclause-e,
div.Ysubsubsubclause-e,
p.Yparagraph-e,
li.Yparagraph-e,
div.Yparagraph-e {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yparagraph-e,
li.Yparagraph-e,
div.Yparagraph-e {
  margin-left: 26.9pt;
  mso-style-name: Yparagraph-e;
}
p.Yparanoindt-e,
li.Yparanoindt-e,
div.Yparanoindt-e {
  margin-left: 0cm;
  mso-style-name: Yparanoindt-e;
}
p.Yparanoindt-e,
li.Yparanoindt-e,
div.Yparanoindt-e,
p.Yparawindt-e,
li.Yparawindt-e,
div.Yparawindt-e {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 4.8pt;
  text-align: justify;
}
p.Yparawindt-e,
li.Yparawindt-e,
div.Yparawindt-e {
  margin-left: 13.9pt;
  mso-style-name: Yparawindt-e;
}
p.Yparawtab-e,
li.Yparawtab-e,
div.Yparawtab-e {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 4.8pt;
  mso-style-name: Yparawtab-e;
  text-align: justify;
}
p.YSparagraph-e,
li.YSparagraph-e,
div.YSparagraph-e {
  margin-left: 26.9pt;
  mso-style-name: YSparagraph-e;
}
p.YSparagraph-e,
li.YSparagraph-e,
div.YSparagraph-e,
p.Ysubpara-e,
li.Ysubpara-e,
div.Ysubpara-e {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Ysubpara-e,
li.Ysubpara-e,
div.Ysubpara-e {
  margin-left: 47.75pt;
  mso-style-name: Ysubpara-e;
}
p.YSsubpara-e,
li.YSsubpara-e,
div.YSsubpara-e {
  margin-left: 47.75pt;
  mso-style-name: YSsubpara-e;
}
p.YSsubpara-e,
li.YSsubpara-e,
div.YSsubpara-e,
p.Ysubsubpara-e,
li.Ysubsubpara-e,
div.Ysubsubpara-e {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Ysubsubpara-e,
li.Ysubsubpara-e,
div.Ysubsubpara-e {
  margin-left: 71.75pt;
  mso-style-name: Ysubsubpara-e;
}
p.YSsubsubpara-e,
li.YSsubsubpara-e,
div.YSsubsubpara-e {
  margin-left: 71.75pt;
  mso-style-name: YSsubsubpara-e;
}
p.YSsubsubpara-e,
li.YSsubsubpara-e,
div.YSsubsubpara-e,
p.Ysubsubsubpara-e,
li.Ysubsubsubpara-e,
div.Ysubsubsubpara-e {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Ysubsubsubpara-e,
li.Ysubsubsubpara-e,
div.Ysubsubsubpara-e {
  margin-left: 89.65pt;
  mso-style-name: Ysubsubsubpara-e;
}
p.Yequation-e,
li.Yequation-e,
div.Yequation-e {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  mso-style-name: Yequation-e;
  text-align: center;
}
p.YPsection-e,
li.YPsection-e,
div.YPsection-e {
  mso-style-name: YPsection-e;
}
p.YPsection-e,
li.YPsection-e,
div.YPsection-e,
p.YSPsection-e,
li.YSPsection-e,
div.YSPsection-e {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5pt;
  text-align: justify;
}
p.YSPsection-e,
li.YSPsection-e,
div.YSPsection-e {
  mso-style-name: YSPsection-e;
}
p.YPsubsection-e,
li.YPsubsection-e,
div.YPsubsection-e {
  mso-style-name: YPsubsection-e;
}
p.YPsubsection-e,
li.YPsubsection-e,
div.YPsubsection-e,
p.YSPsubsection-e,
li.YSPsubsection-e,
div.YSPsubsection-e {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5pt;
  text-align: justify;
}
p.YSPsubsection-e,
li.YSPsubsection-e,
div.YSPsubsection-e {
  mso-style-name: YSPsubsection-e;
}
p.YPclause-e,
li.YPclause-e,
div.YPclause-e {
  margin-left: 26.9pt;
  mso-style-name: YPclause-e;
}
p.YPclause-e,
li.YPclause-e,
div.YPclause-e,
p.YPsubclause-e,
li.YPsubclause-e,
div.YPsubclause-e {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YPsubclause-e,
li.YPsubclause-e,
div.YPsubclause-e {
  margin-left: 47.75pt;
  mso-style-name: YPsubclause-e;
}
p.YPsubsubclause-e,
li.YPsubsubclause-e,
div.YPsubsubclause-e {
  margin-left: 71.75pt;
  mso-style-name: YPsubsubclause-e;
}
p.YPsubsubclause-e,
li.YPsubsubclause-e,
div.YPsubsubclause-e,
p.YPsubsubsubclause-e,
li.YPsubsubsubclause-e,
div.YPsubsubsubclause-e {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YPsubsubsubclause-e,
li.YPsubsubsubclause-e,
div.YPsubsubsubclause-e {
  margin-left: 89.65pt;
  mso-style-name: YPsubsubsubclause-e;
}
p.YPparagraph-e,
li.YPparagraph-e,
div.YPparagraph-e {
  margin-left: 26.9pt;
  mso-style-name: YPparagraph-e;
}
p.YPparagraph-e,
li.YPparagraph-e,
div.YPparagraph-e,
p.YPsubpara-e,
li.YPsubpara-e,
div.YPsubpara-e {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YPsubpara-e,
li.YPsubpara-e,
div.YPsubpara-e {
  margin-left: 47.75pt;
  mso-style-name: YPsubpara-e;
}
p.YPsubsubpara-e,
li.YPsubsubpara-e,
div.YPsubsubpara-e {
  margin-left: 71.75pt;
  mso-style-name: YPsubsubpara-e;
}
p.YPsubsubpara-e,
li.YPsubsubpara-e,
div.YPsubsubpara-e,
p.YPsubsubsubpara-e,
li.YPsubsubsubpara-e,
div.YPsubsubsubpara-e {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YPsubsubsubpara-e,
li.YPsubsubsubpara-e,
div.YPsubsubsubpara-e {
  margin-left: 89.65pt;
  mso-style-name: YPsubsubsubpara-e;
}
p.Ypreamble-f,
li.Ypreamble-f,
div.Ypreamble-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  mso-style-name: Ypreamble-f;
  text-align: justify;
}
p.Ypartnum-f,
li.Ypartnum-f,
div.Ypartnum-f {
  font-weight: bold;
  mso-style-name: Ypartnum-f;
  text-transform: uppercase;
}
p.Ypartnum-f,
li.Ypartnum-f,
div.Ypartnum-f,
p.Yheading1-f,
li.Yheading1-f,
div.Yheading1-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
}
p.Yheading1-f,
li.Yheading1-f,
div.Yheading1-f {
  font-variant: small-caps;
  mso-style-name: Yheading1-f;
}
p.Yheading2-f,
li.Yheading2-f,
div.Yheading2-f {
  font-variant: small-caps;
  mso-style-name: Yheading2-f;
}
p.Yheading2-f,
li.Yheading2-f,
div.Yheading2-f,
p.Yheading3-f,
li.Yheading3-f,
div.Yheading3-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
}
p.Yheading3-f,
li.Yheading3-f,
div.Yheading3-f {
  mso-style-name: Yheading3-f;
}
p.Ytableheading-f,
li.Ytableheading-f,
div.Ytableheading-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 6.95pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  mso-style-name: Ytableheading-f;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.Yfirstdef-f,
li.Yfirstdef-f,
div.Yfirstdef-f {
  mso-style-name: Yfirstdef-f;
}
p.Yfirstdef-f,
li.Yfirstdef-f,
div.Yfirstdef-f,
p.Ydefinition-f,
li.Ydefinition-f,
div.Ydefinition-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 9.45pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Ydefinition-f,
li.Ydefinition-f,
div.Ydefinition-f {
  mso-style-name: Ydefinition-f;
}
p.YSdefinition-f,
li.YSdefinition-f,
div.YSdefinition-f {
  mso-style-name: YSdefinition-f;
}
p.YSdefinition-f,
li.YSdefinition-f,
div.YSdefinition-f,
p.YSdefinition-e,
li.YSdefinition-e,
div.YSdefinition-e {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 9.5pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YSdefinition-e,
li.YSdefinition-e,
div.YSdefinition-e {
  mso-style-name: YSdefinition-e;
}
p.Ydefclause-f,
li.Ydefclause-f,
div.Ydefclause-f {
  mso-style-name: Ydefclause-f;
}
p.Ydefclause-f,
li.Ydefclause-f,
div.Ydefclause-f,
p.YSdefclause-f,
li.YSdefclause-f,
div.YSdefclause-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 26.9pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YSdefclause-f,
li.YSdefclause-f,
div.YSdefclause-f {
  mso-style-name: YSdefclause-f;
}
p.Ydefsubclause-f,
li.Ydefsubclause-f,
div.Ydefsubclause-f {
  margin-left: 47.75pt;
  mso-style-name: Ydefsubclause-f;
}
p.Ydefsubclause-f,
li.Ydefsubclause-f,
div.Ydefsubclause-f,
p.Ydefsubsubclause-f,
li.Ydefsubsubclause-f,
div.Ydefsubsubclause-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Ydefsubsubclause-f,
li.Ydefsubsubclause-f,
div.Ydefsubsubclause-f {
  margin-left: 71.75pt;
  mso-style-name: Ydefsubsubclause-f;
}
p.Ydefparagraph-f,
li.Ydefparagraph-f,
div.Ydefparagraph-f {
  mso-style-name: Ydefparagraph-f;
}
p.Ydefparagraph-f,
li.Ydefparagraph-f,
div.Ydefparagraph-f,
p.YSdefpara-f,
li.YSdefpara-f,
div.YSdefpara-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 26.9pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YSdefpara-f,
li.YSdefpara-f,
div.YSdefpara-f {
  mso-style-name: YSdefpara-f;
}
p.Ydefsubpara-f,
li.Ydefsubpara-f,
div.Ydefsubpara-f {
  margin-left: 47.75pt;
  mso-style-name: Ydefsubpara-f;
}
p.Ydefsubpara-f,
li.Ydefsubpara-f,
div.Ydefsubpara-f,
p.Ydefsubsubpara-f,
li.Ydefsubsubpara-f,
div.Ydefsubsubpara-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Ydefsubsubpara-f,
li.Ydefsubsubpara-f,
div.Ydefsubsubpara-f {
  margin-left: 71.75pt;
  mso-style-name: Ydefsubsubpara-f;
}
p.Ysection-f,
li.Ysection-f,
div.Ysection-f {
  mso-style-name: Ysection-f;
}
p.Ysection-f,
li.Ysection-f,
div.Ysection-f,
p.YSsection-f,
li.YSsection-f,
div.YSsection-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5pt;
  text-align: justify;
}
p.YSsection-f,
li.YSsection-f,
div.YSsection-f {
  mso-style-name: YSsection-f;
}
p.Ysubsection-f,
li.Ysubsection-f,
div.Ysubsection-f {
  mso-style-name: Ysubsection-f;
}
p.Ysubsection-f,
li.Ysubsection-f,
div.Ysubsection-f,
p.YSsubsection-f,
li.YSsubsection-f,
div.YSsubsection-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5pt;
  text-align: justify;
}
p.YSsubsection-f,
li.YSsubsection-f,
div.YSsubsection-f {
  mso-style-name: YSsubsection-f;
}
p.Yclause-f,
li.Yclause-f,
div.Yclause-f {
  mso-style-name: Yclause-f;
}
p.Yclause-f,
li.Yclause-f,
div.Yclause-f,
p.YSclause-f,
li.YSclause-f,
div.YSclause-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 26.9pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YSclause-f,
li.YSclause-f,
div.YSclause-f {
  mso-style-name: YSclause-f;
}
p.Ysubclause-f,
li.Ysubclause-f,
div.Ysubclause-f {
  mso-style-name: Ysubclause-f;
}
p.Ysubclause-f,
li.Ysubclause-f,
div.Ysubclause-f,
p.YSsubclause-f,
li.YSsubclause-f,
div.YSsubclause-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 47.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YSsubclause-f,
li.YSsubclause-f,
div.YSsubclause-f {
  mso-style-name: YSsubclause-f;
}
p.Ysubsubclause-f,
li.Ysubsubclause-f,
div.Ysubsubclause-f {
  mso-style-name: Ysubsubclause-f;
}
p.Ysubsubclause-f,
li.Ysubsubclause-f,
div.Ysubsubclause-f,
p.YSsubsubclause-f,
li.YSsubsubclause-f,
div.YSsubsubclause-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 71.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YSsubsubclause-f,
li.YSsubsubclause-f,
div.YSsubsubclause-f {
  mso-style-name: YSsubsubclause-f;
}
p.Ysubsubsubclause-f,
li.Ysubsubsubclause-f,
div.Ysubsubsubclause-f {
  margin-left: 89.65pt;
  mso-style-name: Ysubsubsubclause-f;
}
p.Ysubsubsubclause-f,
li.Ysubsubsubclause-f,
div.Ysubsubsubclause-f,
p.Yparagraph-f,
li.Yparagraph-f,
div.Yparagraph-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yparagraph-f,
li.Yparagraph-f,
div.Yparagraph-f {
  margin-left: 26.9pt;
  mso-style-name: Yparagraph-f;
}
p.Yparanoindt-f,
li.Yparanoindt-f,
div.Yparanoindt-f {
  margin-left: 0cm;
  mso-style-name: Yparanoindt-f;
}
p.Yparanoindt-f,
li.Yparanoindt-f,
div.Yparanoindt-f,
p.Yparawindt-f,
li.Yparawindt-f,
div.Yparawindt-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 4.8pt;
  text-align: justify;
}
p.Yparawindt-f,
li.Yparawindt-f,
div.Yparawindt-f {
  margin-left: 13.9pt;
  mso-style-name: Yparawindt-f;
}
p.Yparawtab-f,
li.Yparawtab-f,
div.Yparawtab-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 4.8pt;
  mso-style-name: Yparawtab-f;
  text-align: justify;
}
p.YSparagraph-f,
li.YSparagraph-f,
div.YSparagraph-f {
  margin-left: 26.9pt;
  mso-style-name: YSparagraph-f;
}
p.YSparagraph-f,
li.YSparagraph-f,
div.YSparagraph-f,
p.Ysubpara-f,
li.Ysubpara-f,
div.Ysubpara-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Ysubpara-f,
li.Ysubpara-f,
div.Ysubpara-f {
  margin-left: 47.75pt;
  mso-style-name: Ysubpara-f;
}
p.YSsubpara-f,
li.YSsubpara-f,
div.YSsubpara-f {
  margin-left: 47.75pt;
  mso-style-name: YSsubpara-f;
}
p.YSsubpara-f,
li.YSsubpara-f,
div.YSsubpara-f,
p.Ysubsubpara-f,
li.Ysubsubpara-f,
div.Ysubsubpara-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Ysubsubpara-f,
li.Ysubsubpara-f,
div.Ysubsubpara-f {
  margin-left: 71.75pt;
  mso-style-name: Ysubsubpara-f;
}
p.YSsubsubpara-f,
li.YSsubsubpara-f,
div.YSsubsubpara-f {
  margin-left: 71.75pt;
  mso-style-name: YSsubsubpara-f;
}
p.YSsubsubpara-f,
li.YSsubsubpara-f,
div.YSsubsubpara-f,
p.Ysubsubsubpara-f,
li.Ysubsubsubpara-f,
div.Ysubsubsubpara-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Ysubsubsubpara-f,
li.Ysubsubsubpara-f,
div.Ysubsubsubpara-f {
  margin-left: 89.65pt;
  mso-style-name: Ysubsubsubpara-f;
}
p.Yequation-f,
li.Yequation-f,
div.Yequation-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  mso-style-name: Yequation-f;
  text-align: center;
}
p.YPsection-f,
li.YPsection-f,
div.YPsection-f {
  mso-style-name: YPsection-f;
}
p.YPsection-f,
li.YPsection-f,
div.YPsection-f,
p.YSPsection-f,
li.YSPsection-f,
div.YSPsection-f {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5pt;
  text-align: justify;
}
p.YSPsection-f,
li.YSPsection-f,
div.YSPsection-f {
  mso-style-name: YSPsection-f;
}
p.YPsubsection-f,
li.YPsubsection-f,
div.YPsubsection-f {
  mso-style-name: YPsubsection-f;
}
p.YPsubsection-f,
li.YPsubsection-f,
div.YPsubsection-f,
p.YSPsubsection-f,
li.YSPsubsection-f,
div.YSPsubsection-f {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5pt;
  text-align: justify;
}
p.YSPsubsection-f,
li.YSPsubsection-f,
div.YSPsubsection-f {
  mso-style-name: YSPsubsection-f;
}
p.YPclause-f,
li.YPclause-f,
div.YPclause-f {
  margin-left: 26.9pt;
  mso-style-name: YPclause-f;
}
p.YPclause-f,
li.YPclause-f,
div.YPclause-f,
p.YPsubclause-f,
li.YPsubclause-f,
div.YPsubclause-f {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YPsubclause-f,
li.YPsubclause-f,
div.YPsubclause-f {
  margin-left: 47.75pt;
  mso-style-name: YPsubclause-f;
}
p.YPsubsubclause-f,
li.YPsubsubclause-f,
div.YPsubsubclause-f {
  margin-left: 71.75pt;
  mso-style-name: YPsubsubclause-f;
}
p.YPsubsubclause-f,
li.YPsubsubclause-f,
div.YPsubsubclause-f,
p.YPsubsubsubclause-f,
li.YPsubsubsubclause-f,
div.YPsubsubsubclause-f {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YPsubsubsubclause-f,
li.YPsubsubsubclause-f,
div.YPsubsubsubclause-f {
  margin-left: 89.65pt;
  mso-style-name: YPsubsubsubclause-f;
}
p.YPparagraph-f,
li.YPparagraph-f,
div.YPparagraph-f {
  margin-left: 26.9pt;
  mso-style-name: YPparagraph-f;
}
p.YPparagraph-f,
li.YPparagraph-f,
div.YPparagraph-f,
p.YPsubpara-f,
li.YPsubpara-f,
div.YPsubpara-f {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YPsubpara-f,
li.YPsubpara-f,
div.YPsubpara-f {
  margin-left: 47.75pt;
  mso-style-name: YPsubpara-f;
}
p.YPsubsubpara-f,
li.YPsubsubpara-f,
div.YPsubsubpara-f {
  margin-left: 71.75pt;
  mso-style-name: YPsubsubpara-f;
}
p.YPsubsubpara-f,
li.YPsubsubpara-f,
div.YPsubsubpara-f,
p.YPsubsubsubpara-f,
li.YPsubsubsubpara-f,
div.YPsubsubsubpara-f {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YPsubsubsubpara-f,
li.YPsubsubsubpara-f,
div.YPsubsubsubpara-f {
  margin-left: 89.65pt;
  mso-style-name: YPsubsubsubpara-f;
}
p.Pheading-f,
li.Pheading-f,
div.Pheading-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  mso-style-name: Pheading-f;
  page-break-after: avoid;
  text-align: center;
}
p.defPnote-e,
li.defPnote-e,
div.defPnote-e {
  background: #d9d9d9;
  margin-top: 5pt;
  mso-style-name: defPnote-e;
  text-align: justify;
}
p.defPnote-e,
li.defPnote-e,
div.defPnote-e,
p.headnote-e,
li.headnote-e,
div.headnote-e {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
}
p.headnote-e,
li.headnote-e,
div.headnote-e {
  margin-top: 6pt;
  mso-style-name: headnote-e;
}
p.headnote-f,
li.headnote-f,
div.headnote-f {
  margin-top: 6pt;
  mso-style-name: headnote-f;
}
p.headnote-f,
li.headnote-f,
div.headnote-f,
p.defPnote-f,
li.defPnote-f,
div.defPnote-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
}
p.defPnote-f,
li.defPnote-f,
div.defPnote-f {
  background: #d9d9d9;
  margin-top: 5pt;
  mso-style-name: defPnote-f;
  text-align: justify;
}
p.Yprocsection-e,
li.Yprocsection-e,
div.Yprocsection-e {
  mso-style-name: Yprocsection-e;
}
p.Yprocsection-e,
li.Yprocsection-e,
div.Yprocsection-e,
p.Yprocsection-f,
li.Yprocsection-f,
div.Yprocsection-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 12pt;
  margin-right: 0cm;
  margin-top: 5pt;
  text-align: justify;
}
p.Yprocsection-f,
li.Yprocsection-f,
div.Yprocsection-f {
  mso-style-name: Yprocsection-f;
}
p.Yprocsubsection-e,
li.Yprocsubsection-e,
div.Yprocsubsection-e {
  mso-style-name: Yprocsubsection-e;
}
p.Yprocsubsection-e,
li.Yprocsubsection-e,
div.Yprocsubsection-e,
p.Yprocsubsection-f,
li.Yprocsubsection-f,
div.Yprocsubsection-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 12pt;
  margin-right: 0cm;
  margin-top: 5pt;
  text-align: justify;
}
p.Yprocsubsection-f,
li.Yprocsubsection-f,
div.Yprocsubsection-f {
  mso-style-name: Yprocsubsection-f;
}
p.YprocSsection-e,
li.YprocSsection-e,
div.YprocSsection-e {
  mso-style-name: YprocSsection-e;
}
p.YprocSsection-e,
li.YprocSsection-e,
div.YprocSsection-e,
p.YprocSsection-f,
li.YprocSsection-f,
div.YprocSsection-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 12pt;
  margin-right: 0cm;
  margin-top: 5pt;
  text-align: justify;
}
p.YprocSsection-f,
li.YprocSsection-f,
div.YprocSsection-f {
  mso-style-name: YprocSsection-f;
}
p.YprocSsubsection-e,
li.YprocSsubsection-e,
div.YprocSsubsection-e {
  mso-style-name: YprocSsubsection-e;
}
p.YprocSsubsection-e,
li.YprocSsubsection-e,
div.YprocSsubsection-e,
p.YprocSsubsection-f,
li.YprocSsubsection-f,
div.YprocSsubsection-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 12pt;
  margin-right: 0cm;
  margin-top: 5pt;
  text-align: justify;
}
p.YprocSsubsection-f,
li.YprocSsubsection-f,
div.YprocSsubsection-f {
  mso-style-name: YprocSsubsection-f;
}
p.Yprocclause-e,
li.Yprocclause-e,
div.Yprocclause-e {
  mso-style-name: Yprocclause-e;
}
p.Yprocclause-e,
li.Yprocclause-e,
div.Yprocclause-e,
p.Yprocclause-f,
li.Yprocclause-f,
div.Yprocclause-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 38.9pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yprocclause-f,
li.Yprocclause-f,
div.Yprocclause-f {
  mso-style-name: Yprocclause-f;
}
p.Yprocparagraph-e,
li.Yprocparagraph-e,
div.Yprocparagraph-e {
  mso-style-name: Yprocparagraph-e;
}
p.Yprocparagraph-e,
li.Yprocparagraph-e,
div.Yprocparagraph-e,
p.Yprocparagraph-f,
li.Yprocparagraph-f,
div.Yprocparagraph-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 38.9pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yprocparagraph-f,
li.Yprocparagraph-f,
div.Yprocparagraph-f {
  mso-style-name: Yprocparagraph-f;
}
p.Yprocdefclause-e,
li.Yprocdefclause-e,
div.Yprocdefclause-e {
  mso-style-name: Yprocdefclause-e;
}
p.Yprocdefclause-e,
li.Yprocdefclause-e,
div.Yprocdefclause-e,
p.Yprocdefclause-f,
li.Yprocdefclause-f,
div.Yprocdefclause-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 38.9pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yprocdefclause-f,
li.Yprocdefclause-f,
div.Yprocdefclause-f {
  mso-style-name: Yprocdefclause-f;
}
p.Yprocdefinition-e,
li.Yprocdefinition-e,
div.Yprocdefinition-e {
  mso-style-name: Yprocdefinition-e;
}
p.Yprocdefinition-e,
li.Yprocdefinition-e,
div.Yprocdefinition-e,
p.Yprocdefinition-f,
li.Yprocdefinition-f,
div.Yprocdefinition-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 21.5pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yprocdefinition-f,
li.Yprocdefinition-f,
div.Yprocdefinition-f {
  mso-style-name: Yprocdefinition-f;
}
p.Yprocdefparagraph-e,
li.Yprocdefparagraph-e,
div.Yprocdefparagraph-e {
  mso-style-name: Yprocdefparagraph-e;
}
p.Yprocdefparagraph-e,
li.Yprocdefparagraph-e,
div.Yprocdefparagraph-e,
p.Yprocdefparagraph-f,
li.Yprocdefparagraph-f,
div.Yprocdefparagraph-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 38.9pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yprocdefparagraph-f,
li.Yprocdefparagraph-f,
div.Yprocdefparagraph-f {
  mso-style-name: Yprocdefparagraph-f;
}
p.Yprocfirstdef-e,
li.Yprocfirstdef-e,
div.Yprocfirstdef-e {
  mso-style-name: Yprocfirstdef-e;
}
p.Yprocfirstdef-e,
li.Yprocfirstdef-e,
div.Yprocfirstdef-e,
p.Yprocfirstdef-f,
li.Yprocfirstdef-f,
div.Yprocfirstdef-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 21.5pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yprocfirstdef-f,
li.Yprocfirstdef-f,
div.Yprocfirstdef-f {
  mso-style-name: Yprocfirstdef-f;
}
p.YprocSclause-e,
li.YprocSclause-e,
div.YprocSclause-e {
  mso-style-name: YprocSclause-e;
}
p.YprocSclause-e,
li.YprocSclause-e,
div.YprocSclause-e,
p.YprocSclause-f,
li.YprocSclause-f,
div.YprocSclause-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 39.6pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YprocSclause-f,
li.YprocSclause-f,
div.YprocSclause-f {
  mso-style-name: YprocSclause-f;
}
p.YprocSdefclause-e,
li.YprocSdefclause-e,
div.YprocSdefclause-e {
  mso-style-name: YprocSdefclause-e;
}
p.YprocSdefclause-e,
li.YprocSdefclause-e,
div.YprocSdefclause-e,
p.YprocSdefclause-f,
li.YprocSdefclause-f,
div.YprocSdefclause-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 39.6pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YprocSdefclause-f,
li.YprocSdefclause-f,
div.YprocSdefclause-f {
  mso-style-name: YprocSdefclause-f;
}
p.YprocSdefinition-e,
li.YprocSdefinition-e,
div.YprocSdefinition-e {
  mso-style-name: YprocSdefinition-e;
}
p.YprocSdefinition-e,
li.YprocSdefinition-e,
div.YprocSdefinition-e,
p.YprocSdefinition-f,
li.YprocSdefinition-f,
div.YprocSdefinition-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 21.5pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YprocSdefinition-f,
li.YprocSdefinition-f,
div.YprocSdefinition-f {
  mso-style-name: YprocSdefinition-f;
}
p.YprocSdefpara-e,
li.YprocSdefpara-e,
div.YprocSdefpara-e {
  mso-style-name: YprocSdefpara-e;
}
p.YprocSdefpara-e,
li.YprocSdefpara-e,
div.YprocSdefpara-e,
p.YprocSdefpara-f,
li.YprocSdefpara-f,
div.YprocSdefpara-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 39.6pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YprocSdefpara-f,
li.YprocSdefpara-f,
div.YprocSdefpara-f {
  mso-style-name: YprocSdefpara-f;
}
p.YprocSparagraph-e,
li.YprocSparagraph-e,
div.YprocSparagraph-e {
  mso-style-name: YprocSparagraph-e;
}
p.YprocSparagraph-e,
li.YprocSparagraph-e,
div.YprocSparagraph-e,
p.YprocSparagraph-f,
li.YprocSparagraph-f,
div.YprocSparagraph-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 39.6pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YprocSparagraph-f,
li.YprocSparagraph-f,
div.YprocSparagraph-f {
  mso-style-name: YprocSparagraph-f;
}
p.Yprocdefsubclause-e,
li.Yprocdefsubclause-e,
div.Yprocdefsubclause-e {
  mso-style-name: Yprocdefsubclause-e;
}
p.Yprocdefsubclause-e,
li.Yprocdefsubclause-e,
div.Yprocdefsubclause-e,
p.Yprocdefsubclause-f,
li.Yprocdefsubclause-f,
div.Yprocdefsubclause-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 63.6pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yprocdefsubclause-f,
li.Yprocdefsubclause-f,
div.Yprocdefsubclause-f {
  mso-style-name: Yprocdefsubclause-f;
}
p.Yprocdefsubpara-e,
li.Yprocdefsubpara-e,
div.Yprocdefsubpara-e {
  mso-style-name: Yprocdefsubpara-e;
}
p.Yprocdefsubpara-e,
li.Yprocdefsubpara-e,
div.Yprocdefsubpara-e,
p.Yprocdefsubpara-f,
li.Yprocdefsubpara-f,
div.Yprocdefsubpara-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 59.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yprocdefsubpara-f,
li.Yprocdefsubpara-f,
div.Yprocdefsubpara-f {
  mso-style-name: Yprocdefsubpara-f;
}
p.Yprocdefsubsubclause-e,
li.Yprocdefsubsubclause-e,
div.Yprocdefsubsubclause-e {
  mso-style-name: Yprocdefsubsubclause-e;
}
p.Yprocdefsubsubclause-e,
li.Yprocdefsubsubclause-e,
div.Yprocdefsubsubclause-e,
p.Yprocdefsubsubclause-f,
li.Yprocdefsubsubclause-f,
div.Yprocdefsubsubclause-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 84pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yprocdefsubsubclause-f,
li.Yprocdefsubsubclause-f,
div.Yprocdefsubsubclause-f {
  mso-style-name: Yprocdefsubsubclause-f;
}
p.Yprocdefsubsubpara-e,
li.Yprocdefsubsubpara-e,
div.Yprocdefsubsubpara-e {
  mso-style-name: Yprocdefsubsubpara-e;
}
p.Yprocdefsubsubpara-e,
li.Yprocdefsubsubpara-e,
div.Yprocdefsubsubpara-e,
p.Yprocdefsubsubpara-f,
li.Yprocdefsubsubpara-f,
div.Yprocdefsubsubpara-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 83.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yprocdefsubsubpara-f,
li.Yprocdefsubsubpara-f,
div.Yprocdefsubsubpara-f {
  mso-style-name: Yprocdefsubsubpara-f;
}
p.YprocSsubclause-e,
li.YprocSsubclause-e,
div.YprocSsubclause-e {
  mso-style-name: YprocSsubclause-e;
}
p.YprocSsubclause-e,
li.YprocSsubclause-e,
div.YprocSsubclause-e,
p.YprocSsubclause-f,
li.YprocSsubclause-f,
div.YprocSsubclause-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 59.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YprocSsubclause-f,
li.YprocSsubclause-f,
div.YprocSsubclause-f {
  mso-style-name: YprocSsubclause-f;
}
p.YprocSsubpara-e,
li.YprocSsubpara-e,
div.YprocSsubpara-e {
  mso-style-name: YprocSsubpara-e;
}
p.YprocSsubpara-e,
li.YprocSsubpara-e,
div.YprocSsubpara-e,
p.YprocSsubpara-f,
li.YprocSsubpara-f,
div.YprocSsubpara-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 59.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YprocSsubpara-f,
li.YprocSsubpara-f,
div.YprocSsubpara-f {
  mso-style-name: YprocSsubpara-f;
}
p.YprocSsubsubclause-e,
li.YprocSsubsubclause-e,
div.YprocSsubsubclause-e {
  mso-style-name: YprocSsubsubclause-e;
}
p.YprocSsubsubclause-e,
li.YprocSsubsubclause-e,
div.YprocSsubsubclause-e,
p.YprocSsubsubclause-f,
li.YprocSsubsubclause-f,
div.YprocSsubsubclause-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 83.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YprocSsubsubclause-f,
li.YprocSsubsubclause-f,
div.YprocSsubsubclause-f {
  mso-style-name: YprocSsubsubclause-f;
}
p.YprocSsubsubpara-e,
li.YprocSsubsubpara-e,
div.YprocSsubsubpara-e {
  mso-style-name: YprocSsubsubpara-e;
}
p.YprocSsubsubpara-e,
li.YprocSsubsubpara-e,
div.YprocSsubsubpara-e,
p.YprocSsubsubpara-f,
li.YprocSsubsubpara-f,
div.YprocSsubsubpara-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 83.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.YprocSsubsubpara-f,
li.YprocSsubsubpara-f,
div.YprocSsubsubpara-f {
  mso-style-name: YprocSsubsubpara-f;
}
p.Yprocsubclause-e,
li.Yprocsubclause-e,
div.Yprocsubclause-e {
  mso-style-name: Yprocsubclause-e;
}
p.Yprocsubclause-e,
li.Yprocsubclause-e,
div.Yprocsubclause-e,
p.Yprocsubclause-f,
li.Yprocsubclause-f,
div.Yprocsubclause-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 59.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yprocsubclause-f,
li.Yprocsubclause-f,
div.Yprocsubclause-f {
  mso-style-name: Yprocsubclause-f;
}
p.Yprocsubpara-e,
li.Yprocsubpara-e,
div.Yprocsubpara-e {
  mso-style-name: Yprocsubpara-e;
}
p.Yprocsubpara-e,
li.Yprocsubpara-e,
div.Yprocsubpara-e,
p.Yprocsubpara-f,
li.Yprocsubpara-f,
div.Yprocsubpara-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 59.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yprocsubpara-f,
li.Yprocsubpara-f,
div.Yprocsubpara-f {
  mso-style-name: Yprocsubpara-f;
}
p.Yprocsubsubclause-e,
li.Yprocsubsubclause-e,
div.Yprocsubsubclause-e {
  mso-style-name: Yprocsubsubclause-e;
}
p.Yprocsubsubclause-e,
li.Yprocsubsubclause-e,
div.Yprocsubsubclause-e,
p.Yprocsubsubclause-f,
li.Yprocsubsubclause-f,
div.Yprocsubsubclause-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 83.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yprocsubsubclause-f,
li.Yprocsubsubclause-f,
div.Yprocsubsubclause-f {
  mso-style-name: Yprocsubsubclause-f;
}
p.Yprocsubsubpara-e,
li.Yprocsubsubpara-e,
div.Yprocsubsubpara-e {
  mso-style-name: Yprocsubsubpara-e;
}
p.Yprocsubsubpara-e,
li.Yprocsubsubpara-e,
div.Yprocsubsubpara-e,
p.Yprocsubsubpara-f,
li.Yprocsubsubpara-f,
div.Yprocsubsubpara-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 83.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yprocsubsubpara-f,
li.Yprocsubsubpara-f,
div.Yprocsubsubpara-f {
  mso-style-name: Yprocsubsubpara-f;
}
p.Yprocsubsubsubclause-e,
li.Yprocsubsubsubclause-e,
div.Yprocsubsubsubclause-e {
  mso-style-name: Yprocsubsubsubclause-e;
}
p.Yprocsubsubsubclause-e,
li.Yprocsubsubsubclause-e,
div.Yprocsubsubsubclause-e,
p.Yprocsubsubsubclause-f,
li.Yprocsubsubsubclause-f,
div.Yprocsubsubsubclause-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 101.65pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yprocsubsubsubclause-f,
li.Yprocsubsubsubclause-f,
div.Yprocsubsubsubclause-f {
  mso-style-name: Yprocsubsubsubclause-f;
}
p.Yprocsubsubsubpara-e,
li.Yprocsubsubsubpara-e,
div.Yprocsubsubsubpara-e {
  mso-style-name: Yprocsubsubsubpara-e;
}
p.Yprocsubsubsubpara-e,
li.Yprocsubsubsubpara-e,
div.Yprocsubsubsubpara-e,
p.Yprocsubsubsubpara-f,
li.Yprocsubsubsubpara-f,
div.Yprocsubsubsubpara-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 101.65pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yprocsubsubsubpara-f,
li.Yprocsubsubsubpara-f,
div.Yprocsubsubsubpara-f {
  mso-style-name: Yprocsubsubsubpara-f;
}
p.YprocPnote-e,
li.YprocPnote-e,
div.YprocPnote-e {
  mso-style-name: YprocPnote-e;
}
p.YprocPnote-e,
li.YprocPnote-e,
div.YprocPnote-e,
p.YprocPnote-f,
li.YprocPnote-f,
div.YprocPnote-f {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 12pt;
  margin-right: 0cm;
  margin-top: 5pt;
  text-align: justify;
}
p.YprocPnote-f,
li.YprocPnote-f,
div.YprocPnote-f {
  mso-style-name: YprocPnote-f;
}
span.StatuteName {
  font-variant: small-caps;
  mso-style-name: StatuteName;
}
p.procparagraph-e,
li.procparagraph-e,
div.procparagraph-e {
  mso-style-name: procparagraph-e;
}
p.procparagraph-e,
li.procparagraph-e,
div.procparagraph-e,
p.procparagraph-f,
li.procparagraph-f,
div.procparagraph-f {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 26.9pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.procparagraph-f,
li.procparagraph-f,
div.procparagraph-f {
  mso-style-name: procparagraph-f;
}
p.procclause-e,
li.procclause-e,
div.procclause-e {
  mso-style-name: procclause-e;
}
p.procclause-e,
li.procclause-e,
div.procclause-e,
p.procclause-f,
li.procclause-f,
div.procclause-f {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 26.9pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.procclause-f,
li.procclause-f,
div.procclause-f {
  mso-style-name: procclause-f;
}
p.TOCid-e,
li.TOCid-e,
div.TOCid-e {
  mso-style-name: TOCid-e;
}
p.TOCid-e,
li.TOCid-e,
div.TOCid-e,
p.TOCid-f,
li.TOCid-f,
div.TOCid-f {
  color: blue;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
  text-decoration: underline;
}
p.TOCid-f,
li.TOCid-f,
div.TOCid-f {
  mso-style-name: TOCid-f;
}
p.TOCheadCenter-e,
li.TOCheadCenter-e,
div.TOCheadCenter-e {
  mso-style-name: TOCheadCenter-e;
}
p.TOCheadCenter-e,
li.TOCheadCenter-e,
div.TOCheadCenter-e,
p.TOCheadCenter-f,
li.TOCheadCenter-f,
div.TOCheadCenter-f {
  color: blue;
  font-variant: small-caps;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
  text-align: center;
  text-decoration: underline;
}
p.TOCheadCenter-f,
li.TOCheadCenter-f,
div.TOCheadCenter-f {
  mso-style-name: TOCheadCenter-f;
}
p.TOCtable-e,
li.TOCtable-e,
div.TOCtable-e {
  mso-style-name: TOCtable-e;
}
p.TOCtable-e,
li.TOCtable-e,
div.TOCtable-e,
p.TOCtable-f,
li.TOCtable-f,
div.TOCtable-f {
  color: blue;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
  text-decoration: underline;
}
p.TOCtable-f,
li.TOCtable-f,
div.TOCtable-f {
  mso-style-name: TOCtable-f;
}
p.TOCschedCenter-e,
li.TOCschedCenter-e,
div.TOCschedCenter-e {
  mso-style-name: TOCschedCenter-e;
}
p.TOCschedCenter-e,
li.TOCschedCenter-e,
div.TOCschedCenter-e,
p.TOCpartCenter-e,
li.TOCpartCenter-e,
div.TOCpartCenter-e {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
  text-align: center;
}
p.TOCpartCenter-e,
li.TOCpartCenter-e,
div.TOCpartCenter-e {
  font-weight: bold;
  mso-style-name: TOCpartCenter-e;
}
p.TOCschedCenter-f,
li.TOCschedCenter-f,
div.TOCschedCenter-f {
  mso-style-name: TOCschedCenter-f;
}
p.TOCschedCenter-f,
li.TOCschedCenter-f,
div.TOCschedCenter-f,
p.TOCpartCenter-f,
li.TOCpartCenter-f,
div.TOCpartCenter-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
  text-align: center;
}
p.TOCpartCenter-f,
li.TOCpartCenter-f,
div.TOCpartCenter-f {
  font-weight: bold;
  mso-style-name: TOCpartCenter-f;
}
p.issue-f,
li.issue-f,
div.issue-f {
  mso-style-name: issue-f;
}
p.issue-f,
li.issue-f,
div.issue-f,
p.issue-e,
li.issue-e,
div.issue-e {
  layout-grid-mode: line;
  margin-bottom: 35.85pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 3.55pt;
}
p.issue-e,
li.issue-e,
div.issue-e {
  mso-style-name: issue-e;
}
p.transsection-e,
li.transsection-e,
div.transsection-e {
  mso-style-name: transsection-e;
}
p.transsection-e,
li.transsection-e,
div.transsection-e,
p.transsection-f,
li.transsection-f,
div.transsection-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5pt;
  text-align: justify;
}
p.transsection-f,
li.transsection-f,
div.transsection-f {
  mso-style-name: transsection-f;
}
p.transsubsection-e,
li.transsubsection-e,
div.transsubsection-e {
  mso-style-name: transsubsection-e;
}
p.transsubsection-e,
li.transsubsection-e,
div.transsubsection-e,
p.transsubsection-f,
li.transsubsection-f,
div.transsubsection-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5pt;
  text-align: justify;
}
p.transsubsection-f,
li.transsubsection-f,
div.transsubsection-f {
  mso-style-name: transsubsection-f;
}
p.Yprocpartnum-e,
li.Yprocpartnum-e,
div.Yprocpartnum-e {
  mso-style-name: Yprocpartnum-e;
}
p.Yprocpartnum-e,
li.Yprocpartnum-e,
div.Yprocpartnum-e,
p.Yprocpartnum-f,
li.Yprocpartnum-f,
div.Yprocpartnum-f {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.Yprocpartnum-f,
li.Yprocpartnum-f,
div.Yprocpartnum-f {
  mso-style-name: Yprocpartnum-f;
}
p.SeeSource,
li.SeeSource,
div.SeeSource {
  margin-bottom: 0cm;
  mso-style-name: SeeSource;
}
p.SeeSource,
li.SeeSource,
div.SeeSource,
p.NoticeDisclaimer,
li.NoticeDisclaimer,
div.NoticeDisclaimer {
  color: red;
  layout-grid-mode: line;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 4pt;
  text-align: justify;
}
p.NoticeDisclaimer,
li.NoticeDisclaimer,
div.NoticeDisclaimer {
  margin-bottom: 4.55pt;
  mso-style-name: NoticeDisclaimer;
}
p.Standard-e,
li.Standard-e,
div.Standard-e {
  mso-style-name: Standard-e;
}
p.Standard-e,
li.Standard-e,
div.Standard-e,
p.Standard-f,
li.Standard-f,
div.Standard-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5pt;
  text-align: justify;
}
p.Standard-f,
li.Standard-f,
div.Standard-f {
  mso-style-name: Standard-f;
}
p.Ppartnum-e,
li.Ppartnum-e,
div.Ppartnum-e {
  mso-style-name: Ppartnum-e;
}
p.Ppartnum-e,
li.Ppartnum-e,
div.Ppartnum-e,
p.Ppartnum-f,
li.Ppartnum-f,
div.Ppartnum-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.Ppartnum-f,
li.Ppartnum-f,
div.Ppartnum-f {
  mso-style-name: Ppartnum-f;
}
p.act-e,
li.act-e,
div.act-e {
  font-style: italic;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7pt;
  mso-style-name: act-e;
  page-break-after: avoid;
  text-align: center;
}
p.Yheadingx-f,
li.Yheadingx-f,
div.Yheadingx-f {
  mso-style-name: Yheadingx-f;
}
p.Yheadingx-f,
li.Yheadingx-f,
div.Yheadingx-f,
p.Yheadingx-e,
li.Yheadingx-e,
div.Yheadingx-e {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.Yheadingx-e,
li.Yheadingx-e,
div.Yheadingx-e {
  mso-style-name: Yheadingx-e;
}
p.Yschedule-e,
li.Yschedule-e,
div.Yschedule-e {
  mso-style-name: Yschedule-e;
}
p.Yschedule-e,
li.Yschedule-e,
div.Yschedule-e,
p.Yschedule-f,
li.Yschedule-f,
div.Yschedule-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 3pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.Yschedule-f,
li.Yschedule-f,
div.Yschedule-f {
  mso-style-name: Yschedule-f;
}
p.act-f,
li.act-f,
div.act-f {
  font-style: italic;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7pt;
  mso-style-name: act-f;
  page-break-after: avoid;
  text-align: center;
}
p.amendednote-e,
li.amendednote-e,
div.amendednote-e {
  mso-style-name: amendednote-e;
}
p.amendednote-e,
li.amendednote-e,
div.amendednote-e,
p.amendednote-f,
li.amendednote-f,
div.amendednote-f {
  font-style: italic;
  layout-grid-mode: line;
  margin-bottom: 6.95pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  page-break-after: avoid;
  text-align: center;
}
p.amendednote-f,
li.amendednote-f,
div.amendednote-f {
  mso-style-name: amendednote-f;
}
p.commiss-e,
li.commiss-e,
div.commiss-e {
  font-variant: small-caps;
  layout-grid-mode: line;
  margin-bottom: 23.9pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  mso-style-name: commiss-e;
  page-break-after: avoid;
  text-align: right;
}
p.form-e,
li.form-e,
div.form-e {
  mso-style-name: form-e;
}
p.form-e,
li.form-e,
div.form-e,
p.form-f,
li.form-f,
div.form-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7pt;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.form-f,
li.form-f,
div.form-f {
  mso-style-name: form-f;
}
p.regnumber-e,
li.regnumber-e,
div.regnumber-e {
  mso-style-name: regnumber-e;
}
p.regnumber-e,
li.regnumber-e,
div.regnumber-e,
p.regnumber-f,
li.regnumber-f,
div.regnumber-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 7pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.regnumber-f,
li.regnumber-f,
div.regnumber-f {
  mso-style-name: regnumber-f;
}
p.regtitle-e,
li.regtitle-e,
div.regtitle-e {
  mso-style-name: regtitle-e;
}
p.regtitle-e,
li.regtitle-e,
div.regtitle-e,
p.regtitle-f,
li.regtitle-f,
div.regtitle-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 6.95pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.regtitle-f,
li.regtitle-f,
div.regtitle-f {
  mso-style-name: regtitle-f;
}
p.ruleb-e,
li.ruleb-e,
div.ruleb-e {
  mso-style-name: ruleb-e;
}
p.ruleb-e,
li.ruleb-e,
div.ruleb-e,
p.ruleb-f,
li.ruleb-f,
div.ruleb-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 6.95pt;
}
p.ruleb-f,
li.ruleb-f,
div.ruleb-f {
  mso-style-name: ruleb-f;
}
p.rulec-e,
li.rulec-e,
div.rulec-e {
  mso-style-name: rulec-e;
}
p.rulec-e,
li.rulec-e,
div.rulec-e,
p.rulec-f,
li.rulec-f,
div.rulec-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 6.95pt;
  text-align: center;
  text-transform: uppercase;
}
p.rulec-f,
li.rulec-f,
div.rulec-f {
  mso-style-name: rulec-f;
}
p.rulei-e,
li.rulei-e,
div.rulei-e {
  mso-style-name: rulei-e;
}
p.rulei-e,
li.rulei-e,
div.rulei-e,
p.rulei-f,
li.rulei-f,
div.rulei-f {
  font-style: italic;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 6.95pt;
}
p.rulei-f,
li.rulei-f,
div.rulei-f {
  mso-style-name: rulei-f;
}
p.rulel-e,
li.rulel-e,
div.rulel-e {
  mso-style-name: rulel-e;
}
p.rulel-e,
li.rulel-e,
div.rulel-e,
p.rulel-f,
li.rulel-f,
div.rulel-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 6.95pt;
  text-transform: uppercase;
}
p.rulel-f,
li.rulel-f,
div.rulel-f {
  mso-style-name: rulel-f;
}
p.subject-e,
li.subject-e,
div.subject-e {
  mso-style-name: subject-e;
}
p.subject-e,
li.subject-e,
div.subject-e,
p.subject-f,
li.subject-f,
div.subject-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7pt;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.subject-f,
li.subject-f,
div.subject-f {
  mso-style-name: subject-f;
}
p.tocpartnum-e,
li.tocpartnum-e,
div.tocpartnum-e {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 2pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 6pt;
  mso-style-name: tocpartnum-e;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.Yminnote-e,
li.Yminnote-e,
div.Yminnote-e {
  background: #d9d9d9;
  margin-bottom: 10.45pt;
  margin-top: 4.55pt;
  mso-style-name: Yminnote-e;
  text-align: justify;
}
p.Yminnote-e,
li.Yminnote-e,
div.Yminnote-e,
p.version-e,
li.version-e,
div.version-e {
  font-style: italic;
  layout-grid-mode: line;
  margin-left: 0cm;
  margin-right: 0cm;
}
p.version-e,
li.version-e,
div.version-e {
  mso-style-name: version-e;
}
p.version-e,
li.version-e,
div.version-e,
p.version-f,
li.version-f,
div.version-f {
  font-weight: bold;
  margin-bottom: 0cm;
  margin-top: 6.95pt;
}
p.version-f,
li.version-f,
div.version-f {
  font-style: italic;
  mso-style-name: version-f;
}
p.version-f,
li.version-f,
div.version-f,
p.ActTitle-e,
li.ActTitle-e,
div.ActTitle-e {
  layout-grid-mode: line;
  margin-left: 0cm;
  margin-right: 0cm;
}
p.ActTitle-e,
li.ActTitle-e,
div.ActTitle-e {
  font-weight: bold;
  margin-bottom: 10pt;
  margin-top: 0cm;
  mso-style-name: ActTitle-e;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.regaction-e,
li.regaction-e,
div.regaction-e {
  margin: 0cm;
  mso-style-name: regaction-e;
  page-break-after: avoid;
  text-align: center;
}
p.ActTitle-f,
li.ActTitle-f,
div.ActTitle-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 10pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  mso-style-name: ActTitle-f;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.regaction-f,
li.regaction-f,
div.regaction-f {
  margin: 0cm;
  mso-style-name: regaction-f;
  page-break-after: avoid;
  text-align: center;
}
p.dated-e,
li.dated-e,
div.dated-e {
  mso-style-name: dated-e;
}
p.dated-e,
li.dated-e,
div.dated-e,
p.dated-f,
li.dated-f,
div.dated-f {
  layout-grid-mode: line;
  margin-bottom: 11.95pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 14.45pt;
  page-break-after: avoid;
}
p.dated-f,
li.dated-f,
div.dated-f {
  mso-style-name: dated-f;
}
p.madeappfiled-f,
li.madeappfiled-f,
div.madeappfiled-f {
  mso-style-name: "made\/app\/filed-f";
}
p.madeappfiled-f,
li.madeappfiled-f,
div.madeappfiled-f,
p.madeappfiled-e,
li.madeappfiled-e,
div.madeappfiled-e {
  layout-grid-mode: line;
  margin-bottom: 6pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  page-break-after: avoid;
  text-align: center;
}
p.madeappfiled-e,
li.madeappfiled-e,
div.madeappfiled-e {
  mso-style-name: "made\/app\/filed-e";
}
p.regtitleold-e,
li.regtitleold-e,
div.regtitleold-e {
  mso-style-name: regtitleold-e;
}
p.regtitleold-e,
li.regtitleold-e,
div.regtitleold-e,
p.regtitleold-f,
li.regtitleold-f,
div.regtitleold-f {
  layout-grid-mode: line;
  margin-bottom: 6.95pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.regtitleold-f,
li.regtitleold-f,
div.regtitleold-f {
  mso-style-name: regtitleold-f;
}
p.commiss-f,
li.commiss-f,
div.commiss-f {
  font-variant: small-caps;
  layout-grid-mode: line;
  margin-bottom: 23.9pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  mso-style-name: commiss-f;
  page-break-after: avoid;
  text-align: right;
}
p.Yact-e,
li.Yact-e,
div.Yact-e {
  mso-style-name: Yact-e;
}
p.Yact-e,
li.Yact-e,
div.Yact-e,
p.Yact-f,
li.Yact-f,
div.Yact-f {
  background: #d9d9d9;
  font-style: italic;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7pt;
  page-break-after: avoid;
  text-align: center;
}
p.Yact-f,
li.Yact-f,
div.Yact-f {
  mso-style-name: Yact-f;
}
p.Yform-e,
li.Yform-e,
div.Yform-e {
  mso-style-name: Yform-e;
}
p.Yform-e,
li.Yform-e,
div.Yform-e,
p.Yform-f,
li.Yform-f,
div.Yform-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7pt;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.Yform-f,
li.Yform-f,
div.Yform-f {
  mso-style-name: Yform-f;
}
p.note-f,
li.note-f,
div.note-f {
  margin-bottom: 7pt;
  margin-top: 0cm;
  mso-style-name: note-f;
}
p.note-f,
li.note-f,
div.note-f,
p.Yminnote-f,
li.Yminnote-f,
div.Yminnote-f {
  layout-grid-mode: line;
  margin-left: 0cm;
  margin-right: 0cm;
  text-align: justify;
}
p.Yminnote-f,
li.Yminnote-f,
div.Yminnote-f {
  background: #d9d9d9;
  font-style: italic;
  margin-bottom: 10.45pt;
  margin-top: 4.55pt;
  mso-style-name: Yminnote-f;
}
p.Yruleb-e,
li.Yruleb-e,
div.Yruleb-e {
  mso-style-name: Yruleb-e;
}
p.Yruleb-e,
li.Yruleb-e,
div.Yruleb-e,
p.Yruleb-f,
li.Yruleb-f,
div.Yruleb-f {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 6.95pt;
}
p.Yruleb-f,
li.Yruleb-f,
div.Yruleb-f {
  mso-style-name: Yruleb-f;
}
p.Yrulel-e,
li.Yrulel-e,
div.Yrulel-e {
  mso-style-name: Yrulel-e;
}
p.Yrulel-e,
li.Yrulel-e,
div.Yrulel-e,
p.Yrulel-f,
li.Yrulel-f,
div.Yrulel-f {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 6.95pt;
  text-transform: uppercase;
}
p.Yrulel-f,
li.Yrulel-f,
div.Yrulel-f {
  mso-style-name: Yrulel-f;
}
p.Yrulec-e,
li.Yrulec-e,
div.Yrulec-e {
  mso-style-name: Yrulec-e;
}
p.Yrulec-e,
li.Yrulec-e,
div.Yrulec-e,
p.Yrulec-f,
li.Yrulec-f,
div.Yrulec-f {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 6.95pt;
  text-align: center;
  text-transform: uppercase;
}
p.Yrulec-f,
li.Yrulec-f,
div.Yrulec-f {
  mso-style-name: Yrulec-f;
}
p.Yrulei-e,
li.Yrulei-e,
div.Yrulei-e {
  mso-style-name: Yrulei-e;
}
p.Yrulei-e,
li.Yrulei-e,
div.Yrulei-e,
p.Yrulei-f,
li.Yrulei-f,
div.Yrulei-f {
  background: #d9d9d9;
  font-style: italic;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 6.95pt;
}
p.Yrulei-f,
li.Yrulei-f,
div.Yrulei-f {
  mso-style-name: Yrulei-f;
}
p.Ysubject-e,
li.Ysubject-e,
div.Ysubject-e {
  mso-style-name: Ysubject-e;
}
p.Ysubject-e,
li.Ysubject-e,
div.Ysubject-e,
p.Ysubject-f,
li.Ysubject-f,
div.Ysubject-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7pt;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.Ysubject-f,
li.Ysubject-f,
div.Ysubject-f {
  mso-style-name: Ysubject-f;
}
p.Yheadnote-e,
li.Yheadnote-e,
div.Yheadnote-e {
  mso-style-name: Yheadnote-e;
}
p.Yheadnote-e,
li.Yheadnote-e,
div.Yheadnote-e,
p.Yheadnote-f,
li.Yheadnote-f,
div.Yheadnote-f {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 6pt;
}
p.Yheadnote-f,
li.Yheadnote-f,
div.Yheadnote-f {
  mso-style-name: Yheadnote-f;
}
p.TOChead-f,
li.TOChead-f,
div.TOChead-f {
  mso-style-name: TOChead-f;
}
p.TOChead-f,
li.TOChead-f,
div.TOChead-f,
p.TOChead-e,
li.TOChead-e,
div.TOChead-e {
  color: blue;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
  text-decoration: underline;
}
p.TOChead-e,
li.TOChead-e,
div.TOChead-e {
  mso-style-name: TOChead-e;
}
p.tablelevel1-e,
li.tablelevel1-e,
div.tablelevel1-e {
  mso-style-name: tablelevel1-e;
}
p.tablelevel1-e,
li.tablelevel1-e,
div.tablelevel1-e,
p.tablelevel1-f,
li.tablelevel1-f,
div.tablelevel1-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 18pt;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.tablelevel1-f,
li.tablelevel1-f,
div.tablelevel1-f {
  mso-style-name: tablelevel1-f;
}
p.tablelevel2-e,
li.tablelevel2-e,
div.tablelevel2-e {
  mso-style-name: tablelevel2-e;
}
p.tablelevel2-e,
li.tablelevel2-e,
div.tablelevel2-e,
p.tablelevel2-f,
li.tablelevel2-f,
div.tablelevel2-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 30pt;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.tablelevel2-f,
li.tablelevel2-f,
div.tablelevel2-f {
  mso-style-name: tablelevel2-f;
}
p.tablelevel3-e,
li.tablelevel3-e,
div.tablelevel3-e {
  mso-style-name: tablelevel3-e;
}
p.tablelevel3-e,
li.tablelevel3-e,
div.tablelevel3-e,
p.tablelevel3-f,
li.tablelevel3-f,
div.tablelevel3-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 42pt;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.tablelevel3-f,
li.tablelevel3-f,
div.tablelevel3-f {
  mso-style-name: tablelevel3-f;
}
p.tablelevel4-e,
li.tablelevel4-e,
div.tablelevel4-e {
  mso-style-name: tablelevel4-e;
}
p.tablelevel4-e,
li.tablelevel4-e,
div.tablelevel4-e,
p.tablelevel4-f,
li.tablelevel4-f,
div.tablelevel4-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 54pt;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.tablelevel4-f,
li.tablelevel4-f,
div.tablelevel4-f {
  mso-style-name: tablelevel4-f;
}
p.tablelevel1x-e,
li.tablelevel1x-e,
div.tablelevel1x-e {
  mso-style-name: tablelevel1x-e;
}
p.tablelevel1x-e,
li.tablelevel1x-e,
div.tablelevel1x-e,
p.tablelevel1x-f,
li.tablelevel1x-f,
div.tablelevel1x-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 18pt;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.tablelevel1x-f,
li.tablelevel1x-f,
div.tablelevel1x-f {
  mso-style-name: tablelevel1x-f;
}
p.tablelevel2x-e,
li.tablelevel2x-e,
div.tablelevel2x-e {
  mso-style-name: tablelevel2x-e;
}
p.tablelevel2x-e,
li.tablelevel2x-e,
div.tablelevel2x-e,
p.tablelevel2x-f,
li.tablelevel2x-f,
div.tablelevel2x-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 30pt;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.tablelevel2x-f,
li.tablelevel2x-f,
div.tablelevel2x-f {
  mso-style-name: tablelevel2x-f;
}
p.tablelevel3x-e,
li.tablelevel3x-e,
div.tablelevel3x-e {
  mso-style-name: tablelevel3x-e;
}
p.tablelevel3x-e,
li.tablelevel3x-e,
div.tablelevel3x-e,
p.tablelevel3x-f,
li.tablelevel3x-f,
div.tablelevel3x-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 42pt;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.tablelevel3x-f,
li.tablelevel3x-f,
div.tablelevel3x-f {
  mso-style-name: tablelevel3x-f;
}
p.parawindt3-e,
li.parawindt3-e,
div.parawindt3-e {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 41.75pt;
  margin-right: 0cm;
  margin-top: 4.8pt;
  mso-style-name: parawindt3-e;
  text-align: justify;
}
p.equationind1-f,
li.equationind1-f,
div.equationind1-f {
  mso-style-name: equationind1-f;
}
p.equationind1-f,
li.equationind1-f,
div.equationind1-f,
p.equationind1-e,
li.equationind1-e,
div.equationind1-e {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 26.9pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.equationind1-e,
li.equationind1-e,
div.equationind1-e {
  mso-style-name: equationind1-e;
}
p.equationind2-e,
li.equationind2-e,
div.equationind2-e {
  mso-style-name: equationind2-e;
}
p.equationind2-e,
li.equationind2-e,
div.equationind2-e,
p.equationind2-f,
li.equationind2-f,
div.equationind2-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 47.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.equationind2-f,
li.equationind2-f,
div.equationind2-f {
  mso-style-name: equationind2-f;
}
p.equationind3-e,
li.equationind3-e,
div.equationind3-e {
  mso-style-name: equationind3-e;
}
p.equationind3-e,
li.equationind3-e,
div.equationind3-e,
p.equationind3-f,
li.equationind3-f,
div.equationind3-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 71.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.equationind3-f,
li.equationind3-f,
div.equationind3-f {
  mso-style-name: equationind3-f;
}
p.equationind4-e,
li.equationind4-e,
div.equationind4-e {
  mso-style-name: equationind4-e;
}
p.equationind4-e,
li.equationind4-e,
div.equationind4-e,
p.equationind4-f,
li.equationind4-f,
div.equationind4-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 89.65pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.equationind4-f,
li.equationind4-f,
div.equationind4-f {
  mso-style-name: equationind4-f;
}
p.tablelevel4x-e,
li.tablelevel4x-e,
div.tablelevel4x-e {
  mso-style-name: tablelevel4x-e;
}
p.tablelevel4x-e,
li.tablelevel4x-e,
div.tablelevel4x-e,
p.tablelevel4x-f,
li.tablelevel4x-f,
div.tablelevel4x-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 54pt;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.tablelevel4x-f,
li.tablelevel4x-f,
div.tablelevel4x-f {
  mso-style-name: tablelevel4x-f;
}
p.headnoteind-e,
li.headnoteind-e,
div.headnoteind-e {
  mso-style-name: headnoteind-e;
}
p.headnoteind-e,
li.headnoteind-e,
div.headnoteind-e,
p.headnoteind-f,
li.headnoteind-f,
div.headnoteind-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 12.25pt;
  margin-right: 0cm;
  margin-top: 6pt;
}
p.headnoteind-f,
li.headnoteind-f,
div.headnoteind-f {
  mso-style-name: headnoteind-f;
}
p.footnoteLeft-e,
li.footnoteLeft-e,
div.footnoteLeft-e {
  mso-style-name: footnoteLeft-e;
}
p.footnoteLeft-e,
li.footnoteLeft-e,
div.footnoteLeft-e,
p.footnoteLeft-f,
li.footnoteLeft-f,
div.footnoteLeft-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.footnoteLeft-f,
li.footnoteLeft-f,
div.footnoteLeft-f {
  mso-style-name: footnoteLeft-f;
}
p.TOCpartLeft-e,
li.TOCpartLeft-e,
div.TOCpartLeft-e {
  mso-style-name: TOCpartLeft-e;
}
p.TOCpartLeft-e,
li.TOCpartLeft-e,
div.TOCpartLeft-e,
p.TOCpartLeft-f,
li.TOCpartLeft-f,
div.TOCpartLeft-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.TOCpartLeft-f,
li.TOCpartLeft-f,
div.TOCpartLeft-f {
  mso-style-name: TOCpartLeft-f;
}
span.UnderBlue {
  color: blue;
  mso-style-name: UnderBlue;
  text-decoration: underline;
}
p.TOCschedLeft-e,
li.TOCschedLeft-e,
div.TOCschedLeft-e {
  mso-style-name: TOCschedLeft-e;
}
p.TOCschedLeft-e,
li.TOCschedLeft-e,
div.TOCschedLeft-e,
p.TOCschedLeft-f,
li.TOCschedLeft-f,
div.TOCschedLeft-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.TOCschedLeft-f,
li.TOCschedLeft-f,
div.TOCschedLeft-f {
  mso-style-name: TOCschedLeft-f;
}
p.TOCheadLeft-e,
li.TOCheadLeft-e,
div.TOCheadLeft-e {
  mso-style-name: TOCheadLeft-e;
}
p.TOCheadLeft-e,
li.TOCheadLeft-e,
div.TOCheadLeft-e,
p.TOCheadLeft-f,
li.TOCheadLeft-f,
div.TOCheadLeft-f {
  color: blue;
  font-variant: small-caps;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
  text-decoration: underline;
}
p.TOCheadLeft-f,
li.TOCheadLeft-f,
div.TOCheadLeft-f {
  mso-style-name: TOCheadLeft-f;
}
p.Yfootnote-e,
li.Yfootnote-e,
div.Yfootnote-e {
  mso-style-name: Yfootnote-e;
}
p.Yfootnote-e,
li.Yfootnote-e,
div.Yfootnote-e,
p.Yfootnote-f,
li.Yfootnote-f,
div.Yfootnote-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: right;
}
p.Yfootnote-f,
li.Yfootnote-f,
div.Yfootnote-f {
  mso-style-name: Yfootnote-f;
}
p.Yfootnoteleft-e,
li.Yfootnoteleft-e,
div.Yfootnoteleft-e {
  mso-style-name: Yfootnoteleft-e;
}
p.Yfootnoteleft-e,
li.Yfootnoteleft-e,
div.Yfootnoteleft-e,
p.Yfootnoteleft-f,
li.Yfootnoteleft-f,
div.Yfootnoteleft-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yfootnoteleft-f,
li.Yfootnoteleft-f,
div.Yfootnoteleft-f {
  mso-style-name: Yfootnoteleft-f;
}
p.TOCpart-f,
li.TOCpart-f,
div.TOCpart-f {
  mso-style-name: TOCpart-f;
}
p.TOCpart-f,
li.TOCpart-f,
div.TOCpart-f,
p.TOCpart-e,
li.TOCpart-e,
div.TOCpart-e {
  color: blue;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
  text-decoration: underline;
}
p.TOCpart-e,
li.TOCpart-e,
div.TOCpart-e {
  mso-style-name: TOCpart-e;
}
p.TOCsched-f,
li.TOCsched-f,
div.TOCsched-f {
  mso-style-name: TOCsched-f;
}
p.TOCsched-f,
li.TOCsched-f,
div.TOCsched-f,
p.TOCsched-e,
li.TOCsched-e,
div.TOCsched-e {
  color: blue;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
  text-decoration: underline;
}
p.TOCsched-e,
li.TOCsched-e,
div.TOCsched-e {
  mso-style-name: TOCsched-e;
}
p.tocpartnum-f,
li.tocpartnum-f,
div.tocpartnum-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 2pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 6pt;
  mso-style-name: tocpartnum-f;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.partnumRevoked-e,
li.partnumRevoked-e,
div.partnumRevoked-e {
  mso-style-name: partnumRevoked-e;
}
p.partnumRevoked-e,
li.partnumRevoked-e,
div.partnumRevoked-e,
p.partnumRevoked-f,
li.partnumRevoked-f,
div.partnumRevoked-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
}
p.partnumRevoked-f,
li.partnumRevoked-f,
div.partnumRevoked-f {
  mso-style-name: partnumRevoked-f;
}
p.scheduleRevoked-e,
li.scheduleRevoked-e,
div.scheduleRevoked-e {
  mso-style-name: scheduleRevoked-e;
}
p.scheduleRevoked-e,
li.scheduleRevoked-e,
div.scheduleRevoked-e,
p.scheduleRevoked-f,
li.scheduleRevoked-f,
div.scheduleRevoked-f {
  layout-grid-mode: line;
  margin-bottom: 3pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
}
p.scheduleRevoked-f,
li.scheduleRevoked-f,
div.scheduleRevoked-f {
  mso-style-name: scheduleRevoked-f;
}
p.formRevoked-e,
li.formRevoked-e,
div.formRevoked-e {
  mso-style-name: formRevoked-e;
}
p.formRevoked-e,
li.formRevoked-e,
div.formRevoked-e,
p.formRevoked-f,
li.formRevoked-f,
div.formRevoked-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7pt;
  page-break-after: avoid;
  text-align: center;
}
p.formRevoked-f,
li.formRevoked-f,
div.formRevoked-f {
  mso-style-name: formRevoked-f;
}
p.Ytablelevel1-e,
li.Ytablelevel1-e,
div.Ytablelevel1-e {
  mso-style-name: Ytablelevel1-e;
}
p.Ytablelevel1-e,
li.Ytablelevel1-e,
div.Ytablelevel1-e,
p.Ytablelevel1-f,
li.Ytablelevel1-f,
div.Ytablelevel1-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 18pt;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.Ytablelevel1-f,
li.Ytablelevel1-f,
div.Ytablelevel1-f {
  mso-style-name: Ytablelevel1-f;
}
p.Ytablelevel1x-e,
li.Ytablelevel1x-e,
div.Ytablelevel1x-e {
  mso-style-name: Ytablelevel1x-e;
}
p.Ytablelevel1x-e,
li.Ytablelevel1x-e,
div.Ytablelevel1x-e,
p.Ytablelevel1x-f,
li.Ytablelevel1x-f,
div.Ytablelevel1x-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 18pt;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.Ytablelevel1x-f,
li.Ytablelevel1x-f,
div.Ytablelevel1x-f {
  mso-style-name: Ytablelevel1x-f;
}
p.Ytablelevel2-e,
li.Ytablelevel2-e,
div.Ytablelevel2-e {
  mso-style-name: Ytablelevel2-e;
}
p.Ytablelevel2-e,
li.Ytablelevel2-e,
div.Ytablelevel2-e,
p.Ytablelevel2-f,
li.Ytablelevel2-f,
div.Ytablelevel2-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 30pt;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.Ytablelevel2-f,
li.Ytablelevel2-f,
div.Ytablelevel2-f {
  mso-style-name: Ytablelevel2-f;
}
p.Ytablelevel2x-e,
li.Ytablelevel2x-e,
div.Ytablelevel2x-e {
  mso-style-name: Ytablelevel2x-e;
}
p.Ytablelevel2x-e,
li.Ytablelevel2x-e,
div.Ytablelevel2x-e,
p.Ytablelevel2x-f,
li.Ytablelevel2x-f,
div.Ytablelevel2x-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 30pt;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.Ytablelevel2x-f,
li.Ytablelevel2x-f,
div.Ytablelevel2x-f {
  mso-style-name: Ytablelevel2x-f;
}
p.Ytablelevel3-e,
li.Ytablelevel3-e,
div.Ytablelevel3-e {
  mso-style-name: Ytablelevel3-e;
}
p.Ytablelevel3-e,
li.Ytablelevel3-e,
div.Ytablelevel3-e,
p.Ytablelevel3-f,
li.Ytablelevel3-f,
div.Ytablelevel3-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 42pt;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.Ytablelevel3-f,
li.Ytablelevel3-f,
div.Ytablelevel3-f {
  mso-style-name: Ytablelevel3-f;
}
p.Ytablelevel3x-e,
li.Ytablelevel3x-e,
div.Ytablelevel3x-e {
  mso-style-name: Ytablelevel3x-e;
}
p.Ytablelevel3x-e,
li.Ytablelevel3x-e,
div.Ytablelevel3x-e,
p.Ytablelevel3x-f,
li.Ytablelevel3x-f,
div.Ytablelevel3x-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 42pt;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.Ytablelevel3x-f,
li.Ytablelevel3x-f,
div.Ytablelevel3x-f {
  mso-style-name: Ytablelevel3x-f;
}
p.Ytablelevel4-e,
li.Ytablelevel4-e,
div.Ytablelevel4-e {
  mso-style-name: Ytablelevel4-e;
}
p.Ytablelevel4-e,
li.Ytablelevel4-e,
div.Ytablelevel4-e,
p.Ytablelevel4-f,
li.Ytablelevel4-f,
div.Ytablelevel4-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 54pt;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.Ytablelevel4-f,
li.Ytablelevel4-f,
div.Ytablelevel4-f {
  mso-style-name: Ytablelevel4-f;
}
p.Ytablelevel4x-e,
li.Ytablelevel4x-e,
div.Ytablelevel4x-e {
  mso-style-name: Ytablelevel4x-e;
}
p.Ytablelevel4x-e,
li.Ytablelevel4x-e,
div.Ytablelevel4x-e,
p.Ytablelevel4x-f,
li.Ytablelevel4x-f,
div.Ytablelevel4x-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 54pt;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.Ytablelevel4x-f,
li.Ytablelevel4x-f,
div.Ytablelevel4x-f {
  mso-style-name: Ytablelevel4x-f;
}
p.sdefsubclause-e,
li.sdefsubclause-e,
div.sdefsubclause-e {
  mso-style-name: sdefsubclause-e;
}
p.sdefsubclause-e,
li.sdefsubclause-e,
div.sdefsubclause-e,
p.sdefsubclause-f,
li.sdefsubclause-f,
div.sdefsubclause-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 47.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.sdefsubclause-f,
li.sdefsubclause-f,
div.sdefsubclause-f {
  mso-style-name: sdefsubclause-f;
}
p.Ysdefsubclause-e,
li.Ysdefsubclause-e,
div.Ysdefsubclause-e {
  mso-style-name: Ysdefsubclause-e;
}
p.Ysdefsubclause-e,
li.Ysdefsubclause-e,
div.Ysdefsubclause-e,
p.Ysdefsubclause-f,
li.Ysdefsubclause-f,
div.Ysdefsubclause-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 47.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Ysdefsubclause-f,
li.Ysdefsubclause-f,
div.Ysdefsubclause-f {
  mso-style-name: Ysdefsubclause-f;
}
p.parawindt2-f,
li.parawindt2-f,
div.parawindt2-f {
  margin-left: 27.85pt;
  mso-style-name: parawindt2-f;
}
p.parawindt2-f,
li.parawindt2-f,
div.parawindt2-f,
p.parawindt3-f,
li.parawindt3-f,
div.parawindt3-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 4.8pt;
  text-align: justify;
}
p.parawindt3-f,
li.parawindt3-f,
div.parawindt3-f {
  margin-left: 41.75pt;
  mso-style-name: parawindt3-f;
}
p.heading1x-e,
li.heading1x-e,
div.heading1x-e {
  mso-style-name: heading1x-e;
}
p.heading1x-e,
li.heading1x-e,
div.heading1x-e,
p.heading1x-f,
li.heading1x-f,
div.heading1x-f {
  font-variant: small-caps;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
}
p.heading1x-f,
li.heading1x-f,
div.heading1x-f {
  mso-style-name: heading1x-f;
}
p.partnumRepeal-e,
li.partnumRepeal-e,
div.partnumRepeal-e {
  mso-style-name: partnumRepeal-e;
}
p.partnumRepeal-e,
li.partnumRepeal-e,
div.partnumRepeal-e,
p.partnumRepeal-f,
li.partnumRepeal-f,
div.partnumRepeal-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
}
p.partnumRepeal-f,
li.partnumRepeal-f,
div.partnumRepeal-f {
  mso-style-name: partnumRepeal-f;
}
p.scheduleRepeal-e,
li.scheduleRepeal-e,
div.scheduleRepeal-e {
  mso-style-name: scheduleRepeal-e;
}
p.scheduleRepeal-e,
li.scheduleRepeal-e,
div.scheduleRepeal-e,
p.scheduleRepeal-f,
li.scheduleRepeal-f,
div.scheduleRepeal-f {
  layout-grid-mode: line;
  margin-bottom: 3pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
}
p.scheduleRepeal-f,
li.scheduleRepeal-f,
div.scheduleRepeal-f {
  mso-style-name: scheduleRepeal-f;
}
p.formRepeal-e,
li.formRepeal-e,
div.formRepeal-e {
  mso-style-name: formRepeal-e;
}
p.formRepeal-e,
li.formRepeal-e,
div.formRepeal-e,
p.formRepeal-f,
li.formRepeal-f,
div.formRepeal-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7pt;
  page-break-after: avoid;
  text-align: center;
}
p.formRepeal-f,
li.formRepeal-f,
div.formRepeal-f {
  mso-style-name: formRepeal-f;
}
p.tableheadingRepeal-e,
li.tableheadingRepeal-e,
div.tableheadingRepeal-e {
  mso-style-name: tableheadingRepeal-e;
}
p.tableheadingRepeal-e,
li.tableheadingRepeal-e,
div.tableheadingRepeal-e,
p.tableheadingRepeal-f,
li.tableheadingRepeal-f,
div.tableheadingRepeal-f {
  layout-grid-mode: line;
  margin-bottom: 6.95pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  page-break-after: avoid;
  text-align: center;
}
p.tableheadingRepeal-f,
li.tableheadingRepeal-f,
div.tableheadingRepeal-f {
  mso-style-name: tableheadingRepeal-f;
}
p.tableheadingrev-f,
li.tableheadingrev-f,
div.tableheadingrev-f {
  layout-grid-mode: line;
  margin-bottom: 6.95pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  mso-style-name: tableheadingrev-f;
  page-break-after: avoid;
  text-align: center;
}
p.subsubsubsubclause-e,
li.subsubsubsubclause-e,
div.subsubsubsubclause-e {
  mso-style-name: subsubsubsubclause-e;
}
p.subsubsubsubclause-e,
li.subsubsubsubclause-e,
div.subsubsubsubclause-e,
p.subsubsubsubclause-f,
li.subsubsubsubclause-f,
div.subsubsubsubclause-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 107.65pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.subsubsubsubclause-f,
li.subsubsubsubclause-f,
div.subsubsubsubclause-f {
  mso-style-name: subsubsubsubclause-f;
}
p.xnumsub-e,
li.xnumsub-e,
div.xnumsub-e {
  margin-bottom: 0cm;
  margin-left: 48pt;
  margin-right: 42pt;
  margin-top: 5.55pt;
  mso-style-name: xnumsub-e;
}
p.xnumsub-e,
li.xnumsub-e,
div.xnumsub-e,
p.Caution,
li.Caution,
div.Caution {
  layout-grid-mode: line;
  text-align: justify;
}
p.Caution,
li.Caution,
div.Caution {
  color: red;
  margin-bottom: 4.55pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 4pt;
  mso-style-name: Caution;
}
p.Yequationind1-e,
li.Yequationind1-e,
div.Yequationind1-e {
  mso-style-name: Yequationind1-e;
}
p.Yequationind1-e,
li.Yequationind1-e,
div.Yequationind1-e,
p.Yequationind1-f,
li.Yequationind1-f,
div.Yequationind1-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 26.9pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yequationind1-f,
li.Yequationind1-f,
div.Yequationind1-f {
  mso-style-name: Yequationind1-f;
}
p.Yequationind2-e,
li.Yequationind2-e,
div.Yequationind2-e {
  mso-style-name: Yequationind2-e;
}
p.Yequationind2-e,
li.Yequationind2-e,
div.Yequationind2-e,
p.Yequationind2-f,
li.Yequationind2-f,
div.Yequationind2-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 47.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yequationind2-f,
li.Yequationind2-f,
div.Yequationind2-f {
  mso-style-name: Yequationind2-f;
}
p.Yequationind3-e,
li.Yequationind3-e,
div.Yequationind3-e {
  mso-style-name: Yequationind3-e;
}
p.Yequationind3-e,
li.Yequationind3-e,
div.Yequationind3-e,
p.Yequationind3-f,
li.Yequationind3-f,
div.Yequationind3-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 71.75pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yequationind3-f,
li.Yequationind3-f,
div.Yequationind3-f {
  mso-style-name: Yequationind3-f;
}
p.Yequationind4-e,
li.Yequationind4-e,
div.Yequationind4-e {
  mso-style-name: Yequationind4-e;
}
p.Yequationind4-e,
li.Yequationind4-e,
div.Yequationind4-e,
p.Yequationind4-f,
li.Yequationind4-f,
div.Yequationind4-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 89.65pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yequationind4-f,
li.Yequationind4-f,
div.Yequationind4-f {
  mso-style-name: Yequationind4-f;
}
p.xnumsub-f,
li.xnumsub-f,
div.xnumsub-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 48pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  mso-style-name: xnumsub-f;
  text-align: justify;
}
p.Yheading1x-e,
li.Yheading1x-e,
div.Yheading1x-e {
  mso-style-name: Yheading1x-e;
}
p.Yheading1x-e,
li.Yheading1x-e,
div.Yheading1x-e,
p.Yheading1x-f,
li.Yheading1x-f,
div.Yheading1x-f {
  background: #d9d9d9;
  font-variant: small-caps;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
}
p.Yheading1x-f,
li.Yheading1x-f,
div.Yheading1x-f {
  mso-style-name: Yheading1x-f;
}
p.Yprocheadnote-e,
li.Yprocheadnote-e,
div.Yprocheadnote-e {
  mso-style-name: Yprocheadnote-e;
}
p.Yprocheadnote-e,
li.Yprocheadnote-e,
div.Yprocheadnote-e,
p.Yprocheadnote-f,
li.Yprocheadnote-f,
div.Yprocheadnote-f {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 12pt;
  margin-right: 0cm;
  margin-top: 6pt;
}
p.Yprocheadnote-f,
li.Yprocheadnote-f,
div.Yprocheadnote-f {
  mso-style-name: Yprocheadnote-f;
}
p.tableitalic-e,
li.tableitalic-e,
div.tableitalic-e {
  mso-style-name: tableitalic-e;
}
p.tableitalic-e,
li.tableitalic-e,
div.tableitalic-e,
p.tableitalic-f,
li.tableitalic-f,
div.tableitalic-f {
  font-style: italic;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.tableitalic-f,
li.tableitalic-f,
div.tableitalic-f {
  mso-style-name: tableitalic-f;
}
p.Ytableitalic-e,
li.Ytableitalic-e,
div.Ytableitalic-e {
  mso-style-name: Ytableitalic-e;
}
p.Ytableitalic-e,
li.Ytableitalic-e,
div.Ytableitalic-e,
p.Ytableitalic-f,
li.Ytableitalic-f,
div.Ytableitalic-f {
  background: #d9d9d9;
  font-style: italic;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.Ytableitalic-f,
li.Ytableitalic-f,
div.Ytableitalic-f {
  mso-style-name: Ytableitalic-f;
}
p.tablebold-e,
li.tablebold-e,
div.tablebold-e {
  mso-style-name: tablebold-e;
}
p.tablebold-e,
li.tablebold-e,
div.tablebold-e,
p.tablebold-f,
li.tablebold-f,
div.tablebold-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.tablebold-f,
li.tablebold-f,
div.tablebold-f {
  mso-style-name: tablebold-f;
}
p.Ytablebold-e,
li.Ytablebold-e,
div.Ytablebold-e {
  mso-style-name: Ytablebold-e;
}
p.Ytablebold-e,
li.Ytablebold-e,
div.Ytablebold-e,
p.Ytablebold-f,
li.Ytablebold-f,
div.Ytablebold-f {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.Ytablebold-f,
li.Ytablebold-f,
div.Ytablebold-f {
  mso-style-name: Ytablebold-f;
}
p.bhnote-e,
li.bhnote-e,
div.bhnote-e {
  mso-style-name: bhnote-e;
}
p.bhnote-e,
li.bhnote-e,
div.bhnote-e,
p.bhnote-f,
li.bhnote-f,
div.bhnote-f {
  layout-grid-mode: line;
  margin-bottom: 7pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  text-align: justify;
}
p.bhnote-f,
li.bhnote-f,
div.bhnote-f {
  mso-style-name: bhnote-f;
}
p.defsubsubsubclause-e,
li.defsubsubsubclause-e,
div.defsubsubsubclause-e {
  mso-style-name: defsubsubsubclause-e;
}
p.defsubsubsubclause-e,
li.defsubsubsubclause-e,
div.defsubsubsubclause-e,
p.defsubsubsubclause-f,
li.defsubsubsubclause-f,
div.defsubsubsubclause-f {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 89.65pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.defsubsubsubclause-f,
li.defsubsubsubclause-f,
div.defsubsubsubclause-f {
  mso-style-name: defsubsubsubclause-f;
}
p.Ydefsubsubsubclause-e,
li.Ydefsubsubsubclause-e,
div.Ydefsubsubsubclause-e {
  mso-style-name: Ydefsubsubsubclause-e;
}
p.Ydefsubsubsubclause-e,
li.Ydefsubsubsubclause-e,
div.Ydefsubsubsubclause-e,
p.Ydefsubsubsubclause-f,
li.Ydefsubsubsubclause-f,
div.Ydefsubsubsubclause-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 89.65pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Ydefsubsubsubclause-f,
li.Ydefsubsubsubclause-f,
div.Ydefsubsubsubclause-f {
  mso-style-name: Ydefsubsubsubclause-f;
}
p.Yprocdefsubsubsubclause-e,
li.Yprocdefsubsubsubclause-e,
div.Yprocdefsubsubsubclause-e {
  mso-style-name: Yprocdefsubsubsubclause-e;
}
p.Yprocdefsubsubsubclause-e,
li.Yprocdefsubsubsubclause-e,
div.Yprocdefsubsubsubclause-e,
p.Yprocdefsubsubsubclause-f,
li.Yprocdefsubsubsubclause-f,
div.Yprocdefsubsubsubclause-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 101.65pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.Yprocdefsubsubsubclause-f,
li.Yprocdefsubsubsubclause-f,
div.Yprocdefsubsubsubclause-f {
  mso-style-name: Yprocdefsubsubsubclause-f;
}
p.Yprocheading1-e,
li.Yprocheading1-e,
div.Yprocheading1-e {
  mso-style-name: Yprocheading1-e;
}
p.Yprocheading1-e,
li.Yprocheading1-e,
div.Yprocheading1-e,
p.Yprocheading1-f,
li.Yprocheading1-f,
div.Yprocheading1-f {
  background: #d9d9d9;
  font-variant: small-caps;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 12pt;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
}
p.Yprocheading1-f,
li.Yprocheading1-f,
div.Yprocheading1-f {
  mso-style-name: Yprocheading1-f;
}
p.tableitaliclevel1x-e,
li.tableitaliclevel1x-e,
div.tableitaliclevel1x-e {
  mso-style-name: tableitaliclevel1x-e;
}
p.tableitaliclevel1x-e,
li.tableitaliclevel1x-e,
div.tableitaliclevel1x-e,
p.tableitaliclevel1x-f,
li.tableitaliclevel1x-f,
div.tableitaliclevel1x-f {
  font-style: italic;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 18pt;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.tableitaliclevel1x-f,
li.tableitaliclevel1x-f,
div.tableitaliclevel1x-f {
  mso-style-name: tableitaliclevel1x-f;
}
p.tablebolditalic-e,
li.tablebolditalic-e,
div.tablebolditalic-e {
  mso-style-name: tablebolditalic-e;
}
p.tablebolditalic-e,
li.tablebolditalic-e,
div.tablebolditalic-e,
p.tablebolditalic-f,
li.tablebolditalic-f,
div.tablebolditalic-f {
  font-style: italic;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.tablebolditalic-f,
li.tablebolditalic-f,
div.tablebolditalic-f {
  mso-style-name: tablebolditalic-f;
}
p.headnoteitalic-e,
li.headnoteitalic-e,
div.headnoteitalic-e {
  mso-style-name: headnoteitalic-e;
}
p.headnoteitalic-e,
li.headnoteitalic-e,
div.headnoteitalic-e,
p.headnoteitalic-f,
li.headnoteitalic-f,
div.headnoteitalic-f {
  font-style: italic;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 6pt;
}
p.headnoteitalic-f,
li.headnoteitalic-f,
div.headnoteitalic-f {
  mso-style-name: headnoteitalic-f;
}
p.xheadnote-e,
li.xheadnote-e,
div.xheadnote-e {
  mso-style-name: xheadnote-e;
}
p.xheadnote-e,
li.xheadnote-e,
div.xheadnote-e,
p.xheadnote-f,
li.xheadnote-f,
div.xheadnote-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.xheadnote-f,
li.xheadnote-f,
div.xheadnote-f {
  mso-style-name: xheadnote-f;
}
p.Pschedule-e,
li.Pschedule-e,
div.Pschedule-e {
  mso-style-name: Pschedule-e;
}
p.Pschedule-e,
li.Pschedule-e,
div.Pschedule-e,
p.Pschedule-f,
li.Pschedule-f,
div.Pschedule-f {
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 3pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.Pschedule-f,
li.Pschedule-f,
div.Pschedule-f {
  mso-style-name: Pschedule-f;
}
p.rsignature-e,
li.rsignature-e,
div.rsignature-e {
  mso-style-name: rsignature-e;
}
p.rsignature-e,
li.rsignature-e,
div.rsignature-e,
p.rsignature-f,
li.rsignature-f,
div.rsignature-f {
  font-variant: small-caps;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 2.45pt;
  page-break-after: avoid;
  text-align: right;
}
p.rsignature-f,
li.rsignature-f,
div.rsignature-f {
  mso-style-name: rsignature-f;
}
p.lsignature-e,
li.lsignature-e,
div.lsignature-e {
  mso-style-name: lsignature-e;
}
p.lsignature-e,
li.lsignature-e,
div.lsignature-e,
p.lsignature-f,
li.lsignature-f,
div.lsignature-f {
  font-variant: small-caps;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 2.45pt;
  page-break-after: avoid;
}
p.lsignature-f,
li.lsignature-f,
div.lsignature-f {
  mso-style-name: lsignature-f;
}
p.rsigntit-e,
li.rsigntit-e,
div.rsigntit-e {
  mso-style-name: rsigntit-e;
}
p.rsigntit-e,
li.rsigntit-e,
div.rsigntit-e,
p.rsigntit-f,
li.rsigntit-f,
div.rsigntit-f {
  font-style: italic;
  layout-grid-mode: line;
  margin-bottom: 11.95pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  page-break-after: avoid;
  text-align: right;
}
p.rsigntit-f,
li.rsigntit-f,
div.rsigntit-f {
  mso-style-name: rsigntit-f;
}
p.lsigntit-e,
li.lsigntit-e,
div.lsigntit-e {
  mso-style-name: lsigntit-e;
}
p.lsigntit-e,
li.lsigntit-e,
div.lsigntit-e,
p.lsigntit-f,
li.lsigntit-f,
div.lsigntit-f {
  font-style: italic;
  layout-grid-mode: line;
  margin-bottom: 11.95pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  page-break-after: avoid;
}
p.lsigntit-f,
li.lsigntit-f,
div.lsigntit-f {
  mso-style-name: lsigntit-f;
}
p.Notice-e,
li.Notice-e,
div.Notice-e {
  color: red;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 4pt;
  mso-style-name: Notice-e;
  text-align: justify;
}
p.signature-e,
li.signature-e,
div.signature-e {
  mso-style-name: signature-e;
}
p.signature-e,
li.signature-e,
div.signature-e,
p.signature-f,
li.signature-f,
div.signature-f {
  font-variant: small-caps;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 2.45pt;
  page-break-after: avoid;
  text-align: right;
}
p.signature-f,
li.signature-f,
div.signature-f {
  mso-style-name: signature-f;
}
p.signtit-e,
li.signtit-e,
div.signtit-e {
  mso-style-name: signtit-e;
}
p.signtit-e,
li.signtit-e,
div.signtit-e,
p.signtit-f,
li.signtit-f,
div.signtit-f {
  font-style: italic;
  layout-grid-mode: line;
  margin-bottom: 11.95pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  page-break-after: avoid;
  text-align: right;
}
p.signtit-f,
li.signtit-f,
div.signtit-f {
  mso-style-name: signtit-f;
}
p.certify-e,
li.certify-e,
div.certify-e {
  mso-style-name: certify-e;
}
p.certify-e,
li.certify-e,
div.certify-e,
p.certify-f,
li.certify-f,
div.certify-f {
  layout-grid-mode: line;
  margin-bottom: 11.95pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 14.45pt;
  page-break-after: avoid;
}
p.certify-f,
li.certify-f,
div.certify-f {
  mso-style-name: certify-f;
}
p.YPheading3-e,
li.YPheading3-e,
div.YPheading3-e {
  mso-style-name: YPheading3-e;
}
p.YPheading3-e,
li.YPheading3-e,
div.YPheading3-e,
p.YPheading3-f,
li.YPheading3-f,
div.YPheading3-f {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
}
p.YPheading3-f,
li.YPheading3-f,
div.YPheading3-f {
  mso-style-name: YPheading3-f;
}
p.Yproctablelevel1x-e,
li.Yproctablelevel1x-e,
div.Yproctablelevel1x-e {
  mso-style-name: Yproctablelevel1x-e;
}
p.Yproctablelevel1x-e,
li.Yproctablelevel1x-e,
div.Yproctablelevel1x-e,
p.Yproctablelevel1x-f,
li.Yproctablelevel1x-f,
div.Yproctablelevel1x-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 12pt;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.Yproctablelevel1x-f,
li.Yproctablelevel1x-f,
div.Yproctablelevel1x-f {
  mso-style-name: Yproctablelevel1x-f;
}
p.Yproctableboldlevel1x-e,
li.Yproctableboldlevel1x-e,
div.Yproctableboldlevel1x-e {
  mso-style-name: Yproctableboldlevel1x-e;
}
p.Yproctableboldlevel1x-e,
li.Yproctableboldlevel1x-e,
div.Yproctableboldlevel1x-e,
p.Yproctableboldlevel1x-f,
li.Yproctableboldlevel1x-f,
div.Yproctableboldlevel1x-f {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 12pt;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.Yproctableboldlevel1x-f,
li.Yproctableboldlevel1x-f,
div.Yproctableboldlevel1x-f {
  mso-style-name: Yproctableboldlevel1x-f;
}
p.ConsolidationPeriod-e,
li.ConsolidationPeriod-e,
div.ConsolidationPeriod-e {
  color: red;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 4.5pt;
  mso-style-name: ConsolidationPeriod-e;
}
p.NoticeAmend1-e,
li.NoticeAmend1-e,
div.NoticeAmend1-e {
  margin-left: 36pt;
  mso-style-name: NoticeAmend1-e;
}
p.NoticeAmend1-e,
li.NoticeAmend1-e,
div.NoticeAmend1-e,
p.NoticeAmend2-e,
li.NoticeAmend2-e,
div.NoticeAmend2-e {
  color: red;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 4pt;
}
p.NoticeAmend2-e,
li.NoticeAmend2-e,
div.NoticeAmend2-e {
  margin-left: 72pt;
  mso-style-name: NoticeAmend2-e;
}
p.NoticeAmend3-e,
li.NoticeAmend3-e,
div.NoticeAmend3-e {
  margin-top: 4pt;
  mso-style-name: NoticeAmend3-e;
}
p.NoticeAmend3-e,
li.NoticeAmend3-e,
div.NoticeAmend3-e,
p.NoticeProc1-e,
li.NoticeProc1-e,
div.NoticeProc1-e {
  color: red;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 36pt;
  margin-right: 0cm;
}
p.NoticeProc1-e,
li.NoticeProc1-e,
div.NoticeProc1-e {
  margin-top: 6pt;
  mso-style-name: NoticeProc1-e;
}
p.ConsolidationPeriod-f,
li.ConsolidationPeriod-f,
div.ConsolidationPeriod-f {
  color: red;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 4.5pt;
  mso-style-name: ConsolidationPeriod-f;
}
p.Notice-f,
li.Notice-f,
div.Notice-f {
  margin-left: 0cm;
  mso-style-name: Notice-f;
  text-align: justify;
}
p.Notice-f,
li.Notice-f,
div.Notice-f,
p.NoticeAmend1-f,
li.NoticeAmend1-f,
div.NoticeAmend1-f {
  color: red;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 4pt;
}
p.NoticeAmend1-f,
li.NoticeAmend1-f,
div.NoticeAmend1-f {
  margin-left: 36pt;
  mso-style-name: NoticeAmend1-f;
}
p.NoticeAmend2-f,
li.NoticeAmend2-f,
div.NoticeAmend2-f {
  margin-left: 72pt;
  mso-style-name: NoticeAmend2-f;
}
p.NoticeAmend2-f,
li.NoticeAmend2-f,
div.NoticeAmend2-f,
p.NoticeAmend3-f,
li.NoticeAmend3-f,
div.NoticeAmend3-f {
  color: red;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-right: 0cm;
  margin-top: 4pt;
}
p.NoticeAmend3-f,
li.NoticeAmend3-f,
div.NoticeAmend3-f {
  margin-left: 36pt;
  mso-style-name: NoticeAmend3-f;
}
p.NoticeProc1-f,
li.NoticeProc1-f,
div.NoticeProc1-f {
  color: red;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 36pt;
  margin-right: 0cm;
  margin-top: 6pt;
  mso-style-name: NoticeProc1-f;
}
p.Yparawindt2-e,
li.Yparawindt2-e,
div.Yparawindt2-e {
  mso-style-name: Yparawindt2-e;
}
p.Yparawindt2-e,
li.Yparawindt2-e,
div.Yparawindt2-e,
p.Yparawindt2-f,
li.Yparawindt2-f,
div.Yparawindt2-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 27.85pt;
  margin-right: 0cm;
  margin-top: 4.8pt;
  text-align: justify;
}
p.Yparawindt2-f,
li.Yparawindt2-f,
div.Yparawindt2-f {
  mso-style-name: Yparawindt2-f;
}
p.Yparawindt3-e,
li.Yparawindt3-e,
div.Yparawindt3-e {
  mso-style-name: Yparawindt3-e;
}
p.Yparawindt3-e,
li.Yparawindt3-e,
div.Yparawindt3-e,
p.Yparawindt3-f,
li.Yparawindt3-f,
div.Yparawindt3-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 41.75pt;
  margin-right: 0cm;
  margin-top: 4.8pt;
  text-align: justify;
}
p.Yparawindt3-f,
li.Yparawindt3-f,
div.Yparawindt3-f {
  mso-style-name: Yparawindt3-f;
}
p.heading2x-e,
li.heading2x-e,
div.heading2x-e {
  mso-style-name: heading2x-e;
}
p.heading2x-e,
li.heading2x-e,
div.heading2x-e,
p.heading2x-f,
li.heading2x-f,
div.heading2x-f {
  font-variant: small-caps;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
}
p.heading2x-f,
li.heading2x-f,
div.heading2x-f {
  mso-style-name: heading2x-f;
}
p.heading3x-f,
li.heading3x-f,
div.heading3x-f {
  mso-style-name: heading3x-f;
}
p.heading3x-f,
li.heading3x-f,
div.heading3x-f,
p.heading3x-e,
li.heading3x-e,
div.heading3x-e {
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 7.5pt;
  page-break-after: avoid;
  text-align: center;
}
p.heading3x-e,
li.heading3x-e,
div.heading3x-e {
  mso-style-name: heading3x-e;
}
p.Yprocparanoindt-e,
li.Yprocparanoindt-e,
div.Yprocparanoindt-e {
  mso-style-name: Yprocparanoindt-e;
}
p.Yprocparanoindt-e,
li.Yprocparanoindt-e,
div.Yprocparanoindt-e,
p.Yprocparanoindt-f,
li.Yprocparanoindt-f,
div.Yprocparanoindt-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 12.25pt;
  margin-right: 0cm;
  margin-top: 4.8pt;
  text-align: justify;
}
p.Yprocparanoindt-f,
li.Yprocparanoindt-f,
div.Yprocparanoindt-f {
  mso-style-name: Yprocparanoindt-f;
}
p.pnoteclause-e,
li.pnoteclause-e,
div.pnoteclause-e {
  mso-style-name: pnoteclause-e;
}
p.pnoteclause-e,
li.pnoteclause-e,
div.pnoteclause-e,
p.pnoteclause-f,
li.pnoteclause-f,
div.pnoteclause-f {
  background: #d9d9d9;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 38.9pt;
  margin-right: 0cm;
  margin-top: 5.55pt;
  text-align: justify;
}
p.pnoteclause-f,
li.pnoteclause-f,
div.pnoteclause-f {
  mso-style-name: pnoteclause-f;
}
p.Yregtitle-e,
li.Yregtitle-e,
div.Yregtitle-e {
  mso-style-name: Yregtitle-e;
}
p.Yregtitle-e,
li.Yregtitle-e,
div.Yregtitle-e,
p.Yregtitle-f,
li.Yregtitle-f,
div.Yregtitle-f {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 6.95pt;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0cm;
  page-break-after: avoid;
  text-align: center;
  text-transform: uppercase;
}
p.Yregtitle-f,
li.Yregtitle-f,
div.Yregtitle-f {
  mso-style-name: Yregtitle-f;
}
p.YTOCpartLeft-e,
li.YTOCpartLeft-e,
div.YTOCpartLeft-e {
  mso-style-name: YTOCpartLeft-e;
}
p.YTOCpartLeft-e,
li.YTOCpartLeft-e,
div.YTOCpartLeft-e,
p.YTOCpartLeft-f,
li.YTOCpartLeft-f,
div.YTOCpartLeft-f {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
}
p.YTOCpartLeft-f,
li.YTOCpartLeft-f,
div.YTOCpartLeft-f {
  mso-style-name: YTOCpartLeft-f;
}
p.YTOCid-e,
li.YTOCid-e,
div.YTOCid-e {
  mso-style-name: YTOCid-e;
}
p.YTOCid-e,
li.YTOCid-e,
div.YTOCid-e,
p.YTOCid-f,
li.YTOCid-f,
div.YTOCid-f {
  background: #d9d9d9;
  color: blue;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
  text-decoration: underline;
}
p.YTOCid-f,
li.YTOCid-f,
div.YTOCid-f {
  mso-style-name: YTOCid-f;
}
p.YTOCSched-e,
li.YTOCSched-e,
div.YTOCSched-e {
  mso-style-name: YTOCSched-e;
}
p.YTOCSched-e,
li.YTOCSched-e,
div.YTOCSched-e,
p.YTOCSched-f,
li.YTOCSched-f,
div.YTOCSched-f {
  background: #d9d9d9;
  color: blue;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
  text-decoration: underline;
}
p.YTOCSched-f,
li.YTOCSched-f,
div.YTOCSched-f {
  mso-style-name: YTOCSched-f;
}
p.YTOCTable-e,
li.YTOCTable-e,
div.YTOCTable-e {
  mso-style-name: YTOCTable-e;
}
p.YTOCTable-e,
li.YTOCTable-e,
div.YTOCTable-e,
p.YTOCTable-f,
li.YTOCTable-f,
div.YTOCTable-f {
  background: #d9d9d9;
  color: blue;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
  text-decoration: underline;
}
p.YTOCTable-f,
li.YTOCTable-f,
div.YTOCTable-f {
  mso-style-name: YTOCTable-f;
}
p.YTOCheadLeft-e,
li.YTOCheadLeft-e,
div.YTOCheadLeft-e {
  mso-style-name: YTOCheadLeft-e;
}
p.YTOCheadLeft-e,
li.YTOCheadLeft-e,
div.YTOCheadLeft-e,
p.YTOCheadLeft-f,
li.YTOCheadLeft-f,
div.YTOCheadLeft-f {
  background: #d9d9d9;
  color: blue;
  font-variant: small-caps;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
  text-decoration: underline;
}
p.YTOCheadLeft-f,
li.YTOCheadLeft-f,
div.YTOCheadLeft-f {
  mso-style-name: YTOCheadLeft-f;
}
p.YTOCPartCenter-e,
li.YTOCPartCenter-e,
div.YTOCPartCenter-e {
  mso-style-name: YTOCPartCenter-e;
}
p.YTOCPartCenter-e,
li.YTOCPartCenter-e,
div.YTOCPartCenter-e,
p.YTOCPartCenter-f,
li.YTOCPartCenter-f,
div.YTOCPartCenter-f {
  background: #d9d9d9;
  font-weight: bold;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
  text-align: center;
}
p.YTOCPartCenter-f,
li.YTOCPartCenter-f,
div.YTOCPartCenter-f {
  mso-style-name: YTOCPartCenter-f;
}
p.YTOCHeadCenter-e,
li.YTOCHeadCenter-e,
div.YTOCHeadCenter-e {
  mso-style-name: YTOCHeadCenter-e;
}
p.YTOCHeadCenter-e,
li.YTOCHeadCenter-e,
div.YTOCHeadCenter-e,
p.YTOCHeadCenter-f,
li.YTOCHeadCenter-f,
div.YTOCHeadCenter-f {
  background: #d9d9d9;
  color: blue;
  font-variant: small-caps;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
  text-align: center;
  text-decoration: underline;
}
p.YTOCHeadCenter-f,
li.YTOCHeadCenter-f,
div.YTOCHeadCenter-f {
  mso-style-name: YTOCHeadCenter-f;
}
p.YTOCHead-e,
li.YTOCHead-e,
div.YTOCHead-e {
  mso-style-name: YTOCHead-e;
}
p.YTOCHead-e,
li.YTOCHead-e,
div.YTOCHead-e,
p.YTOCHead-f,
li.YTOCHead-f,
div.YTOCHead-f {
  background: #d9d9d9;
  color: blue;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
  text-decoration: underline;
}
p.YTOCHead-f,
li.YTOCHead-f,
div.YTOCHead-f {
  mso-style-name: YTOCHead-f;
}
p.TOCForm-e,
li.TOCForm-e,
div.TOCForm-e {
  mso-style-name: TOCForm-e;
}
p.TOCForm-e,
li.TOCForm-e,
div.TOCForm-e,
p.TOCForm-f,
li.TOCForm-f,
div.TOCForm-f {
  color: blue;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
  text-decoration: underline;
}
p.TOCForm-f,
li.TOCForm-f,
div.TOCForm-f {
  mso-style-name: TOCForm-f;
}
p.YTOCForm-e,
li.YTOCForm-e,
div.YTOCForm-e {
  mso-style-name: YTOCForm-e;
}
p.YTOCForm-e,
li.YTOCForm-e,
div.YTOCForm-e,
p.YTOCForm-f,
li.YTOCForm-f,
div.YTOCForm-f {
  background: #d9d9d9;
  color: blue;
  layout-grid-mode: line;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-top: 0.55pt;
  text-decoration: underline;
}
p.YTOCForm-f,
li.YTOCForm-f,
div.YTOCForm-f {
  mso-style-name: YTOCForm-f;
}
span.Heading1Char {
  color: #365f91;
  mso-style-link: "Heading 1";
  mso-style-name: "Heading 1 Char";
}
/* Page Definitions */
@page WordSection1 {
  margin: 72pt 54pt 57.6pt 72pt;
  size: 612pt 792pt;
}
/* List Definitions */
