@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/global.variables' as globalVariables;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/spacing.variables' as spacing;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/colours.variables' as colours;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/breakpoints.variables' as breakpoints;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/2-tools/placeholder/focus.placeholders' as placeholders;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/typography.variables' as typography;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-sizes.variables' as fontSizes;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/z-index.variables' as zIndex;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/line-heights.variables' as lineHeights;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-weights.variables' as fontWeights;

ul.alpha-toc {
  list-style: none;
  margin: 0;
  padding-right: 0px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
}

li.toc-letter {
  padding: 1rem 1.375rem 0 0;
  font-family: 'Open Sans';
  font-size: fontSizes.$ontario-font-size-xlarge;
}

@media only screen and (max-width: 960px) {
  li.toc-letter {
    font-size: 1.1rem;
  }
}

li.toc-letter a {
  color: colours.$ontario-colour-link;
  font-weight: bold;
  text-decoration: none;
}

li.toc-letter span {
  color: #666666;
  font-weight: normal;
}