
select#law-selection-period {
  text-overflow: ellipsis;
}

#law-selection-period.ontario-dropdown,
#law-selection.ontario-dropdown {
  background-position: right 0.1rem center;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  select#law-selection-period,
  select#law-selection {
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 1150px) {
  .wrap-text {
    white-space: pre-wrap;
    padding-right: 1em;
    min-width: 10em;
  }

  .leg-history .history-item {
    flex-basis: 50%;
  }

  .doc-header-container .ontario-label {
    max-width: 9rem;
  }
}
