@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/colours.variables' as colours;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/spacing.variables' as spacing;

.ontario-radios__label {
    padding: spacing.$spacing-0 spacing.$spacing-1;
}

.ontario-radios__item {
    margin-bottom: spacing.$spacing-0;
}


.ontario-radios__item:last-of-type {
    .ontario-radios__label {
        margin-bottom: spacing.$spacing-0;
    }
}

.ontario-radios__label:before {
    width: 1.5rem;
    height: 1.5rem;
}

.ontario-radios__label:after {
    border: 0.5rem solid #1a1a1a; 
    transform: translate(0.265625rem, 0.265625rem); 
}