@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/global.variables' as globalVariables;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/spacing.variables' as spacing;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/colours.variables' as colours;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/breakpoints.variables' as breakpoints;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/2-tools/placeholder/focus.placeholders' as placeholders;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/typography.variables' as typography;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-sizes.variables' as fontSizes;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/z-index.variables' as zIndex;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/line-heights.variables' as lineHeights;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-weights.variables' as fontWeights;

$page-height: 1250px; 

.doc-search__hint-expander {
    margin: spacing.$spacing-0;
    padding: spacing.$spacing-4 spacing.$spacing-0 spacing.$spacing-4 spacing.$spacing-4;
    background: colours.$ontario-greyscale-5;
    flex: 1;
    max-width: none;

    button {
        margin-bottom: spacing.$spacing-0;
        color: colours.$ontario-colour-black;
        font-weight: fontWeights.$ontario-font-weights-semi-bold;
    }

    .ontario-hint-expander__content {
        border-left: none;
        margin-top: spacing.$spacing-4;
        padding-left: spacing.$spacing-0;
        padding-top: spacing.$spacing-1;
        padding-bottom: spacing.$spacing-0;
    }

    .ontario-hint-expander__container  button {
        color: colours.$ontario-colour-link;
    }

    .act-search {
        padding: spacing.$spacing-0;
    }

    .content-accordion {
        border-bottom: 2px solid colours.$ontario-greyscale-20;
    }
}

.in-doc-search {
    display: flex;
}

.doc-search__search-bar {
    margin-right: spacing.$spacing-4;
    margin-bottom: spacing.$spacing-4 !important;
    
    @media screen and (max-width: breakpoints.$small-breakpoint) {
      width: 100%;
    }
}

.doc-search__select-box {
    margin-right: spacing.$spacing-4;
    margin-bottom: spacing.$spacing-4 !important;
  
    .ontario-label {
        margin-right: 0.625rem;
        font-size: 1.125rem;
        font-weight: fontWeights.$ontario-font-weights-semi-bold;
    }
}

.act-search-options {
    margin-top: spacing.$spacing-1;
}

.act-hl-browse {
    align-self: center;
    margin-bottom: spacing.$spacing-4 !important;

    button {
        margin-right: spacing.$spacing-1;
    }
}

.doc-search__sort-by {
    display: flex;
    justify-content: flex-start; 
    align-items: center;
    margin: spacing.$spacing-4 spacing.$spacing-0;

    label, select {
        flex-grow: 0; 
        flex-shrink: 0; 
        width: auto; 
        margin-right: spacing.$spacing-2;
        margin-bottom: spacing.$spacing-0;
    }
}

.act-content-panel{
    .sticky-inner-wrapper {
        z-index: 1 !important;
    }
}

.act-search {
  width: 100%;
  background-color: #f2f2f2;
  padding: 1.5rem 0.75rem;
}

.act-search .ontario-form-group {
  display: flex;
  flex-wrap: nowrap;
  padding: 0 1rem;
  margin-bottom: 1rem !important;
}

.act-search .ontario-label {
  margin-bottom: 0;
  white-space: wrap;
}

.act-search .ontario-input {
  margin-bottom: 0;
  min-width: 6rem;
}

.act-search .ontario-form-group:last-of-type {
  margin-bottom: 0;
}

.act-search .ontario-accordion__button {
  font-size: 1.15rem;
}


.act-content [class*='Center'],
.act-content [class*='ellipsis'],
.act-content [class*='heading'],
.act-content [class*='part'],
.act-content [class*='Repeal'],
.act-content [class*='repeal'],
.act-content [class*='Revoked'],
.act-content [class*='rulec'],
.act-content [class*='schedule'],
.act-content [class*='shorttitle'],
.act-content [class*='subject'],
.act-content [class*='toc'],
.act-content [class*='TOCpart'],
.act-content [class^='act'],
.act-content [class^='ActTitle'],
.act-content [class^='amendednote'],
.act-content [class^='regaction'], /*Erin to refactor LAWS-1446*/
.act-content [class^='chapter'],
.act-content [class^='form'],
.act-content [class^='line'],
.act-content [class^='longtitle'],
/* .act-content [class^='made'], */
.act-content [class^='reg'],
.act-content [class^='TOChead'],
.act-content [class^='TOCsched'],
.act-content [class^='TOCtable'] .act-content [class*='xtitle'],
.act-content [class^='Yact'],
.act-content [class^='Yform'],
.act-content [class^='Yline'],
.act-content [class^='Yreg'] {
  text-align: center;
  font-weight: bold;
  font-size: fontSizes.$ontario-font-size-large;
}

/*Erin to refactor Laws-1440 removed the class from the above list*/
.act-content [class*='equation-']{ 
  text-align: center;
}

.act-content [class^='made'] {
  text-align: center;
  font-size: fontSizes.$ontario-font-size-large;
}

[class^='reg'] {
  text-align: center;
}

.content [class*='bold'],
.content [class*='rule'],
.content [class*='shorttitle'],
.content [class*='toc'],
.content [class*='TOCpart'],
.content [class^='ActTitle'],
.content [class^='defPnote'],
.content [class^='headnote'],
.content [class^='longtitle'],
.content [class^='number'],
.content [class^='P'],
.content [class^='part'],
.content [class^='procclause'],
.content [class^='procparagraph'],
.content [class^='reg'],
.content [class^='SP'],
.content [class^='trans'],
.content [class^='version'],
.content [class^='xheadnote'],
.content [class^='Yform'],
.content [class^='YprocPnote'],
.content [class^='YSP'],
[class^='Ppart'],
[class^='xpart'],
[class^='Yreg'] {
  font-weight: 700;
  font-size: 1.2rem;
  margin-bottom: 0rem;

  i {
    font-family: typography.$ontario-font-raleway-modified;
  }
}

.content [class*='headnoteitalic-e'],
.content [class*='headnoteitalic-f'] {
  font-family: typography.$ontario-font-raleway-modified; 
}

.content [class*='headingx'],
.content [class*='ovallcaps'],
.content [class*='part'],
.content [class*='rulec'],
.content [class*='rulel'],
.content [class*='schedule'],
.content [class*='subject'],
.content [class*='tableheading'],
.content [class*='xtitle'],
.content [class^='ActTitle'],
.content [class^='chapter'],
.content [class^='form'],
.content [class^='reg'],
.content [class^='Yreg'],
.content [class^='Ytoc'],
[class^='chapter'] {
  text-transform: uppercase;
}

[class^='ActTitle'],
[class^='chapter'],
[class^='form'],
[class*='headingx'],
[class*='ovallcaps'],
[class*='part'],
[class^='reg'],
[class^='Yreg'],
[class*='rulec'],
[class*='rulel'],
[class*='schedule'],
[class*='subject'],
[class*='tableheading'],
/*[class^='toc'], Erin to capture modified as a part of Laws=1337*/
[class*='xtitle'],
[class^='Ytoc'] {
  text-transform: uppercase;
}

.leg-history .history-item:not(:last-child)::after {
  content: ',';
}


/* Fix Hint Explander not showing*/
.ontario-hint-expander__container.ontario-expander--active .ontario-hint-expander__button-icon--open {
  display: inline-block
}