@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/global.variables' as globalVariables;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/spacing.variables' as spacing;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/colours.variables' as colours;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/breakpoints.variables' as breakpoints;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/2-tools/placeholder/focus.placeholders' as placeholders;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/typography.variables' as typography;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-sizes.variables' as fontSizes;

.mobile-accordion__mobile {
	max-width: globalVariables.$standard-width;
	width: globalVariables.$full-width;
	border-top: 2px solid colours.$ontario-greyscale-20;
	border-bottom: 2px solid colours.$ontario-greyscale-20;
}

.mobile-accordion__mobile-header {
	.elaws-h4 {
		margin-bottom: spacing.$spacing-0;
	}
}

.mobile-accordion__content {
	overflow: hidden;
	transition: max-height 0.2s ease-out;
	padding: spacing.$spacing-3 spacing.$spacing-3 spacing.$spacing-3 spacing.$spacing-3;
	margin-left: spacing.$spacing-0;

	@media screen and (min-width: breakpoints.$small-breakpoint) {
		margin-left: spacing.$spacing-6;
	}

	ul,
	ol {
		max-width: calc(100% - spacing.$spacing-8);
	}

	& > :last-child {
		margin-bottom: spacing.$spacing-0;
	}
}

.mobile-accordion__toggle {
	cursor: pointer;
	border: none;
	outline: none;
	width: 100%;
	text-align: left;
	padding: spacing.$spacing-2;
	background: none;
	display: flex;
	align-items: center;
}

.mobile-accordion__icon {
	margin-right: spacing.$spacing-1;
}