@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-sizes.variables' as fontSizes;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/breakpoints.variables' as breakpoints;

$record-odd-background: #f6f6f6;

// .results-list__heading {
//     font-size: elaws-h3;
//     line-height:elaws-h3;
// }

// .results-list__sub-heading {
//     font-size: fontSizes.$ontario-font-size-large;
// }

.font-weight-normal {
    font-weight: normal;
}

.search-term th {
  font-size: 1.1rem;
}
.search-term th,
.search-term td {
  padding: 0.5rem 1rem;
  width: 20%;
  
  @media screen and (max-width: breakpoints.$small-breakpoint) {
    padding: 0.25rem;
  }
}

#search-mode .ontario-form-group:last-of-type {
  margin-bottom: 0;
}
  
.search-button button {
    border-radius: 1.5rem;
    padding: 0.75rem 1.5rem;
    background-color: #f2f2f2;
    border: none;
    color: #1a1a1a;
  }
  
  .suggested-button {
    display: flex;
    flex-direction: flex-start;
    flex-wrap: wrap;
    padding: 1rem 1rem;
  }
  
  .suggested-button .ontario-icon {
    width: 30px;
    height: 30px;
  }

.paging-button {
    display: flex;
    padding-right: 4rem;
    padding-top: 2rem;
  }
  
  .paging-button button {
    padding: 1rem 1.5rem;
  }
  