@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/colours.variables' as colours;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/spacing.variables' as spacing;

div#result-panel > div:nth-child(even),
tbody tr:nth-child(even) {
  background-color: colours.$ontario-greyscale-5;
}

.image-grey-border--default {
  border: 2px solid colours.$ontario-greyscale-20;
  padding: spacing.$spacing-2;
}