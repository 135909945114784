@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/global.variables' as globalVariables;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/spacing.variables' as spacing;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/colours.variables' as colours;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/breakpoints.variables' as breakpoints;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/2-tools/placeholder/focus.placeholders' as placeholders;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/typography.variables' as typography;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-sizes.variables' as fontSizes;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/z-index.variables' as zIndex;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/line-heights.variables' as lineHeights;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-weights.variables' as fontWeights;

/* these items are within the grid column set but on bigger screen widths are too far apart */
@media only screen and (min-width: 1150px) {
  .autocompleteItem .resultLink,
  .autocompleteItem .suggestText {
    margin-left: -4%;
  }
}

@media only screen and (max-width: 960px) {
  .sui-search-box #search-btn strong {
    display: none;
  }

  .search-box > button.search {
    min-width: 3rem;
  }

  .tab-content .regs {
    width: 100% !important;
  }
  
  button.letter-lighten,
  button.letter-dark {
    font-size: 1.1rem;
  }

  li.character {
    padding: 0.5rem 0 0 0;
  }

  .leg-history .history-item {
    flex-basis: 100%;
  }

  #act-verion > ul > li button,
  .collapsable-button.ontario-h5,
  .collapsable-section .ontario-label,
  .act-hl-browse span {
    font-size: fontSizes.$ontario-font-size-standard-body-text;
  }

  #act-verion .tab-content,
  #act-verion .tab-content select {
    font-size: 0.85rem;
  }

  #act-under-reg-panel .doc-tab-container .doc-row,
  #act-rrs-panel .doc-tab-container .doc-row {
    display: grid;
    grid-template-columns: 1fr 3fr;
  }

  .doc-header-container .ontario-label,
  .ontario-accordions__container,
  .search-term th,
  .search-term tbody,
  .collapsable-section .ontario-checkboxes__label {
    font-size: 0.9rem;
  }

  .collapsable-button .ontario-icon {
    width: 24px;
    height: 24px;
  }

  .search-box > input,
  .act-search .ontario-input {
    padding: 0.3rem 1rem;
  }

  .act-search .ontario-input {
    margin: 0.15rem 0;
  }
}
.autocompleteItem .resultLink,
.autocompleteItem .suggestText {
  margin-left: 0;
}

/* Overrides */
.sui-search-box__text-input {
  all: unset;
}

.sui-search-box__text-input:active,
.sui-search-box__text-input:focus,
.sui-search-box__text-input:hover {
  all: unset;
}

.sui-search-box__autocomplete-container {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
}

.sui-search-box__autocomplete-container h3 {
  color: #666;
  font-family: 'Raleway';
  font-weight: 700;
  font-size: fontSizes.$ontario-font-size-standard-body-text;
  line-height: 1.5rem;
  margin-top: 0;
}

.sui-search-box__autocomplete-container li,
.sui-search-box__autocomplete-container button {
  font-family: 'Open Sans';
  font-size: fontSizes.$ontario-font-size-standard-body-text;
  line-height: 1.6rem;
}

.sui-search-box__autocomplete-container li:hover {
  background: #e0f0ff;
  color: #1a1a1a; 
}

.sui-search-box__autocomplete-container li a,
.sui-search-box__autocomplete-container button {
  text-decoration: none;
  color: #1a1a1a;
}

.sui-layout-body {
  display: none;
}
