@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/spacing.variables' as spacing;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/typography.variables' as typography;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-sizes.variables' as fontSizes;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-weights.variables' as fontWeights;

.term-modal {
  background: none;
  padding: 0;
  font-size: fontSizes.$ontario-font-size-standard-body-text;
  font-weight: fontWeights.$ontario-font-weights-normal;
  font-family: typography.$ontario-font-open-sans;  
  border: none;
  color: #1a1a1a;
}

.term-modal .ontario-icon {
  width: 20px;
  height: 20px;
}

.elaws-decorator-parenthesis:before {
  content: '(';
  font-weight: normal;
}

.elaws-decorator-parenthesis:after {
  content: ')';
  font-weight: normal;
}

.elaws-underline {
  text-decoration: underline;
}

.elaws-underline--dotted {
  text-decoration-style: dotted !important;
  text-underline-offset: .25em;
  text-decoration-thickness: 1px;
}
