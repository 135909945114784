@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/spacing.variables' as spacing;

.filter-options {
    .ontario-radios {
        margin-bottom: spacing.$spacing-0;
    }

    .ontario-checkboxes {
        margin-bottom: spacing.$spacing-4;
    }

    .ontario-checkboxes:last-of-type {
        margin-bottom: spacing.$spacing-0;
    }

    hr {
        margin: spacing.$spacing-5 spacing.$spacing-0;
    }
}

.period .ontario-checkboxes,
.source .ontario-checkboxes,
.consolidated .ontario-checkboxes {
  margin-bottom: 1rem !important;
}
