
.collapsable-button {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  background: none;
  border: none;
}

button.collapsable-button {
  margin: 0 0 0.25rem 0 !important;
}

.collapsable-collapsed {
  display: none;
}

.collapsable-expanded {
  display: inherit;
}

.collapsable-button .ontario-icon {
  width: 30px;
  height: 30px;
}

.collapsable-container {
  width: 100%;
  display: block;
}
