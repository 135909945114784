@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/colours.variables' as colours;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/spacing.variables' as spacing;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/typography.variables' as typography;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/line-heights.variables' as lineHeights;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-weights.variables' as fontWeights;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-sizes.variables' as fontSizes;

.search-page__title {
    margin-top: spacing.$spacing-5;
    margin-bottom: spacing.$spacing-7;
}

.search-page__hint-expander {
    .ontario-hint-expander__button {
        color: colours.$ontario-colour-black;
        align-items: center;
    }

    .ontario-hint-expander__content  {
        border-left: none;
        background-color: colours.$ontario-colour-white;
        padding: spacing.$spacing-4 spacing.$spacing-4 spacing.$spacing-4 spacing.$spacing-5;
        margin: spacing.$spacing-0 spacing.$spacing-0 spacing.$spacing-4 spacing.$spacing-1;

        .ontario-radios__item {
            margin: spacing.$spacing-0 spacing.$spacing-0 spacing.$spacing-4;
        }
    }
}

.exact-search {
    .ontario-hint-expander__button {
        color: colours.$ontario-colour-link;
        font-weight: fontWeights.$ontario-font-weights-normal;
    }

    .ontario-hint-expander__content  {
        border-left: 4px solid colours.$ontario-greyscale-20;
        background-color: colours.$ontario-greyscale-5;
        padding: spacing.$spacing-4 spacing.$spacing-4 spacing.$spacing-4 spacing.$spacing-5;
        margin: spacing.$spacing-0 spacing.$spacing-0 spacing.$spacing-4 spacing.$spacing-1;
    }
}


.search-setting-accordion .ontario-accordion__button {
    font-size: fontSizes.$ontario-font-size-standard-body-text;
    color: #00478F; /* Darker blue for accessibility */
  }
  
  .search-setting-accordion a {
    color: #00478F; /* Darker blue for accessibility */
  }
  
.act-hl-browse {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    font-size: 1.15rem;
    margin-bottom: 1rem;
  }
  
  .act-hl-browse span {
    font-size: 1.1rem;
    padding-left: 0.5rem;
  }
  
#search-option-panel.ontario-column {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0 !important;
  }
  
  #search-option-panel .filter-panel {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  
  #search-option-panel .filter-panel .ontario-accordion__content p {
    font-size: fontSizes.$ontario-font-size-standard-body-text;
  }

  .main-panel #search-option-panel {
    flex-basis: 25%;
  }
  
  #search-option-panel .ontario-checkboxes__label {
    padding: 0 0.25rem !important;
  }
  
  #search-option-panel .scroll-y-panel {
    background-color: #f2f2f2;
    overflow: auto;
    overflow-y: auto;
    top: 0;
  }
  
.main-panel #search-panel {
  flex-basis: 75%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.ontario-accordion section {
  margin-left: 0 !important;
  padding: 0.75rem 0.25rem 2rem 0.25rem;
}

.ontario-accordion .ontario-accordion__button {
  padding: 0.75rem 0;
}

.ontario-accordion section blockquote {
  margin-left: 0 !important;
  margin-top: 0;
  padding: 1rem 0 1rem 1rem;
  quotes: '' '' !important;
  border-left: 4px solid #cccccc;
}

#search-panel .ontario-accordion {
  border-top: none;
}

#search-panel .ontario-accordion:last-of-type {
  border-bottom: none;
}
  
.act-explore-buttons {
    position: fixed;
    bottom: 3rem;
    right: 3rem;
    display: inline-flex;
    flex-direction: row;
    flex-shrink: 1;
    padding: 0, 1rem;
  }
  
  .act-explore-buttons a {
    text-decoration: none;
    color: #1a1a1a !important;
  }
  
  .act-explore-buttons .ontario-back-to-top--default {
    position: relative;
    margin: 0 0.5rem;
  }
  
  .act-search-options {
    display: flex;
    flex-wrap: wrap;
  }
  
  .act-search-options .ontario-checkboxes {
    margin-bottom: 0.5rem;
  }