@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/global.variables' as globalVariables;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/spacing.variables' as spacing;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/colours.variables' as colours;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/breakpoints.variables' as breakpoints;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/2-tools/placeholder/focus.placeholders' as placeholders;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/typography.variables' as typography;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-sizes.variables' as fontSizes;
@use '@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-weights.variables' as fontWeights;

.desktop-accordion {
    width: globalVariables.$full-width;
    border-top: 2px solid colours.$ontario-greyscale-20;

    &.closed {
        max-height: spacing.$spacing-5;
    }

    &__header {
        .elaws-h4 {
            margin-bottom: spacing.$spacing-0;
            font-size: fontSizes.$ontario-font-size-standard-body-text;
            font-weight: fontWeights.$ontario-font-weights-semi-bold;
        }
    }

    &__content {
        overflow: hidden;
        transition: max-height 0.2s ease-out;
        padding: spacing.$spacing-3 spacing.$spacing-0 spacing.$spacing-6 spacing.$spacing-7;
        margin-left: spacing.$spacing-0;

        ul,
        ol {
            max-width: calc(100% - spacing.$spacing-8);
        }

        & > :last-child {
            margin-bottom: spacing.$spacing-0;
        }
    }

    &__toggle {
        cursor: pointer;
        border: none;
        outline: none;
        width: 100%;
        text-align: left;
        padding: spacing.$spacing-2;
        background: none;
        display: flex;
        align-items: center;
    }

    &__icon {
        margin-right: spacing.$spacing-1;
    }
}

.desktop-accordion {
}