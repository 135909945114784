@use "sass:math";
@use "@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/global.variables"
  as globalVariables;
@use "@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/spacing.variables"
  as spacing;
@use "@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/colours.variables"
  as colours;
@use "@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/breakpoints.variables"
  as breakpoints;
@use "@ongov/ontario-design-system-global-styles/dist/styles/scss/2-tools/placeholder/focus.placeholders"
  as placeholders;
@use "@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/typography.variables"
  as typography;
@use "@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-sizes.variables"
  as fontSizes;
@use "@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/z-index.variables"
  as zIndex;
@use "@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/line-heights.variables"
  as lineHeights;
@use "@ongov/ontario-design-system-global-styles/dist/styles/scss/1-variables/font-weights.variables"
  as fontWeights;

// Variables
$dark-bg: #e2e2e2;

// Mixin for button styles
@mixin button-style($bg-color, $color) {
  border: none;
  font-size: fontSizes.$ontario-font-size-large;
  font-weight: fontWeights.$ontario-font-weights-bold;
  cursor: pointer;
  color: $color;
  background-color: $bg-color;
}

// Base styles
ul.alpha-toc,
ul.browse-alpha {
  list-style: none;
  margin: spacing.$spacing-0;
  padding-left: spacing.$spacing-0;
  display: flex;
  flex-wrap: wrap;
}

// Browse Alpha Styles
ul.browse-alpha {
  padding-right: spacing.$spacing-0;

  li.character {
    padding: spacing.$spacing-4 0.30rem spacing.$spacing-0 spacing.$spacing-0;
    border: none;
  }

  button.letter-lighten {
    @include button-style(
      colours.$ontario-colour-white,
      colours.$ontario-colour-link
    );
  }

  button.letter-dark {
    @include button-style($dark-bg, colours.$ontario-colour-link);
    text-decoration: underline;
  }
}

// Glossary Page TOC Styles
ul.alpha-toc {
  padding-right: spacing.$spacing-0;

  li.toc-letter {
    padding: spacing.$spacing-4 1.375rem spacing.$spacing-0 spacing.$spacing-0;
    font-family: typography.$ontario-font-open-sans;
    font-size: fontSizes.$ontario-font-size-xlarge;

    @media only screen and (max-width: 960px) {
      font-size: 1.1rem;
    }

    a {
      color: colours.$ontario-colour-link;
      font-weight: bold;
      text-decoration: none;
    }

    span {
      color: colours.$ontario-greyscale-60;
      font-weight: normal;
    }
  }
}

ul.browse-alpha {
  list-style: none;
  margin: 0;
  padding-right: 0px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
}

button.letter-lighten {
  border: none;
  font-size: fontSizes.$ontario-font-size-xlarge;
  font-weight: bold;
  background-color: colours.$ontario-colour-white;
  color: colours.$ontario-colour-link;
  cursor: pointer;
}

button.letter-dark {
  border: none;
  font-size: fontSizes.$ontario-font-size-xlarge;
  font-weight: bold;
  background-color: $dark-bg !important;
  color: colours.$ontario-colour-link;
  text-decoration: underline;
  cursor: pointer;
}
